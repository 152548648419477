import {
  ArcElement,
  BarElement,
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip
} from "chart.js";
import { useContext, useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import { useTheme } from "../../Template/ThemeContext";
import AppContext from "../../context/AppContext";
import { isEmptyObj } from "../../context/MiscFunc";
import { hpost } from "../../context/apiService";
import { PageProps } from "../../types/menu-item";
import BAR_CHART_DATA from "./BarChartData";
import DASHBOARD_SUMMARY_CARDS from "./DashboardData";
import "./DashboardStyles.css";
import DoughnutComponent from "./DoughnutComponent";
import LINE_CHART_DATA from "./LineChartData";

interface DataProps {
    pagename: string;
    dataobject : any;
  }

  interface LineProps {
    options: ChartOptions<'line'>;
    data: ChartData<'line'>;
  }

const Dashboard:React.FC<PageProps> = ({currApplication, pageName}) => {  
  const [message, setMessage] = useState<string>('');
  const [lineChartData, setLineChartData] = useState<any>({});
  const [barChartData, setBarChartData] = useState<any>({});
  const [donut1Data, setDonut1Data] = useState<any>({});
  const [donut2Data, setDonut2Data] = useState<any>({});
  const [tilesData, setTilesData] = useState<any>({});
  
  const Theme = useTheme();
  const { sessionData, setCurrAppLogo, refreshData, setRefreshData, searchFilter, setSearchFilter } = useContext(AppContext);

  const displayMessage = (message : string, timeout : number) => {
    setMessage(message);
    setTimeout(() => {
     setMessage('');
   }, timeout)
 }

  useEffect(() => {
    // Fetch data using the API service
    console.log("DashboardUse Effect for Page ", pageName);
    // Fetch data using the API service
     fetchData();
  }, [pageName]);

  const fetchData = async () => {
    let params: any = {
      objecttype: "dashboard",
      pagename: pageName,
      application_id: currApplication?.id,
      biller_id: sessionData.data["Biller Id"],
    }

    let response = await hpost('/sec/data', params);
    if (response.data) {
      let pagetemplate = JSON.parse(JSON.stringify(response.data));
      console.log("Response: ", pagetemplate);
      if (pagetemplate.hasOwnProperty("data")) {
        let linechartdata = pagetemplate.data.linechart;
        let bargraphdata = pagetemplate.data.bargraph;
        let donut1data = pagetemplate.data.donut1;
        let donut2data = pagetemplate.data.donut2;
        let tilesdata = pagetemplate.data.tilesdata[0];
        setLineChartData(linechartdata);
        setBarChartData(bargraphdata);
        setDonut1Data(donut1data);
        setDonut2Data(donut2data);
        setTilesData(tilesdata);
      }
    } else if (response.error) {
      console.error("Error: ", response.error);
      displayMessage(response.error, 5000);
      
    };
  }
  
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
);

  const donutData1 = [{label:'Residential', backgroundColor:'#FFACE5'},
  {label:'Commercial', backgroundColor:"#A9C9FF"}]
  const donutData2 = [{label:'Pending', backgroundColor:'#86DFE1'}, 
  {label: 'Approved', backgroundColor:'#FBD586'}]
  
  let gradient, gradient2;
  const chartColors = ['rgba(255, 214, 136, 0.54)', 'rgba(255, 214, 136, 0.0)', 'rgba(1, 184, 202, 0.54)', 'rgba(1, 184, 202, 0.0)', 'rgba(1, 184, 202, 0.00)']
  useEffect(()=>{
    let canvas:HTMLCanvasElement = (document.getElementById('lineChart') as HTMLCanvasElement)!;
  console.log(canvas)
  let context = canvas.getContext("2d");  
  gradient = context?.createLinearGradient(0, 0, 0, 200);
  gradient?.addColorStop(0, chartColors[0]);   
  gradient?.addColorStop(0.5, chartColors[1]);
  gradient?.addColorStop(1, chartColors[4]);
  
  gradient2 = context?.createLinearGradient(0,0,0,200);
  gradient?.addColorStop(0,chartColors[2]);   
  gradient?.addColorStop(0.5, chartColors[3]);
  gradient2?.addColorStop(1, chartColors[4]);  
  }, [])
  
  const lineData = isEmptyObj(lineChartData)? {
    labels: LINE_CHART_DATA.map((data) => data.month),
    datasets: [            
      {
        label: '2021',
        data: LINE_CHART_DATA.map((data) => data.orders),
        fill:true,
        borderColor: '#FBD586',
        backgroundColor: 'rgba(255, 214, 136, 0.2)',
        lineTension: 0.5,                     
      },
      {
        label: '2022',
        data: LINE_CHART_DATA.map((data) => data.income),
        fill:true,
        borderColor: '#86DFE1',
        backgroundColor:'rgba(1, 184, 202, 0.2)',
        lineTension: 0.5,       
      },
    ],
  } : {

    labels: lineChartData.map((data :any) => data.month_abbreviation),
    datasets: [            
      {
        label: lineChartData.current_year,
        data: lineChartData.map((data: any) => data.current_charge),
        fill:true,
        borderColor: '#FBD586',
        backgroundColor: 'rgba(255, 214, 136, 0.2)',
        lineTension: 0.5,                     
      },
      {
        label: lineChartData[0].last_year,
        data: lineChartData.map((data: any) => data.last_year_charge),
        fill:true,
        borderColor: '#86DFE1',
        backgroundColor:'rgba(1, 184, 202, 0.2)',
        lineTension: 0.5,       
      },
    ],

  }

  const barData= (isEmptyObj(barChartData)? {
    labels: BAR_CHART_DATA.map((data) => data.month),
    datasets: [
      {
        label: 'WHTR',
        data: BAR_CHART_DATA.map((data) => data.WHTR),
        borderColor: '#86DFE1',
        backgroundColor: '#86DFE1',                        
      },      
      {
        label: 'WFTR',
        data: BAR_CHART_DATA.map((data) => data.WFTR),
        borderColor: '#DDB9FF',
        backgroundColor: '#DDB9FF',
      },
      {
        label: 'FURN',
        data: BAR_CHART_DATA.map((data) => data.Furn),
        borderColor: '#FBD586',
        backgroundColor: '#FBD586',
      },
      {
        label: 'AC',
        data: BAR_CHART_DATA.map((data) => data.AC),
        borderColor: '#FFACE5',
        backgroundColor: '#FFACE5',
      },
    ],
  } : {
    labels: barChartData.map((data : any) => data.month_abbreviation),
    datasets: [
      {
        label: 'NOIL',
        data: barChartData.map((data : any) => data.NOIL_COUNT),
        borderColor: '#86DFE1',
        backgroundColor: '#86DFE1',                        
      },      
      {
        label: 'PNOIL',
        data: barChartData.map((data : any) => data.PNOIL_COUNT),
        borderColor: '#DDB9FF',
        backgroundColor: '#DDB9FF',
      },
      {
        label: 'NOL',
        data: barChartData.map((data : any) => data.NOL_COUNT),
        borderColor: '#FBD586',
        backgroundColor: '#FBD586',
      },
      {
        label: 'SOL',
        data: barChartData.map((data : any) => data.SOL_COUNT),
        borderColor: '#FFACE5',
        backgroundColor: '#FFACE5',
      },
    ],
  } );

  const options:any = {
    bezierCurve : true,
    responsive: true,
    maintainAspectRatio: false, 
    datasetFill:true,   
    scaleFontColor:"#FCFCFC",
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        border: {
          color: '#B3B3B3',
        },
      },
      y: {
        beginAtZero: true,
        border: {
          display: false,
          dash: [6],
        },
      },
    },
    plugins: {       
      legend: {   
        display: false,
        position:"bottom",                     
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',                              
          boxHeight: 8,          
          boxWidth:8,                        
        },
      },
      title: {
        align:"start",    
        display: false,
        fontSize: 14,
        color:'#1F2837',
        padding: 20,
        text: 'Revenue Comparison Last Year vs This Year',                
      },
    },      
  };

  const optionsBar:any = {    
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        border: {
          color: '#B3B3B3',
        },
      },
      y: {
        beginAtZero: true,
        border: {
          display: false,
          dash: [6],
        },
      },
    },
    plugins: { 
      legend: {   
        position:"bottom",  
        fontColor:"#FCFCFC",
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',                              
          boxHeight: 8,          
          boxWidth:8,                        
        },
      },
    },      
  };

  const sortedTiles = () => {
    let keys = Object.keys(tilesData);
    let sortedKeys = keys.sort((a, b) => {
      // Extract the position number from each key
      let positionA = parseInt(a.split('_')[1]);
      let positionB = parseInt(b.split('_')[1]);
      
      // Compare positions
      return positionA - positionB;
  });
   return sortedKeys;
  }
  let i = 0;
return(
    <div className="dashboard">
         <div className="summaryCardDiv">
            {
            !isEmptyObj(tilesData) && sortedTiles().filter (key => !key.endsWith("_meta")).map((data: any, index)=>{
              // Reconstruct sorted JSON object
              console.log("Border Color for ", i );
              i = i % 4;
              const borderColor = DASHBOARD_SUMMARY_CARDS[i++].borderColor;
              console.log("Border Color value is ", borderColor);

              return <div className="summaryCard" style={{borderColor}}>
                <p className="cardTitle">{data.split('_')[0]+(i<3?" (Monthly)":'')}<hr/></p>                 
                <p className="summaryNumber">{(i>1)&& <span>$</span>}{tilesData[data]}</p>
              </div>
            })}
         </div>
         
         <div className="section2">
          <div className="chartDiv">
          <h1 className="chartHeading">Earning Overview</h1>
          <div className="chartTitle">
            <p>Revenue Comparison Last Year vs This Year</p>
            <div className="legend">
              <p><span className="dot1"/>  {!isEmptyObj(lineChartData) && lineChartData[0].current_year}</p>
              <p><span className="dot2"/>  {!isEmptyObj(lineChartData) && lineChartData[0].last_year}</p>
            </div>
          </div>
          <div className="chart"><Line id="lineChart" options={options} data={lineData} />
          {/* <p style={{fontSize:"16px", color:"#677489"}}>Status: Loaded Successfully</p>           */}
          </div>
          
          </div>
         
            <div className="piechart">
              <h1 className="chartHeading">Customer Type</h1>
              {!isEmptyObj(donut1Data) && <div className="doughnutChart ms-2"><DoughnutComponent donutData={donut1Data}/></div>}
            </div>
         </div>

         <div className="section3">
         <div className="chartDiv">
          <h1 className="chartHeading">Lien Cases by Status</h1>
          <div className="chart" id="chartId">
            <Bar options={optionsBar} data={barData}/>
            {/*<p style={{fontSize:"16px", color:"#677489"}}>Direct Social Refferal</p> */}
          </div>
          </div>
          
            <div className="piechart">
              <h1 className="chartHeading">Monthly Programs</h1>
              {!isEmptyObj(donut2Data) && <div className="doughnutChart ms-2"><DoughnutComponent donutData={donut2Data}/></div>}
            </div>
         </div>
    </div>
)
}

export default Dashboard