import React, { useContext, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useTheme } from '../Template/ThemeContext';
import AppContext from '../context/AppContext';
import { isEmptyObj } from '../context/MiscFunc';
import { hpost } from '../context/apiService';
import AlertModal from './AlertModal';
import AutoloadDataForm from './AutoloadDataForm';

interface TabPageProps {
  pageName: string;
  rowid_value?: string | number;
  currApplication: Record<string, string> | null;

}
const TabDataForm: React.FC<TabPageProps> = ({ pageName, currApplication, rowid_value }) => {
  const [tabName, setTabName] = useState<string>('');
  const [parameterValues, setParameterValues] = useState<any>({});
  const [tabData, setTabData] = useState<any>([]);
  const [error, setError] = useState<string>('');
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const Theme = useTheme();
  const { sessionData } = useContext(AppContext);
  const [progress, setProgress] = useState(0);
  let r = 1;


  useEffect(() => {
    console.log("Page Name is ", pageName);
    let params: any = {
      objecttype: "pagetabs",
      pagename: pageName,
      application_id: currApplication?.id,
      biller_id: sessionData.data["Biller Id"],
      searchcriteria: { "rowid_meta": rowid_value },
    }
    fetchData(params);
    return () => {
      setTabName("");
    }
  }, [pageName])

  const fetchData = async (params: any) => {
    let response = await hpost('/sec/data', params);
    if (response.data) {
      let pagetemplate = JSON.parse(JSON.stringify(response.data));
      console.log("Response: ", response.data);
      if (pagetemplate.hasOwnProperty("data")) {
        setTabData(pagetemplate.data);
        setTabName(pagetemplate.data[0]["tabname"]);
      } else {
        setTabName('');
        displayError('No Data Found');
      }
      setDataLoaded(true);
    } else if (response.error) {
      console.error("Error: ", response.error);
      displayError(response.error);
    };
  }
  const displayError = async (errormessage: string) => {
    setError(errormessage);
    setTimeout(() => {
      setError('')
    }, 5000)
  }

  useEffect(() => {
    console.log("Active Tab Name is ", tabName);
    if (tabName && tabData) {
      let tabObject = tabData.find((tabobj: any) => { return (tabobj.tabname === tabName) });
      let params: any = {
        objecttype: "getdatarow",
        pagename: pageName,
        application_id: currApplication?.id,
        biller_id: sessionData.data["Biller Id"],
        searchcriteria: { "rowid_meta": tabObject.row_id },
      }
      /* fetchData(params); */
    }
    }, [tabName])

  if (error) {
    return (
      <span>
        <AlertModal alerttitle="Message" msgobj={error} display={true} />
      </span>
    );
  }
  if (!isEmptyObj(tabData)) {
    console.log("Tab Data is ", tabData);

    return (
      <div >
        <Tabs activeKey={tabName} onSelect={(k) => setTabName(k as string)}>
          {
            tabData.map((tabvalue: any) => {
              return (<Tab eventKey={tabvalue.tabname} title={tabvalue.tabtitle}>
                <AutoloadDataForm pagename={tabvalue.tabinfolink} currApplication={currApplication} />
              </Tab>)
            })
          }
        </Tabs>
      </div>
    );
  } else {
    return (
      <div className="text-center">
        <div className="spinner-grow text-success" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow text-danger" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow text-warning" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow text-info" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  }
}

export default TabDataForm;
