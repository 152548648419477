import { useContext, useLayoutEffect, useRef, useState } from "react";
import { Button, Modal } from 'react-bootstrap';
import 'react-resizable/css/styles.css'; // Import resizable styles
import MicrosoftLogo from "../img/microsoftLogo.png"
import AppContext from "../context/AppContext";
import "../styles/custom-style-vars.css"
  
interface LoginModalProps {
    isOpen: boolean;
    onClose: () => void;
    currentstep: string;
    changeAuthMode: (newmode: string) => void;
    }

interface LoginCredentials {
  username: string;
  password: string;
  roletype: string;
}
  
  const LoginModal: React.FC<LoginModalProps> = ({ isOpen,onClose, changeAuthMode, currentstep}) => {
    const [messageValue, setMessageValue] = useState<String>("");
    const {isSignedUp, setIsSignedUp, sessionData, appVersion, setSessionData, applications, setApplications, currApplication, setCurrentApplication} = useContext(AppContext)
    const [credentials, setCredentials] = useState<LoginCredentials>({
      username: '',
      password: '',
      roletype: 'B'
    });
  
    if ((sessionData != null) && (sessionData !== undefined)) {
      changeAuthMode("loggedin");
    }

    const handleLoginToggle = ()=>{
      setIsSignedUp(!isSignedUp)
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { id, value } = event.target;
      setCredentials((prevCredentials) => ({
        ...prevCredentials,
        [id]: value,
      }));
    };

    const handleSubmit = (e:any)=>{
      e.preventDefault();
      // Make a POST request to the login endpoint
      const fetchUrl = process.env.REACT_APP_API_URI+'/login'
      console.log("Fetching data with ", fetchUrl);
      fetch(fetchUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      })
        .then(response => {
         if (!response.ok) {
            if (response.status === 403) {
               throw new Error("Invalid UserName or Password");
             }
             throw new Error(`Request failed with status code ${response.status}`);
         } else {
         return response.json();
         }
       })
        .then(data => {
       // Handle the response from the server
          console.log(data); // You can customize this based on your API response
          setSessionData(data);
          const allapps = data.data.Applications.split(',');
          const appvalues: Record<string, string>[] = [];
          allapps.map( (appvalue: string) => {
              const appsegs = appvalue.split('~');
              const newvar = {id: appsegs[0],  name: appsegs[1]};
              appvalues.push(newvar);
          });
          setApplications(appvalues);          
          if (appvalues.length > 0) {
          setCurrentApplication(appvalues[0]);          
          }
          setMessageValue("");
          console.log("Session Context Data is set to "+JSON.stringify(data));
          changeAuthMode("loggedin");
        })
        .catch(error => {
         console.log(error.message);
         setMessageValue(error.message);
       });
   };

    return (
    <div>   
        <Modal show={isOpen} onHide={onClose} dialogClassName="resizable-modal-dialog" centered radius="4px">
        <Modal.Header closeButton style={{backgroundColor:"#1A212E", border:"0"}}>
          <Modal.Title className="ms-auto" style={{color:"#FFFFFF"}}>{isSignedUp ? "Register" : "Login"}
          {/* <div style={{display:"flex", flexDirection:"column", gap:"10px", alignItems:"center", marginTop:"10px"}}>
              <img src={MicrosoftLogo} alt="Logo" style={{width:"50px", height:"50px"}}/>
              <p style={{color:"#FFFFFF"}}>or</p>
            </div>    */}
          </Modal.Title>                  
        </Modal.Header>
        <Modal.Body style={{backgroundColor:"#1A212E"}}>
            <form role="form" style={{display:"flex", flexDirection:"column", gap:"30px", padding:"10px 20px"}} onSubmit={(event)=>handleSubmit(event)}>
                {isSignedUp && <div className="form-group">
                <label htmlFor="fullname" style={{color:"#FFFFFF"}}> Full name</label>
                <input type="text" className="form-control" style={{backgroundColor:"#1A212E", color:"#FFFFFFB2"}} id="fullname" placeholder="Example- Carol Smith"/>
                </div>}


                <div className="form-group">
                <label htmlFor="username" style={{color:"#FFFFFF"}}> Email</label>
                <input type="text" className="form-control" id="username" onChange={handleInputChange}
                 style={{backgroundColor:"#1A212E", color:"#FFFFFFB2"}} placeholder="Example- abc@def.com"/>
                </div>

                <div className="form-group">
                <label htmlFor="password" style={{color:"#FFFFFF"}}>Password</label>
                <input type="password" className="form-control" onChange={handleInputChange}
                style={{backgroundColor:"#1A212E", color:"#FFFFFFB2"}} id="psw" placeholder="Enter password"/>
                <p style={{color:"#FFFFFF"}}>Forgot <a href="#">Password?</a></p>
                <div style={{color:"#FFFFFF", marginTop:"2px"}}>
                <label className="switch">
                  <input type="checkbox"/>
                  <span className="slider round"></span>
                </label>
                <label style={{marginLeft:"8px", fontSize:"14px"}}>Remember me</label> 
                </div>                
                </div>
                
                <button type="submit" style={{backgroundColor:"#0397B1", width:'100%', borderRadius:'4px'}} className="btn btn-success me-2">{isSignedUp ? "SIGN UP" : "LOG IN"}</button>
            </form>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor:"#1A212E", border:"0"}}>            
            <p style={{color:"#FFFFFF"}}>{isSignedUp ? "Already have an account?" :"Don't have an account?"} <a href="#" onClick={handleLoginToggle}>{isSignedUp ? "Login" : "Create one"}</a></p>
        </Modal.Footer>
      </Modal>        
    </div>
    )
  }

export default LoginModal;  
