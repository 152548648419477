import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import React, { useContext, useEffect, useState } from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import { Column, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import * as XLSX from 'xlsx';
import { useTheme } from '../Template/ThemeContext';
import AppContext from '../context/AppContext';
import { getTableColumns } from '../context/MiscFunc';
import { hpost } from '../context/apiService';
import { TableRow } from '../types/menu-item';



interface SearchFields {
    position: number;
    fieldname: string;
    fieldlabel: string;
    fieldtype: string;
    fieldvalue : string;
}

const initSearchFields : SearchFields[] =  [
  { position: 1, fieldname: 'customer_code', fieldlabel: 'Customer', fieldtype: 'text', fieldvalue: ''},
  { position: 2, fieldname: 'customer_name', fieldlabel: 'Name', fieldtype: 'text', fieldvalue: ''},
  { position: 3, fieldname: 'location_code', fieldlabel: 'Location', fieldtype: 'text', fieldvalue: ''},
  { position: 4, fieldname: 'location_address', fieldlabel: 'Street Address', fieldtype: 'text', fieldvalue: ''},
  { position: 6, fieldname: 'pgmtyp_code', fieldlabel: 'Program Type Code', fieldtype: 'text', fieldvalue: ''},
  { position: 5, fieldname: 'program_code', fieldlabel: 'Account Program Code', fieldtype: 'text', fieldvalue: ''},
  { position: 7, fieldname: 'start_date', fieldlabel: 'Program Start Date', fieldtype: 'date', fieldvalue: ''},
  { position: 8, fieldname: 'end_date', fieldlabel: 'Program End Date', fieldtype: 'date', fieldvalue: ''},
  { position: 9, fieldname: 'billing_status', fieldlabel: 'Billing Status', fieldtype: 'text', fieldvalue: ''}
];
interface SearchPageProps {
  processName: string;
  currApplication: Record<string, string> | null;
  parentProcessHandle: (selectedRows : Set<string>) => void;
}
const SearchForm: React.FC<SearchPageProps> = ({ processName, currApplication, parentProcessHandle}) => {
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [tableData, setTableData] = useState<TableRow[]>([]);
  const [columnTypes, setColumnTypes] = useState<any>({});
  const [columns, setColumns] = useState<Column<TableRow>[]>([]);
  const [searchFields, setSearchFields] = useState<SearchFields[]>(initSearchFields);
  const [selectedRow, setSelectedRow] = useState<TableRow | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);
  const [bulkSelectedRows, setBulkSelectedRows] = useState<Set<string>>(new Set());
  const [progress, setProgress] = useState(0);
  const { sessionData } = useContext(AppContext);
  const Theme = useTheme();
  let tableheadercount = 5;
  let rkey = 1;
  
   
  useEffect(() => {
    console.log("Testing");
    return () => {
      setBulkSelectedRows(new Set());
      setErrorMessage('');
      setTableData([]);
      setColumns([]);
    }
  }, [processName]);
  
 const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    state: { globalFilter },
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable<TableRow>(
    {
      columns,
      data:  tableData,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const fetchData = async (params: any) => {
    let response = await hpost('/sec/searchdata', params);
    if (response.data) {
      let pagetemplate = JSON.parse(JSON.stringify(response.data));
      console.log("Response: ", response.data);
      if (pagetemplate.hasOwnProperty("data")) {
        setTableData(pagetemplate.data as TableRow[]);
      } else {
        setTableData([]);
      }
      if (pagetemplate.hasOwnProperty("pagemeta")) {
        setColumnTypes(pagetemplate.pagemeta.columntypes);
        setColumns(getTableColumns(pagetemplate.pagemeta.columntypes));
        //setColumns(getTableColumns(pagetemplate.pagemeta.columntypes));
      }
      if (pagetemplate.hasOwnProperty("tableheadercount")) {
        tableheadercount = pagetemplate.pagemeta.headercount;
      } else {
        tableheadercount = 5;
      }
     // setDataLoaded(true);
    } else if (response.error) {
      console.error("Error: ", response.error);
      //setParameters([]);
    };
  }

  const performSearch = (event: any) => {
    event.preventDefault();
    const searchAttributes = new FormData(event.target);
    const searchObject = Object.fromEntries(searchAttributes);
    const searchJson = {
      searchCriteria: searchObject
    }

    const formData = new FormData();
    // Create a FormData object from the form element
    if (validateParameterForm(searchAttributes)) {
      // Append form data from state
      formData.append('biller_id', sessionData.data["Biller Id"]);
      const currentAppId = currApplication?.id ?? '0';
      formData.append('application_id', currentAppId);
      formData.append('pagename', processName);
      formData.append('objecttype', "progsearch");
      formData.append('searchcriteria', JSON.stringify(searchObject));

      // Convert the FormData object to a plain object
      const formObject = Object.fromEntries(formData.entries());
      
      // Convert the plain object to a JSON string
      const jsonString = JSON.stringify(formObject);
      console.log("Search Form Parameters: ", jsonString);
      setErrorMessage('');
      fetchData(formObject);
    } else {
      console.log("Search Form Data is not Valid");
      setErrorMessage("Please provide value for at least one search attribute")
    }
  }
  const validateParameterForm = (formData : any ): boolean => {
    let valid_flag : boolean = false;
    // Iterate over FormData entries
    for (const [key, value] of formData.entries()) {
      console.log(`Search Form has value: ${key}: ${value}`, " value trim is ", (value.trim()?'Y': 'N'));
      if (value.trim()) {
        console.log('Search Form set valid flag');
         valid_flag = true;
      }
    }
    return valid_flag;
  };
  
  const resetSearchClick = (event: any) => {
    event.currentTarget.reset(); // This resets the form fields
    // Reset state variables
    setSearchFields([...initSearchFields]);
    
   }

  const handleBulkRowSelection = (event: any, currentRow: TableRow) => {
    let currentRowId: string = currentRow.rowid.toString();
    console.log("Current ID: ", currentRowId);
    setBulkSelectedRows((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(currentRowId)) {
        //console.log("newly selected rows Removing Current ID: ", currentRowId);
        newSelected.delete(currentRowId);
      } else {
        //console.log("newly selected rows Adding Current ID: ", currentRowId);
        newSelected.add(currentRowId);
      }
      return newSelected;
    });

 
  };

  const handleSelectAll = () => {
    if (bulkSelectedRows.size === rows.length) {
      setBulkSelectedRows(new Set());
    } else {
      const newSelecteds = new Set(rows.map((row) => row.original.rowid.toString()));
      //console.log("newly Selected Rows ", newSelecteds);
      setBulkSelectedRows(newSelecteds);
    }
  };

   // Function to convert table data to Excel
  const exportToExcel = (data: any[], fileName: string) => {
    // Extract column order from headerGroups
    const columnOrder = [];

    // console.log("saud table data: ", Object.keys(data[0]));

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([], { header: Object.keys(data[0]) });

    // Simulate processing large data with setTimeout and update progress
    let chunkSize = 1000;
    let currentIndex = 0;

    const processChunk = () => {
      const chunk = data.slice(currentIndex, currentIndex + chunkSize);
      XLSX.utils.sheet_add_json(ws, chunk, { origin: -1, skipHeader: true });
      currentIndex += chunkSize;
      setProgress(Math.min((currentIndex / data.length) * 100, 100));

      if (currentIndex < data.length) {
        setTimeout(processChunk, 0);
      } else {
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, `${fileName}.xlsx`);
        setProgress(0); // Reset progress after completion
      }
    };

    processChunk();
  };


  const exportToPDF = (data: any[], fileName: string) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 20;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(10);

    let columnHeaders = Object.keys(data[0]).filter(function (key) {
      return !(key.includes("_"));
    }
    );

    let displayData = data.map(row => Object.fromEntries(columnHeaders.map( column => [column,row[column]])));


    // console.log("saud pdf keys", columnHeaders);
    // console.log("saud pdf values", displayData);

    doc.text(fileName, marginLeft, 20);
    autoTable(doc, {
      head: [columnHeaders],
      body: displayData.map(row => Object.values(row))

    }
    );

    doc.save(`${fileName}.pdf`);
 }

  const getTableDataAsJson = () => {
    return rows.map((row) => {
      prepareRow(row);
      return row.original;
    });
  };
  const handleExport = () => {
    const xlsData = getTableDataAsJson();
    // console.log("saud json:", getTableDataAsJson());
    exportToExcel(xlsData, 'table_data');
  };

  const handleExportPDF = () => {
    const xlsData = getTableDataAsJson();
    // console.log("saud json pdf:", getTableDataAsJson());
    exportToPDF(xlsData, 'table_data');
  };
  const isAllSelected = bulkSelectedRows.size === rows?.length??0;
  const isRowSelected = (rowid: string) => bulkSelectedRows.has(rowid)

  const handleRowClick = (row: TableRow) => {
    console.log("handle Row Click");
    
  };
  function processSelected(event : any ) : void {
    if (bulkSelectedRows.size > 0) {
      setErrorMessage("Processing selected "+bulkSelectedRows.size+" records...")
      parentProcessHandle(bulkSelectedRows);
    } else {
      setErrorMessage("No selected rows. Process not submitted ");
    }
  }

      return (
      <div>
          <div className='d-flex'>
            <form id="selectionsearchform" name="selectionsearchform" className="row g-3" onSubmit={performSearch} onReset={resetSearchClick} >
              {
                (searchFields) && searchFields.map((field, fieldIndex) => {
                    let r = field.position;
                    return (
                      <div className="d-flex col-md-3">
                        {
                          <div className="flex-grow-1">
                            <label key={r++} htmlFor={field.fieldname} className="form-label">{field.fieldlabel}</label>
                            <input key={r++} type={field.fieldtype} id={field.fieldname} name={field.fieldname} className="form-control" />
                          </div>
                        }
                      </div>
                    )
                  }
                )
              }
              <div className="d-flex w-100">
                <button key={rkey++} type="reset" className="ms-auto p-2 mb-2" style={{ backgroundColor: '#0397B1', marginLeft: "10px" }}>Clear</button>
                <button key={rkey++} type="submit" className="p-2 mb-2" style={{ backgroundColor: '#0397B1', marginLeft: "10px" }}>Search</button>
              </div>
            </form>
          </div>
          {(errorMessage) && <div className="text-danger">{errorMessage}</div>}
          <div className='d-flex'>
          <p className="fs-5">Search:</p>
          <div className="ms-2 flex-grow-1">
            <div className="d-flex">
              <input key={rkey++}
                value={globalFilter || ''}
                onChange={e => setGlobalFilter(e.target.value)}
                className="form-control w-50"
              />
              {/*(columns.length > (tableheadercount ?? 4)) && <div> <i className="bi bi-binoculars mx-2 h5" id="searchIcon" onClick={handleSearchClick}></i></div> */}
              <div className="ms-auto"><h4>Selected Rows: {bulkSelectedRows.size}</h4></div>
              <div className="ms-auto"><h4>Total Rows: {rows.length}</h4></div>
              <div><button onClick={handleExportPDF}>PDF</button><button onClick={handleExport}>XLSX</button></div>
              {progress > 0 && (
                <ProgressBar now={progress} label={`${progress}%`} animated striped />
              )}
            </div>
          </div>
        </div>


        {/* <button style={{ backgroundColor: '#0397B1', marginLeft:"10px"}} onClick={() => {setIsModalOpen(true); setSelectedRow(null);}}>Edit Mode</button> */}

        <div style={{
          overflowX: 'auto', maxHeight: '300px', minWidth: '100%',
          borderTop: '1px solid #ddd',
          borderBottom: '1px solid #ddd',
          borderLeft: '1px solid #ddd',
          borderRight: '1px solid #ddd',
        }}>
          <table id={processName + "_table"} {...getTableProps()} className="w-100">
            <thead style={{ position: 'sticky', top: '-1px', zIndex: 1, backgroundColor: Theme.topbarBackgroundColor }}>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    <th>
                      <input
                        type="checkbox"
                        checked={isAllSelected}
                        onChange={handleSelectAll}
                      />
                    </th>
                  {headerGroup.headers.slice(0, tableheadercount ?? 5).map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, rowIndex) => {
                prepareRow(row);
                const isRowHovered = rowIndex === hoveredRowIndex;
                const isItemSelected = isRowSelected(row.original.rowid + '');

                const rowStyles: React.CSSProperties = {
                  backgroundColor: isRowHovered ? Theme.topbarBackgroundColor : Theme.backgroundColor, // Apply background color on hover
                  color: isRowHovered ? Theme.topbarColor : rowIndex % 2 === 0 ? Theme.navLinkColor : Theme.primaryColor,
                  cursor: 'pointer'
                };
                return (
                  <tr
                    {...row.getRowProps()}
                    onClick={() => handleRowClick(row.original)}
                    style={rowStyles}>
                    {(tableData?.length ?? 0 > 0) && <td>
                      <input
                        key={rkey++}
                        type="checkbox"
                        checked={isItemSelected}
                        onChange={(event: any) => handleBulkRowSelection(event, row.original)}
                      />
                    </td>}
                    {row.cells.slice(0, tableheadercount ?? 5).map((cell, cellIndex) => {
                      const colkey = cell.column.id;
                      const columnkey = Object.keys(columnTypes).find((collabel: any) => columnTypes[collabel].columnIndex === colkey);
                      const cellcol = columnTypes[columnkey ?? 'none']
                      //  console.log("Cell Accessor is ", colkey, cellcol);
                      return (
                        <td
                          {...cell.getCellProps()} >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        { (bulkSelectedRows.size > 0) && <Button type="button" variant="primary"  className="ml-2" onClick={processSelected}>Process Selected</Button>}
      
      </div>
    );
  }


export default SearchForm;

