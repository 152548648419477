// src/components/MultiLevelMenuItem.tsx
import React, { useEffect, useState } from 'react';
import {
  DragSourceMonitor,
  DropTargetMonitor,
  useDrag,
  useDrop
} from 'react-dnd';
import { MultiLevelMenuItem as MultiLevelMenuItemType } from '../types/menu-item';

interface MultiLevelMenuItemProps {
  originalItem: MultiLevelMenuItemType;
  index: number;
  iniEditMode?: boolean;
  moveItem: (fromItem: MultiLevelMenuItemType, toItem: MultiLevelMenuItemType) => void;
  onDropItem: (targetItem: MultiLevelMenuItemType) => void;
  onMoveToNewLevel: (itemId: string, newParentId: string) => void;
  onUpdateData: (changedItem: MultiLevelMenuItemType, actionType?: string, itemIndex?: number) => void;
}


const MultiLevelMenuItem2: React.FC<MultiLevelMenuItemProps> = ({
  originalItem,
  index,
  iniEditMode,
  moveItem,
  onDropItem,
  onMoveToNewLevel,
  onUpdateData
}) => {
  const [item, setItem] = useState<MultiLevelMenuItemType | null>(null);
  const [draggedItem, setDraggedItem] = useState<any>(null);
  const [editMode, setEditMode] = useState(iniEditMode);
  const [editedLabel, setEditedLabel] = useState(item?.prompt);
  const [expanded, setExpanded] = useState<Boolean>(true);

  console.log(" Rendering Original Item ", originalItem.option_id, originalItem.position, originalItem.prompt);

  useEffect(() => {
    setItem(originalItem);
  }, [originalItem]);

  const [{ isDragging }, drag] = useDrag({
    type: 'MenuItem',
    item: { ...item, index }, // Include the index in thente item object
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const handleDropPropagation = (event: Event) => {
    event.stopPropagation();
  };

  const [, drop] = useDrop({
    accept: 'MenuItem',
    /*
    hover: (hoverItem: any, monitor) => {
      console.log('Hover index:', hoverItem.index, ' Key: ', hoverItem.option_id);
      const draggedItem = monitor.getItem();
      const hIndex = hoverItem.index;
      console.log("Hover Index: ", hoverItem.index, " Monitor: ", monitor.getItem().index);
      setHoveredItem(draggedItem);
      setHoverIndex(hIndex);

       // Get the dragged item
       setDraggedItem(hoverItem);
    },
    */
    hover: (hoverItem: any, monitor) => {
      const draggedItem = monitor.getItem();
      // Get the dragged item
      setDraggedItem(draggedItem);
    },
    drop: (droppedItem: any, monitor: DropTargetMonitor) => {
      console.log("Dragged Item: ", droppedItem);
      console.log("Dropped Item: ", item);

      // Stop the event propagation to the parent item
      const handlerId = monitor.getHandlerId();

      /*
      const dropIndex = droppedItem.index;
      const dropItem = droppedItem;
      console.log(" Drop Index: ", droppedItem.index, ' Monitor Index ', monitor.getItem().index);

       // Access the dragged item using monitor.getItem()
       const draggedItem = monitor.getItem();
       console.log('Dragged Item:', draggedItem);
       console.log("Dropped Item: ", dropItem);
       

      const dragIndex = draggedItem.index;
      console.log('Drag Index: ', dragIndex, 'Drop Index: ', dropIndex);
      
      
      if (dragIndex === dropIndex) {
        return;
      }
      */
      // moveItem(dragIndex, hoverIndex);
      //moveItem(draggedItem,droppedItem)
    },
  });



  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const targetname = event.target.id.substring(4);
    if (item !== null) {
      console.log("In Handle Change: [", targetname, '] New Value ', event.target.value);
      const newItem = { ...item, [targetname]: event.target.value }
      console.log(' New Item: ', newItem);
      setItem(newItem);
    }
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const toggleExpandedMode = () => {
    setExpanded(!expanded);
  }
  const handleSaveClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log("In handle Save Click");
    event.stopPropagation();
    if (item) {
      onUpdateData(item);
    }
    setEditMode(false);

    //onDropItem(item.option_id, editedLabel);
  };

  const handleCancelClick = () => {
    if (item) {
      if (item.status === 'N') {
        onUpdateData(item, 'X');
      } else {
        setItem(originalItem);
        setEditMode(false);
      }
    }
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent the default form submission behavior
    // Handle your form submission logic here
  };


  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    console.log("In Handle Drop Event");
    console.log("Dropped Item: ", item);
    console.log("Dragged Item: ", draggedItem);
    if (item)
    moveItem(draggedItem, item);
    // Stop event propagation to parent elements
    event.stopPropagation();

  };
  if (item == null) {
    return <div>Loading...</div>;
  } else
    return (
      <div ref={(node) => drag(drop(node))} style={{ opacity: isDragging ? 0.5 : 1 }} onDrop={handleDrop} >
        {editMode ? (
          <div>
            <form className="col-lg-3 g-3 border border-primary" id={item.prompt + '_form'} onSubmit={handleSubmit}>
              <div className="container-sm">
                <label htmlFor="itemname" className="form-label">Name</label>
                <input type="text" id="itemname" name="itemname" className="form-control" onChange={handleChange} value={item.name} />
              </div>
              <div className="container-sm">
                <label htmlFor="optionid" className="form-label">ID</label>
                <input type="text" id="optionid" name="optionid" className="form-control" onChange={handleChange} value={item.option_id} />
              </div>
              <div className="container-sm">
                <label htmlFor="itemposition" className="form-label">Position</label>
                <input type="text" id="itemposition" name="itemposition" className="form-control" onChange={handleChange} value={item.position} />
              </div>
              <div className="container-sm">
                <label htmlFor="itemprompt" className="form-label">Prompt</label>
                <input type="text" id="itemprompt" name="itemprompt" className="form-control" onChange={handleChange} value={item.prompt} />
              </div>
              <div className="container-sm">
                <label htmlFor="itemicon" className="form-label">Icon</label>
                <input type="text" id="itemicon" name="itemicon" className="form-control" onChange={handleChange} value={item.icon} />
              </div>
              <div className="d-flex flex-row-reverse">
                <button className="btn m-2" onClick={handleSaveClick}><i className="bi bi-check-square-fill"></i></button>
                <button className="btn m-2" onClick={handleCancelClick}><i className="bi bi-x-circle-fill"></i></button>
              </div>
            </form>

          </div>

        ) : (
          <div>

            {(item.children) && (item.children.length > 0) && expanded &&
              <button className="btn" onClick={toggleExpandedMode}><i className="bi bi-dash-square"></i></button>
            }
            {(item.children) && (item.children.length > 0) && !expanded &&
              <button className="btn" onClick={toggleExpandedMode}><i className="bi bi-plus-square"></i></button>
            }
            {(item.children?.length === 0) &&
              <button className="btn" onClick={toggleExpandedMode}><i className="bi bi-asterisk"></i></button>
            }
            {item.prompt + item.option_id + item.position}
            <button className="btn" onClick={handleEditClick}><i className="bi bi-pencil-square"></i></button>
            <button className="btn" onClick={() => { onUpdateData(item, 'N') }}><i className="bi bi-plus-circle"></i></button>
            <button className="btn" onClick={() => { onUpdateData(item, 'S') }}><i className="bi bi-list-nested"></i></button>
            <button className="btn" onClick={() => { onUpdateData(item, 'X') }}><i className="bi bi-x-octagon-fill"></i></button>
          </div>
        )}
        {item.children && expanded && (
          <div className="subitem">
            {item.children.map((child, index) => (
              <MultiLevelMenuItem2
                key={child.option_id}
                originalItem={child}
                index={index}
                moveItem={moveItem}
                onDropItem={() =>
                  onDropItem(child)
                }
                onMoveToNewLevel={onMoveToNewLevel}
                onUpdateData={onUpdateData}
              />
            ))}
          </div>
        )}
      </div>
    );
};

export default MultiLevelMenuItem2;
