import React, { useContext, useEffect, useRef, useState } from 'react';
import AppContext from '../context/AppContext';
import { getStartingNumber, isEmptyObj, isEmptyOrNull, isValidDate, isValidDateOrTimestamp, sortObjectKeys } from '../context/MiscFunc';
import { hpost, hpostform } from '../context/apiService';
import docimage from '../img/docimage.jpg';
import pdfimage from '../img/pdfimage.jpg';
import { AddressComponents, BaseContentProps } from '../types/menu-item';
import ImageUpload64 from '../utilities/ImageUpload64';
import AddressInput from './AddressInput';
import AlertModal from './AlertModal';
import LargeFilterList from './LargeFilterList';

/*
declare global {
  interface Window {
    google: any;
  }
}
*/

// const contentPaneStyles: React.CSSProperties & Record<string, string> = {
//   'backgroundColor': Theme.backgroundColor,
//   '--text-color': Theme.primaryColor,
//   '--container-width': Theme.containerWidth,
//   '--border-style': Theme.containerBorderStyle,
//   '--border-width': Theme.containerBorderWidth,
//   '--border-color': Theme.containerBorderColor,
// };

interface AutoloadPageProps {
  pagename: string;
  currApplication: Record<string, string> | null;
}

const AutoloadDataForm: React.FC<AutoloadPageProps> = ({ pagename, currApplication }) => {
  const [contentProps, setContentProps] = useState<BaseContentProps>();
  const [formData, setFormData] = useState<any>({});
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [columnTypes, setColumnTypes] = useState<any>([]);
  const [dataChanges, setDataChanges] = useState<Record<string, any>>({});
  const [dataerror, setDataError] = useState<string>('');
  const [currentrow, setCurrentRow] = useState<number>(1);
  const [saveModal, setSaveModal] = useState<any>();
  const [successMessage, setSuccessMessage] = useState<string>('Data saved successfully');
  const [saveModalTimer, setSaveModalTimer] = useState<boolean>(false);
  //const [linkArray, setLinkArray] = useState<Array<string>>(["billerservlocations", "billerprogaccts"]);
  // const [linkPage, setLinkPage] = useState<string>('');
  const { setCurrAppLogo } = useContext(AppContext);
  const { sessionData, setRefreshData, searchFilter, setSearchFilter, refreshData, listItemSelected, setListItemSelected } = useContext(AppContext);
  const streetRef = useRef<HTMLInputElement>(null);
  const cityRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<HTMLInputElement>(null);
  const zipcRef = useRef<HTMLInputElement>(null);
  const countryRef = useRef<HTMLInputElement>(null);
  const [chkBoxToggle, setChkbxToggle] = useState<any>();
  const [action, setAction] = useState<string>('');
  const custInputRef = useRef<HTMLInputElement>(null);
  const [custInputValue, setCustInputValue] = useState<string>('');
  const locaInputRef = useRef<HTMLInputElement>(null);
  const [locaInputValue, setLocaInputValue] = useState<string>('');
  const acctInputRef = useRef<HTMLInputElement>(null);
  const [acctInputValue, setAcctInputValue] = useState<string>('');

  //console.log("Data Object is ", dataobject);
  //console.log("Forms Data is ", formData);
  

  const fetchData = async (withFilter: boolean) => {
    let params: any = withFilter?{
      objecttype: "pageobject",
      pagename: pagename,
      application_id: currApplication?.id,
      biller_id: sessionData.data["Biller Id"],
      searchcriteria: searchFilter ? searchFilter : '',
    } :
    {
        objecttype: "pageobject",
        pagename: pagename,
        application_id: currApplication?.id,
        biller_id: sessionData.data["Biller Id"],
        
      };
    

    console.log("Before Data Fetch ", pagename, ' for application ', currApplication);

    let response = await hpost('/sec/data', params);
    if (response.data) {
      let pagetemplate = JSON.parse(JSON.stringify(response.data));
      setContentProps({
        dataObj: response.data,
        templateName: (isEmptyObj(pagetemplate.pagemeta) ? 'DataForm' : pagetemplate.pagemeta.templateName),
        pageTitle: pagetemplate.pagemeta.page_title + '',
        pageActions: pagetemplate.pagemeta.pageactions + '',
        tableheadercount: pagetemplate.pagemeta.tableheaderscount,
      });

      console.log("Response: ", contentProps);
      if (pagetemplate.hasOwnProperty("data")) {
        setFormData(pagetemplate);
        setCurrentRow(1);
        if (pagetemplate.data[0].hasOwnProperty("Logo")) {
          if (!isEmptyObj(pagetemplate.data[0].Logo)) {
            setCurrAppLogo(pagetemplate.data[0].Logo);
          }
        }
      }
      if (pagetemplate.hasOwnProperty("pagemeta")) {
            const dataobject = pagetemplate;
            const newobject = JSON.parse('{}');
            const columntypes =pagetemplate.pagemeta.columntypes;
            if (!pagetemplate.hasOwnProperty("data")) {
               Object.keys(columntypes).map((datakey, j) => {
                    newobject[datakey] = '';
                    if (datakey.startsWith('biller_id')) {
                         newobject[datakey] = sessionData.data["Biller Id"] ?? '1001';
                    }
                });
                dataobject['data'] = [newobject];
                setFormData(dataobject);
                setAction("new");
                setCurrentRow(1);
             }
      } else if (!pagetemplate.hasOwnProperty("data")) {
          setDataError("No Data Found");
          setColumnTypes({});
      }
      setDataLoaded(true);
      setDataChanges({});
    } else if (response.error) {
      console.error("Error: ", response.error);
      setContentProps({
        currentError: response.error
      });
    };
  }

  useEffect(() => {
    // Fetch data using the API service
    console.log("Current Application is set to ", currApplication, ' Page is ', pagename);
    // Fetch data using the API service
    setSearchFilter('');
    if (refreshData) {
      setRefreshData(false)
    }
    fetchData(false);
    
  }, [currApplication, pagename, refreshData]);


  useEffect(() => {
    // Fetch data using the API service
    console.log("Current Search Filter Value is  ", searchFilter, " for Page ", pagename);
    // Fetch data using the API service
    if (isEmptyOrNull(searchFilter)) {
      fetchData(false);
    } else {
      fetchData(true);
    }
  }, [searchFilter]);


  const handleChkbxToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChkbxToggle(!chkBoxToggle)
  }

  const initDataChange = () => {
    const existingobj = formData.data[currentrow - 1];
    const newobject = JSON.parse('{}');
    const columntypes = formData.pagemeta.columntypes;
    Object.keys(columntypes).filter(datakey => (columntypes[datakey].columnDisplayType === 'key') ||
      (datakey.endsWith("_hidden"))).map((datakey, j) => {
        newobject[datakey] = existingobj[datakey];
      });
    return newobject;
  }

  const setRowCopy = (selectionname: string) => {
    const existingobj = formData.data[currentrow - 1];
    const newobject = JSON.parse('{}');
    const columntypes = formData.pagemeta.columntypes;
    Object.keys(columntypes).map((datakey, j) => {
      switch (columntypes[datakey].columnDisplayType) {
        case null:
        case "meta":
        case "key":
        case "hidden":
        case "display":
              newobject[datakey] = existingobj[datakey];
              break;
        case "groupstart":
        case "groupend":
        case "style":
              break;
        case "unique":
              newobject[datakey] = selectionname.toUpperCase();
              break;
        case "auto":
              newobject[datakey] = 'AUTO-GEN';
              break;
        default:
              if (columntypes[datakey].columnDisplayType.startsWith('llist')) {
                newobject[datakey] = '';
              } else {
                if (dataChanges.hasOwnProperty(datakey)) {
                  if (selectionname === 'copy') {
                    newobject[datakey] = dataChanges[datakey];
                  } else {
                    newobject[datakey] = '';
                  }
                } else {
                  if (selectionname === 'copy') {
                    newobject[datakey] = existingobj[datakey];
                  } else {
                    newobject[datakey] = '';
                  }
                }
              }
          }
    });
    setDataChanges(newobject);
  }

  const handleInputBlur = (ref: React.RefObject<HTMLInputElement>) => {
    if (ref.current) {
      console.log("In Parent Blur Field Name ", ref.current.name, " Value ", ref.current.value);
      updateChangedValue(ref.current.name, ref.current.value, ref.current.name);
    }
  };

  const updateChangedValue = (name: string, value: any, id: any) => {
    const existingobj = formData.data[currentrow - 1];
    const columntypes = formData.pagemeta.columntypes;
    const columndisplaytype = formData.pagemeta.columntypes[id].columnDisplayType;
    let newvalue = value;
    if (columndisplaytype === 'checkbox' || columndisplaytype === 'chktoggle') {
      let currentvalue = existingobj[id];
      if (dataChanges.hasOwnProperty(id)) {
        currentvalue = dataChanges[id];
      }
      if (currentvalue.toLowerCase() === 'y') {
        newvalue = 'N';
      } else {
        newvalue = 'Y'
      }
    }
    if (columndisplaytype === 'unique' || columndisplaytype === 'uppercase') {
      newvalue = newvalue.toUpperCase();
    }

    if (columndisplaytype.startsWith("llist")) {
      newvalue = getStartingNumber(value);
    }

    //console.log("Existing Object: "+JSON.stringify(existingobj));

    if (isEmptyObj(dataChanges)) {
      let newobject = initDataChange();
      console.log("Auto New Object: " + JSON.stringify(newobject));
      newobject[id] = newvalue;
      setDataChanges(newobject);
    } else {
      setDataChanges((prevData) => ({ ...prevData, [id]: newvalue }));
    }
    console.log(JSON.stringify(dataChanges));
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value, id } = e.target;
    updateChangedValue(name, value, id);
  }


  const handleStreetChange = (newvalue: string) => {
    if (streetRef.current !== null) {
      const existingobj = formData.data[currentrow - 1];
      if (isEmptyObj(dataChanges)) {
        let newobject = initDataChange();
        console.log("New Object: " + JSON.stringify(newobject));
        newobject[streetRef.current.id] = newvalue;
        setDataChanges(newobject);
      } else {
        let newobject = { [streetRef.current.id]: newvalue }
        setDataChanges((prevData) => ({ ...prevData, ...newobject }));
      }
    }
  }
  const handleAddressChange = (components: AddressComponents) => {
    console.log("Handle Address Change is called with " + JSON.stringify(components));
    let newdataobj = JSON.parse("{}");
    if (isEmptyObj(dataChanges)) {
      newdataobj = initDataChange();
      //JSON.parse(JSON.stringify(formData.data[currentrow - 1]));
    } else {
      newdataobj = ({});
    }
    if ((streetRef.current) &&
      (streetRef.current?.value !== components.street)) {
      //cityRef.current.value = components.city;
      newdataobj[streetRef.current.id] = components.street;
      console.log("Set Street to " + components.street);
      //cityRef.current.dispatchEvent(new Event('change', { bubbles: false }));
    }

    if ((cityRef.current) &&
      (cityRef.current?.value !== components.city)) {
      //cityRef.current.value = components.city;
      newdataobj[cityRef.current.id] = components.city;
      console.log("Set City to " + components.city);
      //cityRef.current.dispatchEvent(new Event('change', { bubbles: false }));
    }

    if ((stateRef.current) &&
      (stateRef.current?.value !== components.state)) {
      //stateRef.current.value = components.state;
      newdataobj[stateRef.current.id] = components.state;
      console.log("Set State to " + components.state);
      //stateRef.current.dispatchEvent(new Event('change', { bubbles: false }));
    }

    if ((zipcRef.current) &&
      (zipcRef.current?.value !== components.postalCode)) {
      //zipcRef.current.value = components.postalCode;
      newdataobj[zipcRef.current.id] = components.postalCode;
      console.log("Set City to " + components.postalCode);
      // zipcRef.current.dispatchEvent(new Event('change', { bubbles: true }));
    }

    if ((countryRef.current) &&
      (countryRef.current?.value !== components.country)) {
      countryRef.current.value = components.country;
    }
    if (isEmptyObj(dataChanges)) {
      setDataChanges(newdataobj);
    } else {
      setDataChanges((prevData) => ({ ...prevData, ...newdataobj }));
    }

  }
  useEffect(() => {
    console.log("Changed Data is " + JSON.stringify(dataChanges))
  }, [dataChanges])

  const handleCurrentRow = (differential: number, total: number) => {

    if ((currentrow + differential) > 0 && (currentrow + differential <= total)) {
      if (!isEmptyObj(dataChanges)) {
        if (!window.confirm("Discard Changes?")) {
          return;
        }
      }
      setDataChanges({});
      setCurrentRow(currentrow + differential);
    } else {
      // alert("Reached data boundry");
    }
  }

  const handleToggleClick = () => {

  }
  const fileupload = (files: File[] | null) => {
    if (files == null) {
      return;
    }
    const params = {
      application: currApplication?.id,
      biller: 1001,
      description: 'Logo Upload',
      source: pagename
    };

    files.map(async (newfile) => {
      let formData = new FormData();
      formData.append('file', newfile);
      formData.append('paraobj', JSON.stringify(params));
      try {
        console.log("Loading....", newfile.name);
        let response = await hpostform('/file/upload', formData);
        if (response.data) {
          console.log("Response: ", response.data);
        } else if (response.error) {
          console.error("Error: ", response.error);
        };
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    });
    // alert("Total Files to upload: " + files.length)
  }

  const fileupload64 = (id: string, imagevalue: string | null) => {
    if (imagevalue == null) {
      return;
    }
    console.log("File Upload ID " + id);
    if (isEmptyObj(dataChanges)) {
      let newobject = initDataChange();
      newobject[id] = imagevalue;
      setDataChanges(newobject);
    } else {
      setDataChanges((prevData) => ({ ...prevData, [id]: imagevalue }));
    }
    console.log(JSON.stringify(dataChanges));
  }

  const handleCancel = (e: React.FormEvent) => {
    if (listItemSelected)
      setListItemSelected(false)
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isEmptyObj(dataChanges)) {
      // alert("No Changes done on this page");
      return;
    }
    if (validateForm()) {
      //onSubmit(formData);
      //console.log('Submitted data:', formData);
      //console.log('Columns List '+JSON.stringify(formData.Status))
      //console.log('Data Values '+JSON.stringify(formData["Established Date"].columnType));
      const entries = Object.entries(dataChanges);
      for (let [key, value] of entries) {
        console.log(key, value);
      }

      // Make API call to update data
      const params = {
        objecttype: "pageobject", pagename: pagename,
        biller_id: "1001",
        action: (action === '' ? 'save' : action),
        application_id: currApplication?.id,
        changeload: dataChanges
      };

      console.log("Before Data Fetch " + pagename);
      let response = await hpost('/sec/updatedata', params);
      if (!response.error) {
        setSaveModal(true)
        //console.log("Success Response is ", response.data);
        const responseobj = JSON.parse(JSON.stringify(response.data));
        //setDataError(responseobj.message);
        if (responseobj.hasOwnProperty("result") && responseobj.hasOwnProperty("message")) {
          if (responseobj.result === 'Success') {
            setSuccessMessage(responseobj.message);
          }
        } else {
          setSuccessMessage("Data saved successfully");
        }
        setTimeout(() => {
          setSaveModal(false)
          setListItemSelected(false)
          setRefreshData(true)
          setDataError('')
        }, 5000)
      }
      else if (response.error) {
        console.error(response.error);
        setDataError(response.error);
        setTimeout(() => {
          setSaveModal(false)
          setDataError('');
          setListItemSelected(true);
        }, 5000)
      } else {
        if (dataChanges.hasOwnProperty("Logo")) {
          setCurrAppLogo(dataChanges.Logo);
        }
      }
    } else {
      console.error("Form Data is not Valid");
      setDataError("Form Data is not Valid");
    }

  }


  const handleReset = (e: React.FormEvent) => {
    // alert("Handling Reset");
    const entries = Object.entries(dataChanges);
    for (let [key, value] of entries) {
      console.log(key, value);
    }
    setDataChanges({});
    setDataError('');
  };

  // Handle button click
  const handleButtonAction = (event: React.MouseEvent<HTMLButtonElement>) => {
    const buttonName = event.currentTarget.name;
    if ((buttonName === 'copy') || (buttonName === 'new')) {
      setRowCopy(buttonName);
      setAction('new'); // Update the action state to indicate it is a new row
      event.preventDefault();
    } else {
      console.log("Action Button is ", buttonName); // Log the button name
    }
  };

  const validateForm = (): boolean => {
    // Perform validation logic for each field
    // Set error message for fields that fail validation
    // Example: Required field validation
    /*
    if (!formData.name) {
      newErrors.name = 'Name is required';
    }
*/
    // Add more validation rules as needed

    setDataError('');
    return (!dataerror);

    // Return true if the form is valid, false otherwise
    //return Object.keys(newErrors).length === 0;
  };
  /*
    const displayModalLink = (linkedPageName: string) => {
      setLinkPage(linkedPageName);
    }
    if (linkPage) {
      return (<ModalDataForm currApplication={currApplication} pageName={linkPage} setLinkedPage={setLinkPage} />)
    }
      */
  if (dataerror) {
    return (
      <span>
        <AlertModal alerttitle="Error" msgobj={dataerror} display={true} />
        {/*
          Object.keys(errors).map((errorkey, i) => {
            return (<h1 key={i}>{errors[errorkey]}</h1>)
          }
          )
          */
        }
      </span>
    );
  } else if (formData.hasOwnProperty("pagemeta") &&
    (Object.keys(formData.pagemeta.columntypes).length > 0)) {
    let columnTypes = formData.pagemeta.columntypes;
    let sortedKeys = sortObjectKeys(formData.pagemeta.columntypes);
    console.log("Curent Row is set to " + currentrow);
    console.log("Sorted Keys are " + JSON.stringify(sortedKeys));
    let rowdata = isEmptyObj(dataChanges) ? formData.data[currentrow - 1] : { ...formData.data[currentrow - 1], ...dataChanges };
    let r = 1;
    let currentSection: JSX.Element[] = [];
    let currentJSX: JSX.Element | null = null;
    let SectionStart: boolean = false;
    return (
      <div className="d-flex row">
        {/*
          linkArray.map((linkedobject) => {
            return (
              <div className="col-6 d-flex justify-content-start">
                <button type="button" className="btn" onClick={() => displayModalLink(linkedobject)}>
                  <i className="bi bi-caret-up-square"></i>
                </button>
              </div>)
          })
        */}
        <form className="row g-3" id={pagename + '_form'} onSubmit={handleSubmit} onReset={handleReset}>
          {

            sortedKeys.filter(datakey => (datakey !== 'rownumber_meta') &&
              (!datakey.endsWith("_hidden"))).map(datakey => {
                console.log("In A- Data Key is [" + datakey + "] value is " + rowdata[datakey] + " Display Type is " + columnTypes[datakey]?.columnDisplayType);

                if (datakey.endsWith("_divider")) {
                  return (
                    <div key={r++} className="p-1 bg-secondary w-100"></div>
                  );
                }
                let columnObj = columnTypes[datakey];
                switch (columnObj.columnDisplayType) {
                  case null:
                  case "meta":
                  case "key":
                  case "hidden":
                    return (
                      <input key={r++} type="hidden" id={datakey} name={columnObj.columnName} defaultValue={rowdata[datakey]} />
                    );
                  case "groupstart_style":
                    SectionStart = true;
                    currentSection = []
                    currentJSX = null;
                    break;
                  case "groupend_style":
                    SectionStart = false;
                    currentJSX = (<div className="form-section row g-3">
                      <h4>{columnObj.columnValueStr}</h4>
                      {currentSection.map(sectionItem => {
                        { return (sectionItem) }
                      })
                      }
                    </div>)
                    break;
                  case "auto":
                    currentJSX =
                      <div key={r++} className="col-lg-6">
                        <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                        <input key={r++} type="text" id={datakey} name={columnObj.columnName}
                          className="form-control text-uppercase" readOnly
                          value={rowdata[datakey]} title={`${action !== "new" ? 'Display Only attribute' : 'Auto Generated Value'}`} />
                      </div>
                    break;
                  case "unique":
                    currentJSX =
                      <div key={r++} className="col-lg-6">
                        <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                        <input key={r++} type="text" id={datakey} name={columnObj.columnName}
                          className="form-control text-uppercase" onChange={handleChange}
                          value={rowdata[datakey]} title={`${action !== "new" ? 'Display Only attribute' : 'requires unique value'}`} readOnly={action !== 'new'} />
                      </div>
                    break;
                  case "upper":
                    currentJSX = (
                      <div key={r++} className="col-lg-6">
                        <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                        <input key={r++} type="text" id={datakey} name={columnObj.columnName} className="form-control text-uppercase" onChange={handleChange} value={rowdata[datakey]} />
                      </div>
                    )
                    break;
                  case "docupload":
                    currentJSX = (<div key={r++} className="col-lg-12">
                      <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                      <ImageUpload64 file_input_id={datakey} currentValue={rowdata[datakey]} onUpload={fileupload64} maxFileSize={65000} inputButtonLabel="Choose Image" />
                    </div>)
                    break;
                  case "doclist":
                    const listvalues = rowdata[datakey].split("~");
                    currentJSX = (
                      <div key={r++} className="col-lg-6">
                        <ol className="list-group list-group-numbered">
                          {
                            listvalues.map((option: string, idx: any) => (
                              <li key={r++} className="list-group-item d-flex justify-content-between align-items-start row">
                                <div className="ms-2 me-auto">
                                  {(() => {
                                    const optionvalues = option.split('&');
                                    console.log('option ' + option + ' split values ' + optionvalues);
                                    console.log('First ' + optionvalues[0]);
                                    console.log('Second ' + optionvalues[1]);
                                    console.log('Third ' + optionvalues[2]);
                                    const optionComponents = [];
                                    optionComponents.push(<div className="fw-bold">{optionvalues[1]}</div>)
                                    if (optionvalues[1].toLowerCase().endsWith(".docx")) {
                                      optionComponents.push(<img src={docimage} className="img-thumbnail" style={{ width: 100 }} alt="No Document" />)
                                    } else if (optionvalues[1].toLowerCase().endsWith(".pdf")) {
                                      optionComponents.push(<img src={pdfimage} className="img-thumbnail" style={{ width: 100 }} alt="No Document" />)
                                    } else {
                                      optionComponents.push(<img src={process.env.REACT_APP_API_URI + "/file/tnimage?documentid=" + optionvalues[0]} className="img-thumbnail" style={{ width: 100 }} alt="No Document" />)
                                    }
                                    return optionComponents;
                                  })()}
                                </div>
                              </li>
                            ))
                          }
                        </ol>
                      </div>
                    )
                    break;
                  case "type3":
                    currentJSX = (
                      <div key={r++} className="col-md-4">
                        <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                        <input key={r++} type="text" id={datakey} name={columnObj.columnName} className="form-control" onChange={handleChange} value={rowdata[datakey]} />
                      </div>
                    )
                    break;
                  case "type3list":
                    currentJSX = (
                      <div className="col-md-4">
                        <label htmlFor={datakey} className="form-label">{datakey}</label>
                        <select key={r++} id={datakey} name={columnObj.columnName} className="form-select" aria-label="Status" onChange={handleChange} value={rowdata[datakey]} >
                          <option value="A">Active</option>
                          <option value="I">Inactive</option>
                        </select>
                      </div>
                    )
                    break;
                  case "chktoggle":
                    currentJSX = (
                      <div className="col-md-4">
                        <label className="form-label" htmlFor={datakey}>{datakey}</label>
                        <div>
                          <fieldset key={r++} id="chkToggleFieldset" className="radio btn-group">
                            <input key={r++} id={datakey} name={columnObj.columName} className="btn-check" onChange={handleChange} type="checkbox" value={rowdata[datakey]} checked={rowdata[datakey].toLowerCase() === 'y'} />
                            <label key={r++} className="btn btn-outline-primary" htmlFor={datakey} style={{ borderColor: "#0397B1", backgroundColor: rowdata[datakey] === "Y" ? "#0397B1" : "#ffffff", color: rowdata[datakey] === "Y" ? "#ffffff" : "#0397B1" }}> Yes </label>
                            <input key={r++} id={datakey} name={columnObj.columName} className="btn-check" onChange={handleChange} type="checkbox" value={rowdata[datakey]} checked={!rowdata[datakey] || rowdata[datakey].toLowerCase() === 'n'} />
                            <label key={r++} className="btn btn-outline-primary" htmlFor={datakey} style={{ borderColor: "#0397B1", backgroundColor: (!rowdata[datakey] || rowdata[datakey] === "N") ? "#0397B1" : "#ffffff", color: (!rowdata[datakey] || rowdata[datakey] === "N") ? "#ffffff" : "#0397B1" }}> No </label>
                          </fieldset>
                        </div>
                      </div>
                    )
                    break;
                  case "display":
                    currentJSX = (
                      <div key={r++} className="col-lg-6">
                        <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                        <input key={r++} readOnly type="text" id={datakey} name={columnObj.columnName} title="You cannot edit this value" className="form-control" value={rowdata[datakey]} />
                      </div>
                    )
                    break;
                  case "multiline":
                    currentJSX = (
                      <div key={r++} className="col-lg-6">
                        <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                        <textarea key={r++} rows={3} id={datakey} name={columnObj.columnName} className="form-control" onChange={handleChange} value={rowdata[datakey]} />
                      </div>
                    )
                    break;
                  case "checkbox":
                    currentJSX = (
                      <div key={r++} className="col-lg-6">
                        <label key={r++} className="form-check-label" htmlFor={datakey}> {datakey} </label>
                        <input key={r++} id={datakey} name={columnObj.columName} className="form-check mt-2" onChange={handleChange} type="checkbox" value={rowdata[datakey]} checked={rowdata[datakey].toLowerCase() === 'y'} />
                      </div>
                    )
                    break;
                  case "radiobutton":
                    currentJSX = (
                      <div key={r++} className="col-lg-6">
                        <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                        <input key={r++} type="text" id={datakey} name={columnObj.columnName} className="form-control" />
                      </div>
                    )
                    break;
                  case "date":
                    currentJSX = (
                      <div key={r++} className="col-lg-6">
                        <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                        <input key={r++} type="date" id={datakey} name={columnObj.columnName} className="form-control" onChange={handleChange} value={rowdata[datakey]} />
                      </div>
                    )
                    break;
                  case "timestamp":
                    currentJSX = (
                      <div key={r++} className="col-lg-6">
                        <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                        <input key={r++} type="datetime-local" id={datakey} name={columnObj.columnName} className="form-control" onChange={handleChange} value={rowdata[datakey]} />
                      </div>
                    )
                    break;
                  case "phone":
                    currentJSX = (
                      <div key={r++} className="col-lg-6">
                        <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                        <input key={r++} type="tel"
                          id={datakey}
                          name={columnObj.columnName}
                          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" placeholder="123-456-7890"
                          className="form-control" onChange={handleChange} value={rowdata[datakey]} />
                      </div>
                    )
                    break;
                  case "bphone":
                    currentJSX = (
                      <div key={r++} className="col-lg-6">
                        <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                        <input key={r++} type="tel"
                          id={datakey}
                          name={columnObj.columnName}
                          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}( x[0-9]{1,5})?"
                          placeholder="123-456-7890 x12345"
                          className="form-control" onChange={handleChange} value={rowdata[datakey]} />
                      </div>
                    )
                    break;
                  case "email":
                    currentJSX = (
                      <div key={r++} className="col-lg-6">
                        <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                        <input key={r++} type="email"
                          id={datakey}
                          name={columnObj.columnName}
                          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                          title="Please enter a valid email address"
                          className="form-control" onChange={handleChange} value={rowdata[datakey]} />
                      </div>
                    )
                    break;
                  case "selectlist":
                    currentJSX = (
                      <div key={r++} className="col-lg-6">
                        <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                        <select key={r++} id={datakey} name={columnObj.columnName} className="form-select" aria-label="Status" onChange={handleChange} value={rowdata[datakey]} >
                          {
                            columnObj.columnListofValues.map((option: any, idx: any) => (
                              <option key={r++} value={option.optionvalue}>{option.optionlabel}</option>
                            ))
                          }
                        </select>
                      </div>
                    )
                    break;
                  case "Customer Status":
                    currentJSX = (
                      <div key={r++} className="col-lg-6">
                        <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                        <select key={r++} id={datakey} name={columnObj.columnName} className="form-select" aria-label="Status" onChange={handleChange} value={rowdata[datakey]} >
                          {
                            columnObj.columnListofValues.map((option: any, idx: any) => (
                              <option key={r++} value={option.optionvalue}>{option.optionlabel}</option>
                            ))
                          }
                        </select>
                      </div>
                    )
                    break;
                  case "addauto":
                    currentJSX = (
                      <AddressInput inputRef={streetRef}
                        inputId={datakey}
                        inputLabel={datakey}
                        currentvalue={rowdata[datakey]}
                        handleUpdate={handleAddressChange}
                        handleStreetChange={handleStreetChange} />
                    )
                    break;
                  case "addcity":
                    currentJSX = (
                      <div key={r++} className="col-lg-6">
                        <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                        <input key={r++} ref={cityRef} type="text" id={datakey} name={columnObj.columnName} className="form-control" onChange={handleChange} value={rowdata[datakey]} />
                      </div>
                    )
                    break;
                  case "addstate":
                    currentJSX = (
                      <div key={r++} className="col-lg-6">
                        <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                        <input key={r++} ref={stateRef} type="text" id={datakey} name={columnObj.columnName} className="form-control" onChange={handleChange} value={rowdata[datakey]} />
                      </div>
                    )
                    break;
                  case "addzipc":
                    currentJSX = (
                      <div key={r++} className="col-lg-6">
                        <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                        <input key={r++} ref={zipcRef} type="text" id={datakey} name={columnObj.columnName} className="form-control" onChange={handleChange} value={rowdata[datakey]} />
                      </div>
                    )
                    break;
                  case "addcountry":
                    currentJSX = (
                      <div key={r++} className="col-lg-6">
                        <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                        <input key={r++} ref={countryRef} type="text" id={datakey} name={columnObj.columnName} className="form-control" onChange={handleChange} value={rowdata[datakey]} />
                      </div>
                    )
                    break;
                  default:
                    if (columnObj.columnDisplayType.startsWith("lis3")) {
                      console.log("Column ", datakey, " Value is ", rowdata[datakey]);
                      currentJSX = (
                        <div key={r++} className="col-md-4">
                          <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                          <select key={r++} id={datakey} name={columnObj.columnName} className="form-select"
                            aria-label={datakey} onChange={handleChange}
                            value={rowdata[datakey]} >
                            <option key={r++} value=''>Please select...</option>
                            {
                              columnObj.columnListofValues.map((option: any, idx: any) => (
                                <option key={r++} value={option.optionvalue}>{option.optionlabel}</option>
                              ))
                            }
                          </select>
                        </div>
                      )
                    } else if (columnObj.columnDisplayType.startsWith("list")) {
                      console.log("Column ", datakey, " Value is ", rowdata[datakey]);
                      currentJSX = (
                        <div key={r++} className="col-lg-6">
                          <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                          <select key={r++} id={datakey} name={columnObj.columnName} className="form-select"
                            aria-label={datakey} onChange={handleChange}
                            value={rowdata[datakey]} >
                            <option key={r++} value=''>Please select...</option>
                            {
                              columnObj.columnListofValues.map((option: any, idx: any) => (
                                <option key={r++} value={option.optionvalue}>{option.optionlabel}</option>
                              ))
                            }
                          </select>
                        </div>
                      )
                    } else if (columnObj.columnDisplayType.startsWith("llist")) {
                      if ((action !== 'new') && rowdata[datakey]) {
                        currentJSX = (
                          <div key={r++} className="col-lg-6">
                            <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                            <input key={r++} readOnly type="text" id={datakey} name={columnObj.columnName} title="You cannot edit this value" className="form-control" value={rowdata[datakey]} />
                          </div>
                        )
                        break;
                      } else {
                        if (columnObj.columnDisplayType.includes("customer")) {
                          currentJSX = (<div key={r++} className="col-lg-6">
                            <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                            <LargeFilterList inputRef={custInputRef} currApplication={currApplication} fieldname={datakey} listname="customer" initialValue={custInputValue} depRefs={[]} parentBlur={handleInputBlur} />
                            {/*<LListAutocomplete inputRef={custInputRef} currApplication={currApplication} fieldname={datakey} listname="customer" initialValue={custInputValue} depRefs={[]} parentBlur={handleInputBlur} /> */}
                          </div>)
                        } else if (columnObj.columnDisplayType.includes("location")) {
                          currentJSX = (<div key={r++} className="col-lg-6">
                            <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                            <LargeFilterList inputRef={locaInputRef} currApplication={currApplication} fieldname={datakey} listname="location" initialValue={locaInputValue} depRefs={[custInputRef]} parentBlur={handleInputBlur} />
                          </div>)
                        } else if (columnObj.columnDisplayType.includes("custloca")) {
                          currentJSX = (<div key={r++} className="col-lg-6">
                            <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                            <LargeFilterList inputRef={locaInputRef} currApplication={currApplication} fieldname={datakey} listname="custloca" initialValue={locaInputValue} depRefs={[custInputRef]} parentBlur={handleInputBlur} />
                          </div>)
                        } else if (columnObj.columnDisplayType.includes("account")) {
                          currentJSX = (<div key={r++} className="col-lg-6">
                            <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                            <LargeFilterList inputRef={locaInputRef} currApplication={currApplication} fieldname={datakey} listname="account" initialValue={acctInputValue} depRefs={[custInputRef]} parentBlur={handleInputBlur} />
                          </div>)
                        } else {
                          currentJSX = (
                            <div key={r++} className="col-lg-6">
                              <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                              <input key={r++} type="text" id={datakey} name={columnObj.columnName} className="form-control" onChange={handleChange} value={rowdata[datakey]} />
                            </div>
                          )
                        }
                      }
                    } else if (isValidDateOrTimestamp(rowdata[datakey])) {
                      console.log("Value ", rowdata[datakey], " is valid date or timestamp", isValidDateOrTimestamp(rowdata[datakey]));
                      currentJSX = (
                        <div key={r++} className="col-lg-6">
                          <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                          <input key={r++} type="datetime-local" id={datakey} name={columnObj.columnName} className="form-control" onChange={handleChange} value={rowdata[datakey]} />
                        </div>
                      )
                    } else if (isValidDate(rowdata[datakey])) {
                      currentJSX = (
                        <div key={r++} className="col-lg-6">
                          <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                          <input key={r++} type="date" id={datakey} name={columnObj.columnName} className="form-control" onChange={handleChange} value={rowdata[datakey]} />
                        </div>
                      )
                    } else {
                      currentJSX = (
                        <div key={r++} className="col-lg-6">
                          <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                          <input key={r++} type="text" id={datakey} name={columnObj.columnName} className="form-control" onChange={handleChange} value={rowdata[datakey]} />
                        </div>
                      )
                    }
                }
                if (currentJSX !== null) {
                  if (SectionStart) {
                    currentSection.push(currentJSX)
                  } else {
                    return currentJSX;
                  }
                }
              }

              )}
          <div className="row m-2">
            {
              ((rowdata._total_rows == null) || (Number(rowdata._total_rows) <= 1)) &&
              <div className="col-6">
                <span className='d-none'></span>
              </div>
            }
            {
              pagename === "categories" ? null :
                (Number(rowdata._total_rows) > 1) &&
                <div className="col-6 d-flex justify-content-start">
                  <button type="button" style={{ backgroundColor: "#0397B1" }} className="btn btn-success btnPrev" onClick={() => handleCurrentRow(-1, Number(rowdata._total_rows))}><i className="bi bi-box-arrow-left"></i></button>
                  <span className="text-success ms-2 mt-1 fs-6 text-center "> {currentrow} of {rowdata._total_rows} </span>
                  <button type="button" style={{ backgroundColor: "#0397B1" }} className="btn btn-success btnNext ms-2" onClick={() => handleCurrentRow(1, Number(rowdata._total_rows))}><i className="bi bi-box-arrow-right"></i></button>
                </div>
            }

            <div className={pagename === "categories" ? "d-flex justify-content-end" : "col-6 d-flex justify-content-end"}>
              {(contentProps?.pageActions === 'A') && <button type="submit" name="new" style={{ backgroundColor: "#0397B1" }} className="btn btn-success me-2" onClick={(handleButtonAction)}>New</button>}
              {(contentProps?.pageActions === 'A') && <button type="submit" name="copy" style={{ backgroundColor: "#0397B1" }} className="btn btn-success me-2" onClick={(handleButtonAction)}>Copy</button>}
              {((contentProps?.pageActions === 'A') || (contentProps?.pageActions === 'M')) && <button type="submit" name="save" style={{ backgroundColor: "#0397B1" }} className="btn btn-success me-2" onClick={(handleButtonAction)}>Save</button>}
              {(contentProps?.pageActions === 'Q') && <button type="reset" className="btn btn-outline-dark" onClick={handleCancel} >Close</button>}
              {(contentProps?.pageActions !== 'Q') && <button type="reset" className="btn btn-outline-dark" onClick={handleCancel} >Cancel</button>}
            </div>
          </div>
          {saveModal && <AlertModal alerttitle="Success" msgobj={successMessage} display={true} />}
        </form >
      </div>
    )
  } else {
    return (
      <div className="text-center">
        <div className="spinner-grow text-success" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow text-danger" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow text-warning" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow text-info" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  }
}

export default AutoloadDataForm;
