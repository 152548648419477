import React, { useContext, useEffect, useRef, useState } from 'react';
import { Spinner, Tab, Tabs } from 'react-bootstrap';
import { useTheme } from '../Template/ThemeContext';
import AppContext from '../context/AppContext';
import { isEmptyObj } from '../context/MiscFunc';
import { hpost } from '../context/apiService';
import { PageProps } from '../types/menu-item';
import AlertModal from './AlertModal';
import ParameterForm from './ParameterForm';
import SimpleTable from './SimpleTable';


const ReportingForm: React.FC<PageProps> = ({ pageName, currApplication }) => {
  const [tabName, setTabName] = useState<string>('Parameters');
  const [runMode, setRunMode] = useState<string>('');
  const [runningReport, setRunningReport] = useState<boolean>(false);
  const [process, setProcess] = useState<string>(pageName);
  const [resultData, setResultData] = useState<any>({});
  const [submitCounter, setsubmitCounter] = useState<number>(0);
  const [parameterValues, setParameterValues] = useState<any>({});
  const [processData, setProcessData] = useState<any>({});
  const [error, setError] = useState<string>('');
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [resultLoaded, setResultLoaded] = useState<boolean>(false);
  const Theme = useTheme();
  const { sessionData } = useContext(AppContext);
  const [progress, setProgress] = useState(0);
  const isInitialMount = useRef(true);
  let r = 1;

  useEffect(() => {
    console.log("Reporting Form report Name is ", process);
    if (process != pageName) {
      setProcess(pageName);
      setTabName("Parameters")
    }
    let params: any = {
      objecttype: "pageobject",
      pagename: 'processing',
      process_code: process,
      application_id: currApplication?.id,
      biller_id: sessionData.data["Biller Id"],
    }
    fetchData(params, 'processing');
    return () => {
      console.log("Reporting Form process Use Effect return")

    }
  }, [pageName, process])

  useEffect(() => {
    console.log("InitialMount is ", isInitialMount.current);
    if (!isInitialMount.current) {
      let displayResultTab: boolean = false;
      if (!isEmptyObj(parameterValues)) {
        if (parameterValues.hasOwnProperty('REPORTMODE')) {
          setRunMode(parameterValues.REPORTMODE);
          console.log("Reporting Form, Setting Report Mode to ", parameterValues.REPORTMODE);
        }
        parameterValues["objecttype"] = "runreport";
        console.log("Parameter Values are ", parameterValues);
        submitReportData(parameterValues, "runreport");
      } else {
        console.log("Reporting Form Parameter Object is empty: ", parameterValues);
        //  displayError("No parameters submitted");
      }
    } else {
      isInitialMount.current = false;
    }
  }, [parameterValues])

  // useEffect(() => {
  //   if (!isInitialMount.current) {
  //     if ((runMode) && (runMode === 'R')) {
  //       setTabName('third');
  //     } else if ((runMode) && (parameterValues)) {
  //       submitProcess(new Set<string>());
  //     } else {
  //       console.log("Reporting form: This is empty parameter set");
  //     }
  //   }

  // }, [runMode, submitCounter])

  const fetchData = async (params: any, requesttype: string) => {
    let response = await hpost('/sec/data', params);
    if (response.data) {
      let pagetemplate = JSON.parse(JSON.stringify(response.data));
      console.log("Reporting Form Response: ", pagetemplate);
      if (pagetemplate.hasOwnProperty("data")) {
        let processObj = pagetemplate.data.find((proc: any) => proc.process_code === process);
        console.log("Reporting Form Report Object is ", processObj);
        setProcessData(processObj);
      } else {
        console.log("Reporting Form  Not found data key ", pagetemplate);
        setProcessData({});
        displayError('No Data Found');
      }
      setResultLoaded(false);
      setDataLoaded(true);
    } else if (response.error) {
      console.error("Error: ", response.error);
      displayError(response.error);
    };
  }
  const displayError = async (errormessage: string) => {
    setError(errormessage);
    setTimeout(() => {
      setError('')
    }, 5000)
  }
  const submitReportData = async (params: any, requesttype: string) => {
    setRunningReport(true);
    try {
      let response = await hpost('/sec/data', params);
      if (response.data) {
        let pagetemplate = JSON.parse(JSON.stringify(response.data));
        console.log("Reporting Form Response: ", pagetemplate);
        if (pagetemplate.hasOwnProperty("data")) {
          setResultData(pagetemplate);
          setTabName("Results");
        } else {
          console.log("No Data Found ", pagetemplate);
          setResultData({});
          displayError('No Data Found');
        }
        setResultLoaded(true);
        setRunningReport(false);
      } else if (response.error) {
        console.error("Error: ", response.error);
        displayError(response.error);
      };
    } finally {
      setRunningReport(false);
    }
  }



  console.log("Before Report Data is ", processData);
  if (!isEmptyObj(processData)) {
    console.log("Inside Report Data is ", processData);
    return (
      <div >
        <div className="justify-content-start ms-3">
          <h2>{processData.process_name} - {processData.description} </h2>
        </div>
        <hr className="mb-4" /> {/* Horizontal line with a bottom margin */}

        <Tabs activeKey={tabName} onSelect={(k) => setTabName(k as string)}>
          <Tab eventKey="Parameters" title="Parameters">
            <ParameterForm processName={process} currApplication={currApplication} setParameterValues={setParameterValues} />
            {runningReport && 
            <div style={{ cursor: runningReport ? 'wait' : 'auto' }}>
            <div className="position-fixed top-50 start-50 translate-middle">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Running report...</span>
                    </Spinner>
                </div>
             </div>
             }
          </Tab>
          <Tab eventKey="Results" title="Results" disabled={isEmptyObj(resultData)} >
            {isEmptyObj(resultData) ?
              <h1>Waiting for Report Submission and Results</h1>
              : <SimpleTable dataobject={resultData} columnTypes={resultData.pagemeta.columntypes} />
            }
          </Tab>
        </Tabs>
        {(error) &&
          <span>
            <AlertModal alerttitle="Message" msgobj={error} display={true} />
          </span>
        }
      </div>
    );
  } else {
    return (
      <div className="text-center">
        <div className="spinner-grow text-success" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow text-danger" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow text-warning" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow text-info" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  }
}

export default ReportingForm;
