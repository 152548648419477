import { LoadScript } from '@react-google-maps/api';
import React, { useContext, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './App.css';
import MainApplication from './components/MainApplication';
import Welcome from './components/Welcome';
import AppContext from './context/AppContext';
import AppContextProvider from './context/AppContextProvider';

const App: React.FC = () => {
  const { sessionData } = useContext(AppContext);
  const [showSideMenu, setShowSideMenu] = useState(true);
let [authMode, setAuthMode] = useState((sessionData === undefined) || (sessionData == null) ? "signin" : "loggedin");
let [signupStep, setSignupStep] = useState("rstep1");
const [scriptLoaded, setScriptLoaded] = useState(false);
const scriptId = 'google-maps-api-script';

const changeAuthMode = (authmode: string) => {
  setAuthMode(authmode);
}

const handleToggleSideMenu = () => {
  setShowSideMenu(!showSideMenu);
};

useEffect(() => {
  const existingScript = document.getElementById(scriptId);

  if (existingScript) {
    // If the script already exists, set the state to true
    setScriptLoaded(true);
  } else {
    // If the script does not exist, use LoadScript
    setScriptLoaded(false);
  }
}, []);


return (
  <AppContextProvider>
      <DndProvider backend={HTML5Backend}>
        {authMode !== "signin" && <div id={scriptId}></div>}
      {(authMode === "signin") &&
        // <Login currentstep={signupStep} changeAuthMode={changeAuthMode} />
        <Welcome currentstep={signupStep} changeAuthMode={changeAuthMode}/>
      }
      {(authMode != "signin") &&
         <LoadScript
         googleMapsApiKey="AIzaSyAuIJ-FawUSuEhRD1YsLr5JuNKQYB0Rh6k"
         libraries={['places']}>
        <MainApplication />        
        </LoadScript>
      }
      </DndProvider>
    </AppContextProvider>
);
}

export default App;
