const DASHBOARD_SUMMARY_CARDS = [
    {
        cardTitle:"Earnings (Monthly)",
        summaryCardNumber:"$19,390",
        borderColor: "#86DFE1"
    },
    {
        cardTitle:"Earnings (Yearly)",
        summaryCardNumber:"2,169,043",
        borderColor:"#FBD586",
    },
    {
        cardTitle:"Total No. of Task",
        summaryCardNumber:"63",
        borderColor:"#DDB9FF",
    },
    {
        cardTitle:"Total Requests",
        summaryCardNumber:"7",
        borderColor:"#E9A076"
    },
]

export default DASHBOARD_SUMMARY_CARDS