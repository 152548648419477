import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../context/AppContext';
import { isEmptyObj } from '../context/MiscFunc';
import { hpost } from '../context/apiService';
import { BaseContentProps, PageDataProps } from '../types/menu-item';
import DataForm from './DataForm';


const BillerProfile: React.FC<PageDataProps> = ({ pagename, currApplication, dataobject }) => {
  const [contentProps, setContentProps] = useState<BaseContentProps>({});
  const [datafetched, setDataFetched] = useState<boolean>(false);
  const { sessionData, refreshData, setCurrAppLogo } = useContext(AppContext);
  const [formData, setFormData] = useState<any>(dataobject);
  const [pageTab, setPageTab] = useState<string>("billerprofile");

  const handleTabToggle = (tabName: any) => {
    setPageTab(tabName)
    fetchData(tabName)
  }

  const fetchData = async (tabName: any) => {
    const params = {
      objecttype: "pageobject",
      pagename: tabName,
      application_id: currApplication?.id,
      biller_id: sessionData.data["Biller Id"],
    };

    let response = await hpost('/sec/data', params);
    if (response.data) {
      console.log("Biller Response data: ", response.data);
      let pagetemplate = JSON.parse(JSON.stringify(response.data));
      setContentProps({
        dataObj: response.data,
        templateName: (isEmptyObj(pagetemplate.pagemeta) ? 'DataForm' : pagetemplate.pagemeta.templateName),
        pageTitle: pagetemplate.pagemeta.page_title,
        pageActions: pagetemplate.pagemeta.pageactions,
      });
      console.log("Biller ContentProps:", contentProps)
      console.log("Biller data: ", pagetemplate.data[0]);

      setDataFetched(true);
    } else if (response.error) {
      console.error("Error: ", response.error);
      setContentProps({
        currentError: response.error
      });

    };
  }
  useEffect(() => {
    console.log("Reloading Biller Profile Data");
    fetchData("billerprofile")
    setPageTab("billerprofile")
  }, [refreshData])

  useEffect(() => {
    datafetched ? setFormData(contentProps.dataObj) : setFormData(dataobject)
  }, [datafetched, contentProps.dataObj])

  return (
    <div className='container'>

      {/* Biller info and Bank Info Nav */}
      <nav>
        <div className="nav nav-tabs mb-2" id="nav-tab" role="tablist">
          <button style={{ color: pageTab !== 'billerprofile' ? "#000000" : "#0397B1" }} className={`nav-link fs-4 ${pageTab=='billerprofile'?'active':''}`} id="nav-biller-tab" type="button" data-bs-toggle="tab"
            onClick={() => handleTabToggle("billerprofile")}
            data-bs-target="#nav-biller" role="tab" aria-controls="nav-home" aria-selected={`${pageTab=='billerprofile'?'true':'false'}`} >Biller Info</button>
          <button style={{ color: pageTab !== 'bankinfo' ? "#000000" : "#0397B1" }} className={`nav-link fs-4 ${pageTab=='bankinfo'?'active':''}`}
            id="nav-bank-tab" type="button"
            onClick={() => handleTabToggle("bankinfo")} 
            data-bs-toggle="tab" data-bs-target="#nav-bank" role="tab" 
            aria-controls="nav-bank" aria-selected={`${pageTab=='bankinfo'?'true':'false'}`}>Bank Info</button>
        </div>

        {/* Biller Info */}
        <div className="tab-content" id="nav-tabContent">
          {(pageTab === 'billerprofile') &&
            <DataForm pagename="billerprofile" currApplication={currApplication} dataobject={formData} pageactions={contentProps.pageActions} />}
          {/* Bank Info */}
          {(pageTab === 'bankinfo') &&
            <DataForm pagename="bankinfo" currApplication={currApplication} dataobject={formData} pageactions={contentProps.pageActions} />}
        </div>
      </nav>
    </div>
  )
}

export default BillerProfile