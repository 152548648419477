import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../context/AppContext';
import { debounce, getStartingNumber } from '../context/MiscFunc';
import { hpost } from '../context/apiService';

interface FilterProps {
    currApplication: Record<string, any> | null;
    fieldname: string;
    listname: string;
    inputRef: React.RefObject<HTMLInputElement>;
    initialValue: string;
    depRefs: React.RefObject<HTMLInputElement>[];
    nextInputRef?: React.RefObject<HTMLInputElement>;
    parentBlur: (refobj:React.RefObject<HTMLInputElement>) => void;
 }
const LargeFilterList: React.FC<FilterProps> = ({currApplication, fieldname, 
          listname, inputRef, 
          initialValue, depRefs, nextInputRef, parentBlur}) => {
    const [inputValue, setInputValue] = useState<string>(initialValue);
    const [isFocused, setIsFocused] = useState(false);
    const [optionValues, setOptionValues] = useState<string[]>([]);
    const [filter, setFilter] = useState<string>('');
    // const [selectedValue, setSelectedValue] = useState<string | null>(null);
    const { sessionData } = useContext(AppContext);
    const [fieldError, setFieldError]  = useState<string>('');
    
    useEffect(() => {

        let active = true;

        if (inputValue !== initialValue) {
            if (active) {
              setFilter(inputValue);
            }
        }
    
        return () => {
          active = false;
        };
        /*
        console.log("LList Initial Value is ", initialValue)
        if (initialValue) {
             setSelectedValue(initialValue);
             setFilter('');
        } else {
             setSelectedValue(null);
        }
        setFilteredValues([]);
        */
    }, [inputValue, initialValue])
    
    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (fieldError) {
            if (inputRef.current) {
                inputRef.current.title=fieldError
            }
          timer = setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.title='Please fill out this field';
                setFieldError('');
            }
          }, 1000);
        }
        return () => clearTimeout(timer);
      }, [fieldError]);
/*
    useEffect(() => {
        if (filteredValues.length === 1) {
            console.log("Filtered Value Size is now exactly 1", filteredValues);
            handleSelection(filteredValues[0])
        }
    }, [filteredValues]);
    */

    useEffect(() => {
        // Debouncing could be implemented here for better performance
        const fetchData = debounce(async () => {
            try {
                
                let params : any = {
                    application_id: currApplication?.id,
                    biller_id: sessionData.data["Biller Id"],
                    groupname: "WEBAPI",
                    pagename: listname,
                    objecttype: "searchlist",
                    listname: listname,
                    searchcriteria: filter

                };

                for (const ref of depRefs) {
                    if (ref.current) {
                        let current_value = ref.current.value;
                        let ref_name : string = ref.current.name.toLowerCase();
                        if (current_value) {
                            let ref_value_id = getStartingNumber(current_value);
                            if (ref_name.includes("customer")) {
                               params['customer_code'] = ref_value_id;
                            } else if (ref_name.includes("location")) {
                                params['location_code'] = ref_value_id;
                            } else {
                                params[ref_name] = ref_value_id;
                            }
                        }
                    } 
                 }
                const response = await hpost('/sec/data', params);
                if (response.data) {
                    console.log("filtered Response: ", response.data);
                    let payloadString = JSON.parse(JSON.stringify(response.data)).data;
                    console.log("PayLoad is :", payloadString);
                    let valuesfound : string[] = payloadString.map((valueitem : any) => valueitem.option_value)
                    setOptionValues(valuesfound);
                } else if (response.error) {
                    console.error("appsettings Error: ", response.error);
                    setOptionValues([]);
                };
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }, 500);

         // Function to check if all dependent inputs have non-blank values
         // and get the name of the first blank input
      const checkInputs = () : string => {
       for (const ref of depRefs) {
          if (ref.current && ref.current.value.trim() === '') {
             console.log("The first blank input is: ", ref.current.name);
             if (inputRef.current) {
                setFilter('');
                setFieldError("First, please enter value for "+ ref.current.name);
             }
             ref.current.focus();
             return ref.current.name;
          } else {
            console.log("LList The non blank : ",ref.current?.name, "value is ", ref.current?.value);
          }
       }
       return '';
    }
          let checkDepInput = checkInputs();
          if (checkDepInput === '') {
            console.log('LList All inputs have non-blank values');
            setFieldError('');
          } else {
            let errormessage = 'LList Please first select value for '+checkDepInput;
            setFieldError(errormessage);
          }

        if (filter) {
            const timerId = setTimeout(() => {
                fetchData();
            }, 10); // Debounce by 10ms for testing only

            return () => clearTimeout(timerId); // Cleanup on unmount or filter change
        } 

    }, [filter]); // Effect runs on mount and whenever 'filter' changes
/*
    useEffect(() => {
        if ((inputRef.current) && (selectedValue)) {
           inputRef.current.value=selectedValue;
           setFilteredValues([]);
           setFilter('');
           console.log("After Setting Filtered Values to empty");
        }
    }, [selectedValue]);
*/

const handleBlur = () => {
    setIsFocused(false);
    if (!(inputValue) && (optionValues.length >= 1)) {
        console.log("LList No Selected Value Yet");
        setInputValue(optionValues[0]);
    }
    parentBlur(inputRef);
    console.log("LList Blur Input Value is ", inputValue);
 };


  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, id } = e.target;
    console.log("LList Selected Value: ", inputValue, " current value: ", value);
    setInputValue(value);
    setFilter(value);
  }
  /*
    const handleSelection = (newvalue: string) => {
        setFilteredValues([]);
        setFilter('');
        setSelectedValue(newvalue);
        parentBlur(newvalue, inputRef);
        // Here you would call your data load function with the selected item
        console.log('LList Selected Value:', newvalue);
    };
    */
    return (
        <div className="form-control">
            <div className="position-relative">
            <input
                type="text"
                placeholder={inputValue? '':'Search...'}
                value={inputValue}
                name={fieldname}
                ref={inputRef} 
                className="w-100"
                required 
                //onChange={(e) => { e.preventDefault(); if (selectedValue) { setSelectedValue(null) }; setFilter(e.target.value)} }
                onChange={handleValueChange}
                onFocus={() => setIsFocused(true)}
                onBlur={handleBlur}
            />
            {/* !filter && (
              <BIcon iconName="Search" size={16} className="position-absolute" style={{ top: '50%', left: '10px', transform: 'translateY(-50%)' }} />
            ) */}
              {/*fieldError && <span className="error-message">{fieldError}</span> */}
            </div>
            {  isFocused &&
               inputValue != initialValue &&
                optionValues.length > 0 && (
                    <>
                        <ul className="list-group" style={{ position: 'absolute', width: '50%', zIndex: 1000 }}>
                            {optionValues.map((NewValue) => (
                                <>
                                    {console.log("New Value Object: ", NewValue)}
                                    <li key={NewValue} className="list-group-item list-group-item-action text-start"
                                        onMouseDown={() => {setInputValue(NewValue); parentBlur(inputRef);} }>{NewValue}</li>
                                </>
                            ))}
                        </ul>
                        {inputValue && <div>Selected: {inputValue}</div>}
                    </>
                )
            }
        </div>
    );
};

export default LargeFilterList;
