import { isEmptyObj } from "./MiscFunc";

interface ApiResponse<T> {
    templateName? : string;
    data?: T;
    error?: string;
  }

interface BlobResponse {
    blob: Blob;
    filename: string;
  }

  function setError(errormessage: string) {
    return { error: errormessage};
  }
  async function handleResponse<T>(response: Response): Promise<ApiResponse<T>> {
    const contentType = response.headers.get('content-type');
    try {
      console.log("Content Types: ", contentType);
    if (contentType && contentType.includes('application/json')) {
      const data = await response.json();
      if (response.ok) {
        if (isEmptyObj(data) || data.hasOwnProperty("_notfound")) {
          console.log("Setting error to No Data Found");
         // throw new Error('Data Not Found'); /* We need to handle this at the response level to display empty form */
          return {data}
        } else {
          if (data.hasOwnProperty("_success")) {
            console.log("api service success:", data)
            return {data};
          } else if (data.hasOwnProperty("_error")) {
            console.log("API Error " + data);
            return {data};
          } else if (data.hasOwnProperty("error")) {
            console.log("API Error " + data.error);
            throw new Error("Data Fetch Error: " + data.error);
          } else if (data.hasOwnProperty("pagemeta") || data.hasOwnProperty("columntypes")
                     || data.hasOwnProperty("data")) {
            console.log("api service:", data)
            return {data}
          } else if (data.hasOwnProperty("result")) {
            if (data.result.includes("Success") || data.result.includes("success")) {
              console.log("api service:", data)
              return {data};
            } else if (data.result === "Error") {
              throw new Error (data.message);
            } else {
              throw new Error("Invalid Result: "+data.result+" Message "+data.message);  
            }
          } else {
            throw new Error("Empty Data Set");
          }
        }
      } else {
        if (response.status === 401) {
          throw new Error("Unauthorized Access.");
        } else if (response.status === 403) {
          throw new Error("Secure Resource. Please login to access.");
        } else {
          throw new Error(`Request failed with status code ${response.status}`);
        }
      } 
        
    } else {
      throw new Error('Invalid response format');
    }
  } catch (error)  {
    console.error(error);
    return setError("Response: "+error);
  }
  }
  
  export async function hget<T>(url: string): Promise<ApiResponse<T>> {
    try {
      const response = await fetch(process.env.REACT_APP_API_URI +url)
      .catch(error => {
        console.error(error.message);
        throw new Error(error.message);
      });
      return handleResponse<T>(response);
    } catch (error) {
      return setError("HGET: "+error);
    }
  }
  
  export async function hgeturl<T>(url: string): Promise<ApiResponse<T>> {
    try {
      const response = await fetch(url)
      .catch(error => {
        console.error(error.message);
        throw new Error(error.message);
      });
      return handleResponse<T>(response);
    } catch (error) {
      return setError("HGET URL: "+error);
    }
  }
  export async function hpost<T>(url: string, body: any): Promise<ApiResponse<T>> {
    console.log("API SERVICE: ", body)
    try {
      const response = await fetch(process.env.REACT_APP_API_URI +url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-API-KEY': 'Baeldung'
        },
        body: JSON.stringify(body),
      });
      return handleResponse<T>(response);
    } catch (error) {
      return setError("HPOST: "+error);
    }
  }

  export async function hpostform<T>(url: string, body: any) : Promise<ApiResponse<T>> {
    try {
      const response = await fetch(process.env.REACT_APP_API_URI +url, {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-API-KEY': 'Baeldung'
        },
        body: body,
      });
      return handleResponse<T>(response);
    } catch (error) {
      return setError("HPOST: "+error);
    }

  }

  export async function hposturl<T>(url: string, body: any) : Promise<ApiResponse<T>> {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: body,
      });
      return handleResponse<T>(response);
    } catch (error) {
      return setError("HPOST: "+error);
     }
    }


    export async function hfetchdoc (url: string, body: any): Promise<BlobResponse>  {
      console.log("Before sending Parameters are ", JSON.stringify(body));
      const response = await fetch(process.env.REACT_APP_API_URI +url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      console.log("After Post");
      if (!response.ok) {
        throw new Error('Failed to fetch document');
      }
      const contentDisposition = response.headers.get('content-disposition');
      const filename = contentDisposition ? contentDisposition.split('filename=')[1] : 'document.pdf';
      console.log("Before waiting for blob");
      const blob = await response.blob();
    
      return { blob, filename };
    }
    
  
  
  // Add other HTTP methods as needed (e.g., put, patch, delete, etc.)
  