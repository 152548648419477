import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import React, { useContext, useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import { Column, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import * as XLSX from 'xlsx';
import { useTheme } from '../Template/ThemeContext';
import AppContext from '../context/AppContext';
import { getTableColumns } from '../context/MiscFunc';
import { hpost } from '../context/apiService';
import { TableDataProps, TableRow } from '../types/menu-item';
import DataForm from './DataForm';
import SegFormLayout from './SegFormLayout';

const DynamicTable: React.FC<TableDataProps> = ({ pagename, currApplication, dataobject, columnTypes, pageactions, tableheadercount }) => {
  const [tableData, setTableData] = useState<TableRow[] | undefined>();
  const [columns, setColumns] = useState<Column<TableRow>[]>([]);
  const [currentPage, setCurrentPage] = useState<string>(pagename);
  const Theme = useTheme();
  const { refreshData, setRefreshData, listItemSelected, setListItemSelected, setSearchFilter } = useContext(AppContext);
  const [selectedRow, setSelectedRow] = useState<TableRow | null>(null);
  const [hoveredRowIndex, setHoveredRowIndex] = React.useState<number | null>(null);
  const [searchMode, setSearchMode] = useState<string>("basic");
  const [bulkSelectedRows, setBulkSelectedRows] = useState<Set<string>>(new Set());
  const [progress, setProgress] = useState(0);
  /* Table Pagination */
  const [pageCount, setPageCount] = useState(8);
  const [pageSize, setPageSize] = useState(20);
  const sumCell: any = {};

  // const [isModalOpen, setIsModalOpen] = useState(false);

  const tableStyles: React.CSSProperties & Record<string, string> = {
    'background-color': Theme.backgroundColor,
    '--text-color': Theme.primaryColor,
    '--container-width': Theme.containerWidth,
    '--border-style': Theme.containerBorderStyle,
    '--border-width': Theme.containerBorderWidth,
    '--border-color': Theme.containerBorderColor,
  };

  /*
  console.log("Data Object in useffect is ", dataobject.data as TableRow[]);
  console.log("Column Types in useffect is ", Object.keys(dataobject.pagemeta.columntypes).length);
  console.log("Header count: ", dataobject.pagemeta.tableheaderscount);
*/
  useEffect(() => {
    //  console.log("Data Object in useffect is ", dataobject);
    console.log("Table Header Count is ", tableheadercount, currentPage);
    setSelectedRow(null);

    if (listItemSelected)
      setListItemSelected(false)
  }, [currentPage])

  useEffect(() => {
    setCurrentPage(pagename);
    setSelectedRow(null);
    if (dataobject.hasOwnProperty("data")) {
      setTableData(dataobject.data as TableRow[]);
      setColumns(getTableColumns(columnTypes));
    }
    console.log("saud: table data is:", getTableColumns(columnTypes));
  }, [pagename, dataobject, columnTypes, refreshData])

  // Load more data when reaching the bottom of the scroll
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    const bottom = target.scrollHeight - target.scrollTop === target.clientHeight;
    if (bottom && canNextPage) {
      nextPage();
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
    state: { pageIndex, globalFilter },
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable<TableRow>(
    {
      columns,
      data: (tableData === undefined ? [] : tableData),
      manualPagination: true, // Enable manual pagination
      initialState: { pageIndex: 0, pageSize: 25 }, // Initial page index and size
      pageCount: Math.ceil(tableData?.length ?? 1 / pageSize),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );


  const handlePageSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSize = Number(e.target.value);
    if (newSize > 0) {
      setPageSize(newSize);
    } else {
      setPageSize(25);
    }
  };

  useEffect(() => {
    let containerName: string = (currentPage + "_table");
    const tableContainer = document.getElementById('tableContainer');
    if (tableContainer) {
      // Add event listener using EventListener type
      const listener: EventListener = handleScroll as unknown as EventListener;
      tableContainer.addEventListener('scroll', listener);

      // Cleanup function to remove event listener
      return () => {
        tableContainer.removeEventListener('scroll', listener);
      };
    }
  }, [canNextPage]);

  const handleRowClick = (row: TableRow) => {
    console.log("handle Row Click");
    if (searchMode !== 'advance') {
      setSelectedRow(row);
      setListItemSelected(true);
      console.log(" Selected Row " + JSON.stringify(row));
    }
  };

  // const handleModalClose = () => {
  //   setListItemSelected(false);
  // };

  const handleAdd = () => {
    // Implement add logic
    setSelectedRow(null);
    setListItemSelected(true);
  };

  const handleUpdate = async (updatedRow: TableRow) => {
    // Implement update logic
    console.log('Update row:', updatedRow);
    // Make API call to update data
    const params = {
      objecttype: "pageobject",
      pagename: currentPage,
      customer: 0, biller: 0,
      detail: currApplication?.id,
      application_id: currApplication?.id,
      changeload: updatedRow
    };

    let response = await hpost('/updatedata', params);
    if (response.error) {
      console.error(response.error);
    } else {
      console.log('Data updated successfully!', response.data);
      // Perform any additional actions after successful update
      const updatedData = tableData?.map((row) => (row['Page ID'] === updatedRow['Page ID'] ?
        { ...row, ...updatedRow } : row));
      //initData=JSON.parse(JSON.stringify(updatedData));
      console.log("Updated Row is " + JSON.stringify(updatedData));
      //tableData=JSON.parse(JSON.stringify(updatedData));
      setTableData(updatedData);
    }
  };

  const handleDelete = (deletedKey: TableRow) => {
    // Implement delete logic
    console.log('Delete row:', deletedKey);
  };

  const handleSearchClick = (event: any) => {
    event.preventDefault()
    //console.log("EVENT: ", event.target.id, 'Search Modes is ', searchMode);
    setSearchMode(searchMode === 'basic' ? 'advance' : 'basic');
  }

  const handleMouseEnter = (rowIndex: number) => {
    setHoveredRowIndex(rowIndex);
  };
  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };


  // useEffect(() => {
  //   //alert('Page Index '+pageIndex +' Total Size '+ Math.ceil(tableData?.length??1/pageSize)); 
  //   // Fetch data when pageIndex or data changes
  // }, [pageIndex, tableData]);

  const performSearch = (event: any) => {
    event.preventDefault()
    // Create a FormData object from the form element
    const formData = new FormData(event.target);
    // Convert the FormData object to a plain object
    const formObject = Object.fromEntries(formData.entries());
    // Convert the plain object to a JSON string
    const jsonString = JSON.stringify(formObject);

    // Log the JSON string to the console (or do something else with it)
    //console.log(jsonString);
    setBulkSelectedRows(new Set());
    setSearchFilter(jsonString);
  }
  const resetSearchClick = (event: any) => {
    event.preventDefault()
    //console.log("EVENT: ", event.target.id, 'Search Modes is ', searchMode);
    setSearchFilter('');
    setBulkSelectedRows(new Set());
    setSearchMode(searchMode === 'basic' ? 'advance' : 'basic');
  }

  const handleBulkRowSelection = (event: any, currentRow: TableRow) => {
    // event.preventDefault();
    let currentRowId: string = currentRow.rowid + '';
    console.log("Current ID: ", currentRowId);
    setBulkSelectedRows((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(currentRowId)) {
        //console.log("newly selected rows Removing Current ID: ", currentRowId);
        newSelected.delete(currentRowId);
      } else {
        //console.log("newly selected rows Adding Current ID: ", currentRowId);
        newSelected.add(currentRowId);
      }
      return newSelected;
    });

  };

  const handleSelectAll = () => {
    if (bulkSelectedRows.size === rows.length) {
      setBulkSelectedRows(new Set());
    } else {
      const newSelecteds = new Set(rows.map((row) => row.original.rowid + ''));
      //console.log("newly Selected Rows ", newSelecteds);
      setBulkSelectedRows(newSelecteds);
    }
  };

  const isAllSelected = bulkSelectedRows.size === rows.length;
  const isRowSelected = (rowid: string) => bulkSelectedRows.has(rowid)

  // Function to convert table data to Excel
  const exportToExcel = (data: any[], fileName: string) => {
    // Extract column order from headerGroups
    const columnOrder = headerGroups[0].headers.map(header => header.id);

    // console.log("saud table data: ", Object.keys(data[0]));

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([], { header: Object.keys(data[0]) });

    // Simulate processing large data with setTimeout and update progress
    let chunkSize = 1000;
    let currentIndex = 0;

    const processChunk = () => {
      const chunk = data.slice(currentIndex, currentIndex + chunkSize);
      XLSX.utils.sheet_add_json(ws, chunk, { origin: -1, skipHeader: true });
      currentIndex += chunkSize;
      setProgress(Math.min((currentIndex / data.length) * 100, 100));

      if (currentIndex < data.length) {
        setTimeout(processChunk, 0);
      } else {
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, `${fileName}.xlsx`);
        setProgress(0); // Reset progress after completion
      }
    };

    processChunk();
  };


  const exportToPDF = (data: any[], fileName: string, displayColumnHeaders: any[]) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 20;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(10);

    // let columnHeaders = Object.keys(data[0]).filter(function (key) {
    //   return !(key.includes("_") && key.);
    // }
    // );

    let displayData = data.map(row => Object.fromEntries(displayColumnHeaders.map(column => [column, row[column]])));


    // console.log("saud pdf keys", columnHeaders);
    // console.log("saud pdf values", displayData);

    doc.text(fileName, marginLeft, 20);
    autoTable(doc, {
      head: [displayColumnHeaders],
      body: displayData.map(row => Object.values(row))

    }
    );

    doc.save(`${fileName}.pdf`);



  }

  const getTableDataAsJson = () => {
    return rows.map((row) => {
      prepareRow(row);
      return row.original;
    });
  };
  const handleExport = () => {
    const xlsData = getTableDataAsJson();
    // console.log("saud json:", getTableDataAsJson());
    let headerFullList: any[] = [];

    Object.keys(dataobject.pagemeta.columntypes).filter(keylabel => ((keylabel !== 'rownumber_meta') &&
      (!keylabel.endsWith("_hidden")) && (!keylabel.startsWith('row(')))).forEach(element => {
        headerFullList.push([element, dataobject.pagemeta.columntypes[element].columnIndex]);
      });



    function compareFn(a: any[], b: any[]) {
      if (a[1] < b[1]) {
        return -1;
      } else if (a[1] > b[1]) {
        return 1;
      }
      // a must be equal to b
      return 0;
    }

    headerFullList.sort(compareFn);
    //headerFullList = headerFullList.slice(0, dataobject.pagemeta.tableheaderscount)

    let displayHeaderList: any[] = [];
    headerFullList.forEach(header => displayHeaderList.push(header[0]))

    let filteredData: any[] = [];
    xlsData.forEach(row => {
      let filteredRow: any = {};
      displayHeaderList.forEach(key => {
        filteredRow[key] = row[key];
      })
      filteredData.push(filteredRow);
    });

    console.log("Saud EXCEL: ", filteredData);
    exportToExcel(filteredData, 'table_data');
  };

  const handleExportPDF = () => {
    const xlsData = getTableDataAsJson();
    // console.log("saud json pdf:", getTableDataAsJson());
    let PDFHeaderFullList: any[] = [];
    Object.keys(dataobject.pagemeta.columntypes).filter(keylabel => ((keylabel !== 'rownumber_meta') &&
      (!keylabel.endsWith("_hidden")) && (!keylabel.startsWith('row(')))).forEach(element => {
        PDFHeaderFullList.push([element, dataobject.pagemeta.columntypes[element].columnIndex]);
      });


    function compareFn(a: any[], b: any[]) {
      if (a[1] < b[1]) {
        return -1;
      } else if (a[1] > b[1]) {
        return 1;
      }
      // a must be equal to b
      return 0;
    }

    PDFHeaderFullList.sort(compareFn);

    let displayHeaders: any[] = PDFHeaderFullList.slice(0, dataobject.pagemeta.tableheaderscount);
    let displayNames: string[] = [];
    displayHeaders.forEach(header => displayNames.push(header[0]));

    // console.log("saud PDF headers:", displayNames);
    exportToPDF(xlsData, 'table_data', displayNames);
  };

  const hasFooter = columns.some(column => column.Footer);

  if (listItemSelected && Object.keys(dataobject.pagemeta.columntypes).length > dataobject.pagemeta.tableheaderscount) {
    return (
      (selectedRow &&
        <div>
          {
            /*<DataForm pagename={pagename} currApplication={currApplication} dataobject={dataobject} initrow={selectedRow === null ? '1' : selectedRow.rownumber_meta + ''} pageactions={pageactions} /> */
          }
          {/*<SingleRowDataForm pageName={pagename} currApplication={currApplication} rowid_value={selectedRow.rowid} /> */}
          <SegFormLayout pagename={currentPage} currApplication={currApplication} dataobject={dataobject} initrow={selectedRow === null ? '1' : selectedRow.rownumber_meta + ''} pageactions={pageactions} />
        </div>
      ))
  } else if (Object.keys(columnTypes).length <= 0) {
    return <div>No Data Found Yet</div>
  }
  else {
    let r = 1;
    //console.log("Column Types are ", columnTypes);
    return (

      <div >
        {Object.keys(dataobject.pagemeta.columntypes).length <= dataobject.pagemeta.tableheaderscount && <div>
          <DataForm pagename={currentPage} currApplication={currApplication} dataobject={dataobject} initrow={selectedRow === null ? '1' : selectedRow.rownumber_meta + ''} />
        </div>}


        {(searchMode === 'advance') &&
          <div className='d-flex'>
            <form id="tablesearchform" name="tablesearchform" className="row g-3" onSubmit={performSearch} onReset={resetSearchClick} >
              {
                columns.slice(0, tableheadercount ?? 5).map((cell, cellIndex) => {
                  //console.log("Cell Value is ", cell);
                  let datakey: string = cell.accessor + '' ?? 'none';
                  if (datakey === 'none') {
                    setSearchMode('basic');
                    return (<div></div>);
                  } else {
                    //console.log("Looking for Column Object: ", datakey, " In page ", pagename);
                    let columnObj = columnTypes[datakey];
                    
                    if (columnObj.columnDisplayType.startsWith("list")) {
                      return (<div key={r++} className="d-flex col-lg-6">
                        <div className="flex-grow-1">
                          <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                          <select key={r++} id={datakey} name={columnObj.columnOriginalLabel}
                            className="form-select">
                            <option key={r++} value=''>Please select...</option>
                            {
                              columnObj.columnListofValues.map((option: any, idx: any) => (
                                <option key={r++} value={option.optionvalue}>{option.optionlabel}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                      )
                    } else {
                      return (
                        <div className="d-flex col-lg-6">
                          {
                            <div className="flex-grow-1">
                              <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                              <input key={r++} type="text" id={datakey} name={columnObj.columnOriginalLabel}
                                className="form-control" />
                            </div>
                          }
                        </div>
                     )
                    }
                  }
                })
              }
              <div className="d-flex w-100">
                <button type="reset" className="ms-auto p-2 mb-2" style={{ backgroundColor: '#0397B1', marginLeft: "10px" }}>Close</button>
                <button type="submit" className="p-2 mb-2" style={{ backgroundColor: '#0397B1', marginLeft: "10px" }}>Search</button>
              </div>
            </form>
          </div>
        }

        <div className='d-flex'>
          <p className="fs-5">Search:</p>
          <div className="ms-2 flex-grow-1">
            <div className="d-flex">
              <input
                value={globalFilter || ''}
                onChange={e => setGlobalFilter(e.target.value)}
                className="form-control w-50"
              />
              {(columns.length > (tableheadercount ?? 4)) && <div> <i className="bi bi-binoculars mx-2 h5" id="searchIcon" onClick={handleSearchClick}></i></div>}
              {/*(searchMode === 'advance') && <div className="ms-auto"><h4>Selected Rows: {bulkSelectedRows.size}</h4></div> */}
              <div className="ms-auto"><h4>Total Rows: {rows.length}</h4></div>
              {(rows.length === 0) && (pageactions === 'A') && <button onClick={handleAdd}>Add</button>}
              {/*(searchMode === 'advance') && <div><button onClick={handleExportPDF}>PDF</button><button onClick={handleExport}>XLSX</button></div> */}
              {progress > 0 && (
                <ProgressBar now={progress} label={`${progress}%`} animated striped />
              )}
            </div>
          </div>
        </div>


        {/* <button style={{ backgroundColor: '#0397B1', marginLeft:"10px"}} onClick={() => {setIsModalOpen(true); setSelectedRow(null);}}>Edit Mode</button> */}

        <div style={{
          overflowX: 'auto', maxHeight: '600px', minWidth: '100%',
          borderTop: '1px solid #ddd',
          borderBottom: '1px solid #ddd',
          borderLeft: '1px solid #ddd',
          borderRight: '1px solid #ddd',
        }}>
          <table id={currentPage + "_table"} {...getTableProps()} className="w-100">
            <thead style={{ position: 'sticky', top: '-1px', zIndex: 1, backgroundColor: Theme.topbarBackgroundColor }}>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {/*(searchMode === 'advance') &&
                    <th>
                      <input
                        type="checkbox"
                        checked={isAllSelected}
                        onChange={handleSelectAll}
                      />
                    </th> */}
                  {headerGroup.headers.slice(0, tableheadercount ?? 5).map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, rowIndex) => {
                prepareRow(row);
                const isRowHovered = rowIndex === hoveredRowIndex;
                const isItemSelected = isRowSelected(row.original.rowid + '');

                const rowStyles: React.CSSProperties = {
                  backgroundColor: isRowHovered ? Theme.topbarBackgroundColor : Theme.backgroundColor, // Apply background color on hover
                  color: isRowHovered ? Theme.topbarColor : rowIndex % 2 === 0 ? Theme.navLinkColor : Theme.navLinkColor,
                  cursor: 'pointer'
                };
                return (
                  <tr
                    {...row.getRowProps()}
                    onMouseEnter={() => handleMouseEnter(rowIndex)}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => handleRowClick(row.original)}
                    style={rowStyles}>
                    {/*searchMode === 'advance' && (tableData?.length ?? 0 > 0) && <td>
                      <input
                        type="checkbox"
                        checked={isItemSelected}
                        onChange={(event: any) => handleBulkRowSelection(event, row.original)}
                      />
                    </td>*/}
                    {row.cells.slice(0, tableheadercount ?? 5).map((cell, cellIndex) => {
                      const colkey = cell.column.id;
                      const columnkey = Object.keys(columnTypes).find((collabel: any) => columnTypes[collabel].columnIndex === colkey);
                      const cellcol = columnTypes[columnkey ?? 'none']
                      {/* if ((cellcol.columnDisplayType === 'amountsum') ||
                          (cellcol.columnDisplayType === 'numsum')) {
                            if (columnkey) {
                              sumCell[columnkey] = (sumCell[columnkey] || 0) + Number(cell.value);
                            }
                      }
                    */}
                      //  console.log("Cell Accessor is ", colkey, cellcol);
                      return (
                        <td
                          {...cell.getCellProps()} >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}

            </tbody>
            {hasFooter && <tfoot>
              {footerGroups.map(group => (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map(column => (
                    <td {...column.getFooterProps()} style={{ textAlign: 'right', paddingRight: '10px', fontWeight: 'bold', borderTop: '2px solid black' }}>
                      {column.Footer ? column.render('Footer') : null}
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
            }
          </table>


          {/*isModalOpen && selectedRow && 
        (
        <FormModal
          isOpen={isModalOpen}
          initialWidth={800}
          initialHeight={Math.max(500, window.innerHeight-30)}
          onClose={handleModalClose}
          pagename={pagename}
          rowData={selectedRow}
          columnTypes={columnTypes}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
      )*/
          }
        </div>
        {(rows.length > 200) &&
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button className="btn btn-light mx-1" onClick={() => previousPage()} disabled={!canPreviousPage}>
              <ChevronLeft />
            </button>
            <span style={{ margin: '0 10px' }}>
              Page {pageIndex + 1} of {Math.ceil(tableData?.length ?? 1 / pageSize)}
            </span>
            <button className="btn btn-light mx-1" onClick={() => nextPage()} disabled={!canNextPage}>
              <ChevronRight />
            </button>
          </div>}
      </div>
    );
  }
};


export default DynamicTable;

