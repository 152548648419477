import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { useTheme } from '../Template/ThemeContext';
import AppContext from '../context/AppContext';
import { isEmptyObj } from '../context/MiscFunc';
import { hpost } from '../context/apiService';
import { PageProps } from '../types/menu-item';
import AlertModal from './AlertModal';
import ParameterForm from './ParameterForm';
import SearchForm from './SearchForm';


const ProcessingForm: React.FC<PageProps> = ({ pageName, currApplication }) => {
  const [tabName, setTabName] = useState<string>('Parameters');
  const [runMode, setRunMode] = useState<string>('');
  const [selectMode, setSelectMode] = useState<string>('');
  const [process, setProcess] = useState<string>(pageName);
  const [submitCounter, setsubmitCounter] = useState<number>(0);
  const [parameterValues, setParameterValues] = useState<any>({});
  const [processData, setProcessData] = useState<any>({});
  const [error, setError] = useState<string>('');
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const Theme = useTheme();
  const { sessionData } = useContext(AppContext);
  const [progress, setProgress] = useState(0);
  const isInitialMount = useRef(true);
  let r = 1;

  useEffect(() => {
    console.log("Reporting Form report Name is ", process);
    if (process != pageName) {
      setProcess(pageName);
    }
    let params: any = {
      objecttype: "pageobject",
      pagename: 'processing',
      process_code: process,
      application_id: currApplication?.id,
      biller_id: sessionData.data["Biller Id"],
    }
    fetchData(params, 'processing');
    return () => {
      console.log("Reporting Form process Use Effect return")

    }
  }, [pageName, process])

  useEffect(() => {
    console.log("InitialMount is ", isInitialMount.current);
    if (!isInitialMount.current) {
      let displaySelectionTab: boolean = false;
      if (!isEmptyObj(parameterValues)) {
        if (parameterValues.hasOwnProperty("SELECTMODE")) {
          setSelectMode(parameterValues.SELECTMODE);

          console.log("Processing Form, Setting Select Mode to ", parameterValues.SELECTMODE);
        }
        if (parameterValues.hasOwnProperty('RUNMODE')) {
          setRunMode(parameterValues.RUNMODE);
          console.log("Processing Form, Setting Run Mode to ", parameterValues.RUNMODE);
        }
        setsubmitCounter(prevCounter => prevCounter + 1);
      } else {
        console.log("Processing Form Parameter Object is empty: ", parameterValues);
      }
    } else {
      isInitialMount.current = false;
    }
  }, [parameterValues])

  useEffect(() => {
    if (!isInitialMount.current) {
      console.log("Processing Form Selection Mode ", selectMode, " runMode ", runMode);
      if ((selectMode) && (selectMode !== 'ALL')) {
        setTabName("Selection");
      } else if ((runMode) && (runMode === 'R')) {
        setTabName('third');
      } else if ((runMode) && (parameterValues)) {
        submitProcess(new Set<string>());
      } else {
        console.log("Processing form: This is empty parameter set");
      }
    }

  }, [selectMode, runMode, submitCounter])

  const fetchData = async (params: any, requesttype: string) => {
    let response = await hpost('/sec/data', params);
    if (response.data) {
      let pagetemplate = JSON.parse(JSON.stringify(response.data));
      console.log("Response: ", response.data);
      if (pagetemplate.hasOwnProperty("data")) {
        let processObj = pagetemplate.data.find((proc: any) => proc.process_code === process);
        setProcessData(processObj);
      } else {
        setProcessData({});
        displayError('No Data Found');
      }
      setDataLoaded(true);
    } else if (response.error) {
      console.error("Error: ", response.error);
      displayError(response.error);
    };
  }
  const displayError = async (errormessage: string) => {
    setError(errormessage);
    setTimeout(() => {
      setError('')
    }, 5000)
  }
  const submitProcessData = async (params: any, requesttype: string) => {
    let response = await hpost('/sec/submitprocess', params);
    if (response.data) {
      let pagetemplate = JSON.parse(JSON.stringify(response.data));
      console.log("Processing Response: ", response.data);
      if (pagetemplate.hasOwnProperty("_success")) {
        displayError(pagetemplate["_success"]);
        setTabName('Parameters')
      } else if (pagetemplate.hasOwnProperty("_error")) {
        displayError("Job not submitted: " + pagetemplate["_error"]);
      } else {
        displayError(JSON.stringify(response.data));
      }
    } else if (response.error) {
      console.error("Error: ", response.error);
      displayError(response.error);
    };
  }


  const submitProcess = (selectedRows: Set<string>) => {
    let stringArray = [];
    alert("Calling job submission");
    if ((selectMode !== 'ALL') && (selectedRows.size === 0)) {
      displayError("Need to select at least 1 row for the selection mode. Otherwise, please change it to All rows");
      return;
    } else {
      stringArray = Array.from(selectedRows);
    }
    if (isEmptyObj(parameterValues)) {
      displayError("Parameter values not submitted");
      return;
    }

    parameterValues["selected_ids"] = stringArray;
    parameterValues["objecttype"] = "submitprocess";
    console.log("Parameter Values are ", parameterValues);
    submitProcessData(parameterValues, "submitprocess");

  };
  if (error) {
    return (
      <span>
        <AlertModal alerttitle="Message" msgobj={error} display={true} />
      </span>
    );
  }
  if (!isEmptyObj(processData)) {
    console.log("Process Data is ", processData);

    return (
      <div >
        <div className="justify-content-start ms-3">
          <h2>{processData.process_name} - {processData.description} </h2>
        </div>
        <hr className="mb-4" /> {/* Horizontal line with a bottom margin */}

        <Tabs activeKey={tabName} onSelect={(k) => setTabName(k as string)}>
          <Tab eventKey="Parameters" title="Parameters">
            <ParameterForm processName={process} currApplication={currApplication} setParameterValues={setParameterValues} />
          </Tab>
          <Tab eventKey="Selection" title="Selection Tab" disabled={(selectMode ?? 'ALL' === 'ALL') ? false : true} >
            <SearchForm processName={process} currApplication={currApplication} parentProcessHandle={submitProcess} />
          </Tab>
          {runMode === 'R' && (
            <Tab eventKey="third" title="Third Tab">
              <div className="p-3">
                <p>Final Review before submitting job: </p>
                <div className="d-flex">
                  <Button type="button" variant="primary" className="ms-auto" onClick={() => { submitProcess(new Set()) }} >Submit Job</Button>
                </div>
              </div>
            </Tab>
          )}
        </Tabs>
      </div>
    );
  } else {
    return (
      <div className="text-center">
        <div className="spinner-grow text-success" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow text-danger" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow text-warning" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow text-info" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  }
}

export default ProcessingForm;
