import { saveAs } from 'file-saver';
import React, { useEffect, useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import { useTheme } from '../Template/ThemeContext';
import { TableRow } from '../types/menu-item';
import AlertModal from './AlertModal';

interface LoaderPage {
    loadoptions: any;
}

const BulkLoader: React.FC<LoaderPage> = ({ loadoptions }) => {
    const [loadTables, setLoadTables] = useState<TableRow[]>([])
    const [file, setFile] = useState<File | null>(null);
    const [alertMessage, setAlertMessage] = useState<string>();
    const [table, setTable] = useState<string>('');
    const [path, setPath] = useState<string>('');
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [data, setData] = useState<TableRow[]>([]);
    const [progress, setProgress] = useState<number>(0);
    const [message, setMessage] = useState<string>('');
    const fileInputRef = useRef<HTMLInputElement>(null);
    const Theme = useTheme();

    let r = 0;

    useEffect(() => {
        if (loadoptions.hasOwnProperty("data")) {
            setLoadTables(loadoptions.data);
        }
    })

    const displayAlertMessage = async (newmessage: string) => {
        setAlertMessage(newmessage);
        setMessage(newmessage);
        setTimeout(() => {
            setAlertMessage('')
        }, 5000)
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const selectedFile = event.target.files[0];
            setFile(selectedFile);
            handleFileRead(selectedFile);
        }
    };

    const handleTableChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setTable(event.target.value);
        setPath(event.target.selectedOptions[0].value);
        console.log("saud event: ", event);
    };

    const handleFileRead = (file: File) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            if (event.target) {
                const fileData = new Uint8Array(event.target.result as ArrayBuffer);
                const workbook = XLSX.read(fileData, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: false }) as (string | number | null)[][];
                const headers = jsonData[0] as string[];
                const rows = jsonData.slice(1);
                const parsedData = rows.map((row) => {
                    const rowData: TableRow = {};
                    headers.forEach((header, index) => {
                        rowData[header] = row[index] ?? '';
                    });
                    return rowData;
                });
                setData(parsedData);
            }
        };
        reader.readAsArrayBuffer(file);
    };



    const handleFileUpload = async () => {
        if (!data.length || !table || !file || !file.name || data.length === 0) {
            displayAlertMessage('Please select load type from the list, then view file data before uploading');
            return;
        }

        // setIsProcessing(true);
        // setMessage('');

        // fetch(`/api/${table}/upload`, {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(data)
        // })
        //     .then(response => response.json())
        //     .then(result => {
        //         setIsProcessing(false);
        //         displayAlertMessage(`Successfully processed ${result.processedRecords} records.`);
        //         setProgress(100);
        //         resetProcessingState(); // Reset after 5 seconds
        //     })
        //     .catch(error => {
        //         setIsProcessing(false);
        //         displayAlertMessage(`Error processing file: ${error.message}`);
        //     });

        // // Simulate progress update for demonstration purposes
        // const totalRecords = data.length;
        // let processedRecords = 0;
        // const interval = setInterval(() => {
        //     if (processedRecords < totalRecords) {
        //         processedRecords += Math.floor(totalRecords / 10); // Increment progress
        //         setProgress((processedRecords / totalRecords) * 100);
        //     } else {
        //         clearInterval(interval);
        //     }
        // }, 100);
        const request = {
            "filename": file.name,
            "rows": data.length,
            "filedata": data
        }

        console.log("saud request:", request);
        console.log("saud path:", process.env.REACT_APP_API_URI, path);

        fetch(process.env.REACT_APP_API_URI + path, {
            method: "POST", headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        }
        ).then(response => response.json())
            .then(result => {
                setIsProcessing(false);
                displayAlertMessage(`Successfully processed ${result?.inserted} records.`);
                setProgress(100);
                resetProcessingState(); // Reset after 5 seconds
            })
            .catch(error => {
                setIsProcessing(false);
                displayAlertMessage(`Error processing file: ${error.message}`);
            });



    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (!isProcessing && event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            const droppedFile = event.dataTransfer.files[0];
            setFile(droppedFile);
            handleFileRead(droppedFile);
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleClick = () => {
        if (!isProcessing) {
            fileInputRef.current?.click();
        }
    };

    const handleViewData = () => {
        if (file) {
            handleFileRead(file);
        }
    };

    const resetProcessingState = () => {
        setTimeout(() => {
            setIsProcessing(false);
            setProgress(0);
        }, 5000); // Reset isProcessing and progress after 5 seconds
    };

    const handleDownload = () => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, table);
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, table + '-sample.xlsx');
    };

    // Remove rownumber_meta column from displaying in the table
    const filteredData = data.map(({ rownumber_meta, rowid,
         user_id, status_reason, biller_id, program_id, 
         customer_id, location_id,
         cust_address_end, cust_address_start, search_count, custaddress_end, custaddress_start, ...rest }) => rest);
    if (alertMessage) {
        return (
            <span>
                <AlertModal alerttitle="Message" msgobj={alertMessage} display={true} />
            </span>
        );
    }
    return (
        <div className="d-flex flex-column me-4 w-80">
            <div className="justify-content-start ms-3">
                <h2 className="primary-color mb-4">Bulk Data Loader</h2>
                <p className="mb-2"></p>
                <select id="base-table" name="base-table" className="form-select w-50" onChange={handleTableChange} disabled={isProcessing}>
                    <option key={r++} >Select Load Type</option>
                    {
                        loadTables.map((optionitem: any) => {
                            return (<option key={r++} value={optionitem.upload_path_hidden}>{optionitem.description}</option>)
                        })
                    }
                </select>
            </div>
            <hr className="mb-4" /> {/* Horizontal line with a bottom margin */}
            <div
                className="drop-area d-flex flex-column align-items-center justify-content-center p-4 m-4"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onClick={handleClick}
                style={{
                    border: '2px dashed #ccc',
                    borderRadius: '4px',
                    cursor: isProcessing ? 'not-allowed' : 'pointer'
                }}
            >
                Drag and drop a file here or click to select a file
                <input
                    type="file"
                    accept=".csv, .xlsx"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    disabled={isProcessing}
                />
            </div>
            {file && (

                <div className="d-flex justify-content-center">
                    <h2>File: {file.name}</h2>
                    <h2 className="ms-4"> Total Rows: {data.length}</h2>
                    {/* <button className="btn btn-primary m-4" onClick={handleViewData} disabled={isProcessing}>
                        View Data
                    </button> */}
                </div>
            )}
            {filteredData.length > 0 && (

                <div className="d-flex flex-column justify-content-center">
                    <div style={{
                        overflowX: 'auto', maxWidth: '1400px',
                        maxHeight: '350px',
                        borderTop: '1px solid #ddd',
                        borderBottom: '1px solid #ddd',
                        borderLeft: '1px solid #ddd',
                        borderRight: '1px solid #ddd',
                        margin: '20px',
                    }}>

                        <table id={"load_table"} className="w-100">
                            <thead style={{ position: 'sticky', top: '-1px', zIndex: 1, backgroundColor: Theme.topbarBackgroundColor }}>

                                <tr>
                                    {Object.keys(filteredData[0]).map((key) => (
                                        <th key={key}>{key}</th>
                                    ))}
                                </tr>
                            </thead>

                            <tbody>

                                {filteredData.map((row, index) => (
                                    <tr key={index}>
                                        {Object.values(row).map((value, i) => (
                                            <td key={i}>{String(value)}</td>
                                        ))}
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>
                    <div className="mb-4 d-flex">
                        <div className="ms-auto me-4">
                            {(table) && <button className="btn btn-secondary me-4" onClick={handleDownload}>
                                Download Sample
                            </button>}
                            {filteredData.length > 0 &&
                                <button className="btn btn-success" onClick={handleFileUpload} disabled={isProcessing}>
                                    Upload and Process
                                </button>}
                        </div>
                    </div>
                    {isProcessing && <div><div>Processing...</div>
                        <progress value={progress} max="100"></progress>

                    </div>}
                    {message && <div><strong>Message:</strong> {message}</div>}
                    </div>)}
                    {/*

                <div className="d-flex justify-content-center">
                    <div style={{ overflowX: 'auto', overflowY: 'auto', maxWidth: '1300px', maxHeight: '400px', margin: '20px' }}>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    {Object.keys(filteredData[0]).map((key) => (
                                        <th key={key}>{key}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.map((row, index) => (
                                    <tr key={index}>
                                        {Object.values(row).map((value, i) => (
                                            <td key={i}>{String(value)}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
                */}

              
                </div>
            );
};
            export default BulkLoader;
