import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../context/AppContext';
import { PageDisplay } from '../types/menu-item';
import AppFooter from './AppFooter';
import AppNavbar from './AppNavbar';
import ContentPane from './ContentPane';
import SideMenu from './SideMenu';

const initPageDisplay = {
    topbardisplay: true,
    menudisplay: true,
    contentdisplay: true,
    footerdisplay: true,
    menubranddisplay : true
}
const MainApplication: React.FC = () => {
    const { sessionData, applications, themeMode, setThemeMode, fetchThemeData, currApplication, setCurrentApplication, currAppLogo, setCurrAppLogo, currMenuId, setCurrMenuId, searchFilter, setSearchFilter } = useContext(AppContext);
    const [pageSettings, setPageSettings] = useState<PageDisplay>(initPageDisplay);
    const [selectedNavItem, setSelectedNavItem] = useState<string>('Dashboard');


    console.log('Main App Session Data is ' + JSON.stringify(sessionData) + " Application " + currApplication?.name);

    useEffect(() => {
        // Fetch data using the API service
        console.log("Current Application is set to UseEffect " + JSON.stringify(currApplication));
        if (currApplication !== null) {
            console.log("Current Application Fetching Theme Data for ", currApplication)
            fetchThemeData(themeMode);          
        } else {
            console.log("Current Application is null for Theme Data in UseEffect");
        }
    }, [currApplication, themeMode]);

    const handleSwitchDisplay = (switchtype: keyof PageDisplay) => {
        console.log("Switching Display for ", switchtype, pageSettings[switchtype], ' to ', !pageSettings[switchtype]);
       /* setPageSettings((prevSet) => ({ ...prevSet, [switchtype]: !pageSettings[switchtype] })); */
    };

    const renderAppComponent = () => {
        return (
            <div className="d-flex flex-column" style={{ minHeight: '90vh' }}>
                <div className="row mr-5">
                    {!pageSettings.topbardisplay && <button className="col-3" onClick={() => handleSwitchDisplay("topbardisplay")}><i className="bi bi-toggle-off" style={{ fontSize: '24px' }}></i> Topbar</button>}
                    {!pageSettings.menudisplay && <button className="col-3" onClick={() => handleSwitchDisplay("menudisplay")}><i className="bi bi-toggle-off" style={{ fontSize: '24px' }}></i> Menu</button>}
                    {!pageSettings.menubranddisplay && <button className="col-3" onClick={() => handleSwitchDisplay("menubranddisplay")}><i className="bi bi-toggle-off" style={{ fontSize: '24px' }}></i> Brand</button>}
                    {!pageSettings.footerdisplay && <button className="col-3" onClick={() => handleSwitchDisplay("footerdisplay")}><i className="bi bi-toggle-off" style={{ fontSize: '24px' }}></i> Footer</button>}
                </div>
                {pageSettings.topbardisplay && <AppNavbar toggleState={pageSettings.topbardisplay} toggleHandle={handleSwitchDisplay} selectedNavItem={selectedNavItem} setSelectedNavItem={setSelectedNavItem} /> }
                <div className="d-flex">
                    {pageSettings.menudisplay && <SideMenu toggleState={pageSettings.menudisplay} toggleHandle={handleSwitchDisplay} selectedNavItem={selectedNavItem} setSelectedNavItem={setSelectedNavItem} />}
                    <div className="flex-grow-1">
                        {pageSettings.contentdisplay &&
                            (currApplication) && <ContentPane currApplication={currApplication} pageName={selectedNavItem}  />}
                    </div>
                </div>
                {pageSettings.footerdisplay && <AppFooter toggleState={pageSettings.footerdisplay} toggleHandle={handleSwitchDisplay} selectedNavItem={selectedNavItem} setSelectedNavItem={setSelectedNavItem} />}
            </div>
        )
    }
    return (
        <>
            {
                renderAppComponent()
            }
            </>
    );
}

export default MainApplication;