import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  DoughnutController,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
  // import "chartjs-plugin-doughnutlabel";  
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    BarElement,    
    DoughnutController
  );
  
interface Donutprops{
    donutData:any
}  

const DoughnutComponent:React.FC<Donutprops> = ({donutData})=>{  
  const backgroundColorList : any = [
    { backgroundColor:'#FFACE5'},
    { backgroundColor:"#A9C9FF"},
    { backgroundColor:'#86DFE1'}, 
    { backgroundColor:'#FBD586'}];
    
  const data :any = {
      labels: donutData.map((item:any)=>item.item_label),
      datasets: [{        
        data:  donutData.map((item:any)=>item.item_value),
        backgroundColor: backgroundColorList.map((item:any)=>item.backgroundColor),
        hoverOffset: 4
      }],
}

 const options:any ={
        responsive: true,
        maintainAspectRatio: false,
        cutout: '70%',
        borderRadius: '8',        
        plugins:{
          doughnutlabel: {
            display:true,
            labels: [
                {
                    text: 'open',
                    font: {
                        size: '18',
                        family: 'Roboto',
                        style: 'normal',
                        weight: '500',
                        horizontalCenter: "middle",
                        verticalCenter: "middle"

                    },
                    sidePadding: 15,
                    color: '#FFACE5'
                },
            ]
        },            
        legend: { 
            align:'start', 
            // position:"left",                            
            labels: {
                usePointStyle: true,
                pointStyle: 'circle',                              
                boxHeight: 8,          
                boxWidth:8,                                             
            },
            },
} }

return(
    <Doughnut options={options} data={data}/>
)
}
export default DoughnutComponent