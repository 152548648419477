import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';

interface MessageModalProps {
  show: boolean;
  onHide: () => void;
  message: string;
  type: 'error' | 'warning' | 'info';
  autoClose: boolean;
  duration: number;
}

const MessageModal: React.FC<MessageModalProps> = ({ show, onHide, message, type, autoClose, duration }) => {
  useEffect(() => {
    console.log("Message Modal Current Message is ", message, " Type is ", type);
    if (show && autoClose) {
      const timer = setTimeout(onHide, duration * 1000);
      return () => clearTimeout(timer);
    }
  }, [show, autoClose, duration, onHide]);

  const getTypeClass = () => {
    switch (type) {
      case 'error':
        return 'text-danger';
      case 'warning':
        return 'text-warning';
      case 'info':
        return 'text-info';
      default:
        return '';
    }
  };

  const getTypeIcon = () => {
    switch (type) {
      case 'error':
        return '❌';
      case 'warning':
        return '⚠️';
      case 'info':
        return 'ℹ️';
      default:
        return '';
    }
  };

  return (
    <Modal show={show} onHide={onHide} container={document.getElementById('appalerts')}>
      <Modal.Header closeButton>
        <Modal.Title>
          {getTypeIcon()} Message
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className={getTypeClass()}>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MessageModal;
