import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

interface DataProps {
  alerttitle : string;
  msgobj: string;
  display : boolean;
}
const AlertModal: React.FC<DataProps> = ({alerttitle, msgobj, display}) => {
  const [showModal, setShowModal] = useState(true);
  console.log(msgobj+" "+alerttitle);
 
  useEffect(() => {
    if (display) {
      setShowModal(true);
      const timer = setTimeout(() => {
        setShowModal(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [display]);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      {/* {(showModal) && <Button onClick={openModal}>Show {alerttitle}</Button>} */}

      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{alerttitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{msgobj}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AlertModal;
