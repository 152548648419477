import { createContext } from 'react';
import { Message } from '../types/menu-item';

interface AppContextProps {
  // Define your context properties and functions here
  sessionData: any | undefined;
  appVersion: string;
  themeMode: string;
  setThemeMode: (thememode : string) => void;
  fetchThemeData: (thememode : string ) => void;
  setSessionData: (data: any) => void;
  applications : Record<string, string>[] | null;
  setApplications: (data: Record<string, string>[] |null) => void;
  currApplication: Record<string, string>|null;
  setCurrentApplication: (data: Record<string, string>|null) => void;
  currAppLogo : string;
  setCurrAppLogo : (data: string) => void;
  currMenuId : number;
  setCurrMenuId : (menu_id: number) => void;
  refreshData : boolean;
  setRefreshData : (data: any) => void;
  listItemSelected : boolean;
  setListItemSelected : (data:any) =>void;
  isSignedUp : boolean;
  setIsSignedUp: (data:any) =>void;
  searchFilter : any;
  setSearchFilter : (data : any) => void;
  lastMessage: Message | null;
  setLastMessage: (message: Message) => void;
  displayMessage: (message: Message, duration?: number) => void;
 
}

const AppContext = createContext<AppContextProps>({
  sessionData: undefined,
  appVersion: '',
  themeMode: '',
  setThemeMode: () => {},
  fetchThemeData: () => {},
  setSessionData:  () => {},
  applications: null,
  setApplications: () => {},
  currApplication: null,
  setCurrentApplication: () => {},
  currAppLogo :  'default',
  setCurrAppLogo : () => {},
  currMenuId : 0,
  setCurrMenuId: () => {},
  refreshData : false,
  setRefreshData : () => {}, 
  listItemSelected : false,
  setListItemSelected : ()=>{},
  isSignedUp : false,
  setIsSignedUp: () =>{},
  searchFilter : {},
  setSearchFilter : () => {},
  lastMessage:  null,
  setLastMessage: (message: Message) => {},
  displayMessage: (message: Message, duration?: number) => {}
  }
);

export default AppContext;
