import React, { useContext, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { ThemeProps, useTheme } from '../Template/ThemeContext';
import MessageModal from '../components/MessageModal';
import { Message } from '../types/menu-item';
import AppContext from './AppContext';
import { getColorHex } from './MiscFunc';
import { hpost } from './apiService';

type MyThemeProperties = keyof ThemeProps;

const AppContextProvider: React.FC<any> = ({ children }) => {
  // Initialize your context values here
  const [sessionData, setSessionData] = useState<any>(undefined);
  const appVersion = '1.0.0';
  const [applications, setApplications]  = useState<Record<string, string>[] | null>(null);
  const [currApplication, setCurrentApplication]  = useState<Record<string, string>| null>(null);
  const [currAppLogo, setCurrAppLogo] = useState<string>('default');
  const [currMenuId, setCurrMenuId] = useState<number>(0);
  const [refreshData, setRefreshData] =useState<any>()
  const [listItemSelected, setListItemSelected] = useState<any>()
  const [isSignedUp, setIsSignedUp] = useState <any>()
  const [searchFilter, setSearchFilter] = useState<any>()
  const [themeMode, setThemeMode] = useState<string>('light')
  const [lastMessage, setLastMessage] = useState<Message | null>(null);
  const Theme = useTheme(); 

  const displayMessage = (message: Message, duration: number = 5) => {
    const div = document.getElementById('appalerts');

    const hideModal = () => {
      ReactDOM.unmountComponentAtNode(div!);
    };

    ReactDOM.render(
      <MessageModal
        show={true}
        onHide={hideModal}
        message={message.content}
        type={message.type}
        autoClose={true}
        duration={duration}
      />,
      div
    );
    setLastMessage(message);
  };

  const fetchThemeData = async (thememode : string) => {
    const params = {
        objecttype: "appsettings",
        application_id: currApplication?.id,
        pagename: "x",
        thememode: thememode
    };
    //console.log("Before Theme Fetch ", "appsettings", ' for application ', currApplication);
    let response = await hpost('/sec/data', params);
    if (response.data) {
        console.log("appsettings Response: ", response.data);
        if (response.data.hasOwnProperty("_notfound")) {
          Theme.setInitTheme(themeMode);
          return;
        }
        let appsettings = JSON.parse(JSON.stringify(response.data));
        let settingvalues :  Map<string, any> = new Map<string, any>();
        Object.entries(appsettings.data).forEach(([key, value]) => {
            console.log('Theme Data', appsettings.data[key].NAME, appsettings.data[key].setting_value)
            let indstr = appsettings.data[key].NAME;
            let set_value = appsettings.data[key].setting_value;
            if (indstr.endsWith('color')) {
               settingvalues.set(indstr, getColorHex(set_value));
            } else {
                settingvalues.set(indstr, set_value);
            }
        })
        console.log("Theme DB Settings ", settingvalues);
        if (appsettings.hasOwnProperty("appmenuid")) {
            setCurrMenuId(appsettings.appmenuid);
        }
        if (appsettings.hasOwnProperty("applogo")) {
            console.log(" App has logo", appsettings.applogo);
             setCurrAppLogo(appsettings.applogo);
        } else {
            console.log(" App has No logo - default");
            setCurrAppLogo('default');
        }
        for (const key of Object.keys(Theme) as MyThemeProperties[]) {
              let keystr = key.toLowerCase();
              if (settingvalues.has(keystr)) {
                Theme.setKeyProperty(key, settingvalues.get(keystr));
                console.log("Theme Property Found ", key, keystr, settingvalues.get(keystr));
              }
        }
        /*
        Object.entries(appsettings.data).forEach(([key, value]) => {
            console.log('Theme Data', appsettings.data[key].NAME, appsettings.data[key].setting_value)
        })
        */
    } else if (response.error) {
        console.error("appsettings Error: ", response.error);
        if (response.error.hasOwnProperty("_notfound")) {
          console.log("No Settings Found");
        }
        setCurrAppLogo('default');
    };
  }
  // Memorize the context value to prevent re-initialization on each render
  const appContextValue = useMemo(
    () => ({
      sessionData,
      appVersion,
      themeMode,
      setThemeMode,
      fetchThemeData,
      setSessionData,
      applications,
      setApplications,
      currApplication,
      setCurrentApplication,
      currAppLogo,
      setCurrAppLogo,
      currMenuId,
      setCurrMenuId,
      refreshData,
      setRefreshData,
      listItemSelected,
      setListItemSelected,
      isSignedUp,
      setIsSignedUp,
      searchFilter,
      setSearchFilter,
      lastMessage,
      setLastMessage,
      displayMessage
      
    }),
    [sessionData, appVersion, themeMode,
       applications, currApplication, 
       currAppLogo, currMenuId, refreshData, 
       listItemSelected, isSignedUp, searchFilter, lastMessage, setLastMessage, displayMessage]
  );

  return (
    <AppContext.Provider value={appContextValue}>
      {children}
    </AppContext.Provider>
  );
};
export const useAppContext = (): any => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};
export default AppContextProvider;
