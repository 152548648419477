import React, { useContext, useEffect, useState } from 'react';
import { Navbar } from 'react-bootstrap';
import { useTheme } from '../Template/ThemeContext';
import AppContext from '../context/AppContext';
import logo from '../img/TrekFLEX_S.png';
import { toggleHandle } from '../types/menu-item';
import NavbarIconModal from './NavbarIconModal';

const AppNavbar: React.FC<toggleHandle> = ({ toggleState, toggleHandle }) => {
  const [isIconModalOpen, setIconModal] = useState(false)
  const [iconName, setIconName] = useState();
  const { sessionData, applications, themeMode, setThemeMode, currApplication, setCurrentApplication } = useContext(AppContext);
  const theme = useTheme();

  useEffect(() => {
    // Fetch data using the API service
    const setApplication = () => {
      console.log("In NavBar ", applications, currApplication);
      if (applications != null) {
        if (currApplication === null) {
          let app = applications[0];
          setCurrentApplication({ id: app.id, name: app.name });         
        }
      }
    }
    setApplication();
  }, [applications]);

  const handleIconClick= (event: any)=>{
    event.preventDefault()
    console.log("EVENT: ", event.target.id)
    setIconName(event.target.id)
    setIconModal(true)
  }

  const onClose = () => {
    setIconModal(false)
  }

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value, id, options, selectedIndex } = e.target;
    // alert("Handling Application Change");
    console.log(e.target.name + " Name " + name + " Value " + value + " text is " + options[selectedIndex].text);
    setCurrentApplication({ id: value, name: options[selectedIndex].text });
    //setSelectedNavItem('Settings');

  }
  if (currApplication === null) {
    return (
      <div className="text-center">
        <div className="spinner-grow text-success" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow text-danger" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow text-warning" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow text-info" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  } else {
    return (
      <Navbar className="topbar" variant="dark">
        <Navbar.Brand href="#" className="mr-auto">
          <img
            src={logo} // Replace with your profile picture URL
            alt="Logo"
            height="50"
            className="d-inline-block align-top ms-2"
          />{' '}
        </Navbar.Brand>
             
        <Navbar.Collapse id="navbar-nav" className="justify-content-between">                   
        
          <p className="fs-3 fw-bold ms-3" style={{color:theme.topbarHeadingColor}}>{sessionData.data["Biller Id"]}-{sessionData.data["Biller Name"]}</p>

          <div className='d-flex'>
            {/* Search bar */}
            <div className="input-group rounded" style={{backgroundColor:theme.searchbarBackgroundColor}}>
               <span className="input-group-append">
                    <button className="btn btn-outline-secondary border-end-0 border-bottom-0 border" style={{backgroundColor:theme.searchbarBackgroundColor}} type="button">
                        <i className="bi bi-search"></i>
                    </button>
                </span>
                <input className="form-control border-0 ms-n8 rounded" style={{backgroundColor:theme.searchbarBackgroundColor}} type="search" placeholder="Search" id="search-input"/>   
            </div>
 
            {/* Navbar Options */}           
            <i className="bi bi-moon-fill mx-2 h4" id="modeIcon" 
             onClick={() => (themeMode === 'Dark' ? setThemeMode('Light') : setThemeMode('Dark'))} />
            <i className="bi bi-bell-fill mx-2 h4"id="bellIcon" onClick={(event)=>handleIconClick(event)}></i>
            <i className="bi bi-envelope-fill mx-2 h4" id="envelopeIcon" onClick={(event)=>handleIconClick(event)}></i>
            <i className="bi bi-person-fill mx-2 h4" style={{borderRadius:"50%"}}></i>
          </div>
                
          {isIconModalOpen && <NavbarIconModal onClose={onClose} isIconModalOpen={isIconModalOpen} iconName={iconName}/>}
        </Navbar.Collapse>
      </Navbar>
    );
  }
};

export default AppNavbar;
