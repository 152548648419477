import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import { Column } from 'react-table';
import { useTheme } from '../Template/ThemeContext';
import AppContext from '../context/AppContext';
import { getTableColumns } from '../context/MiscFunc';
import { hpost } from '../context/apiService';
import { TableRow } from '../types/menu-item';


interface Parameter {
  number: string;
  name: string;
  type: string;
  value: string;
  required_flag: string;
  options?: string[];
}
interface ParamProps {
  processName: string;
  currApplication: Record<string, string> | null;
  setParameterValues: (parameters: any) => void;
}
const initialParameters: TableRow[] = [];

const ParameterForm: React.FC<ParamProps> = ({ processName, currApplication, setParameterValues }) => {
  const [process, setProcess] = useState<string>('');
  const [parameters, setParameters] = useState<Array<TableRow>>(initialParameters);
  const [columns, setColumns] = useState<Column<TableRow>[]>([]);
  const [columntypes, setColumnTypes] = useState<any>({});
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const Theme = useTheme();
  const { sessionData } = useContext(AppContext);
  const [progress, setProgress] = useState(0);
  let r = 1;

  // const [isModalOpen, setIsModalOpen] = useState(false);

  const tableStyles: React.CSSProperties & Record<string, string> = {
    'background-color': Theme.backgroundColor,
    '--text-color': Theme.primaryColor,
    '--container-width': Theme.containerWidth,
    '--border-style': Theme.containerBorderStyle,
    '--border-width': Theme.containerBorderWidth,
    '--border-color': Theme.containerBorderColor,
  };
  
  useEffect(() => {
    setProcess(processName);
  }, [processName]);
  
  useEffect(() => {
    console.log("Process Changed to ", processName);
    let params: any = {
      objecttype: "pageobject",
      pagename: 'processparams',
      application_id: currApplication?.id,
      biller_id: sessionData.data["Biller Id"],
      process_code: process
    }
    if (process) {
      fetchData(params);
    }
  }, [process])


  const fetchData = async (params: any) => {
    let response = await hpost('/sec/data', params);
    if (response.data) {
      let pagetemplate = JSON.parse(JSON.stringify(response.data));
      console.log("Response: ", response.data);
      if (pagetemplate.hasOwnProperty("data")) {
        setParameters(pagetemplate.data as TableRow[]);
      } else {
        setParameters([]);
      }
      if (pagetemplate.hasOwnProperty("pagemeta")) {
        setColumnTypes(pagetemplate.pagemeta.columntypes);
        setColumns(getTableColumns(pagetemplate.pagemeta.columntypes));
      }
      setDataLoaded(true);
    } else if (response.error) {
      console.error("Error: ", response.error);
      setParameters([]);
    };
  }

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    let newvalue = e.target.value;
  };


  const handleParameterChange = (index: number, field: string, value: string) => {
    console.log("Handling parameter change for ", field, "new value", value);
    const newParameters = parameters.map((param, i) => {
      // console.log("index ", index, ' Parameter Index ', param.parameter_index, 'Current Value', param.current_value);
      return (param.parameter_index === index ? { ...param, current_value: value } : param)
    });
    console.log("New Parameter Object is ", newParameters);
    setParameters(newParameters);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    //alert("Handling Form  Again");
    const formData = new FormData(event.target);
    if (validateParameterForm()) {
      // Append form data from state
      formData.append('biller_id', sessionData.data["Biller Id"]);
      const currentAppId = currApplication?.id ?? '0';
      formData.append('application_id', currentAppId);
      formData.append('pagename', processName);
      formData.append('objecttype', "pageobject");

      // Convert the FormData object to a plain object
      const formObject = Object.fromEntries(formData.entries());

      // Convert the plain object to a JSON string
      const jsonString = JSON.stringify(formObject);
      console.log(jsonString);
      setParameterValues(formObject);

      // Manually trigger form reset and update resetTrigger
      //(event.target as HTMLFormElement).reset();
      //handleReset(event);
    } else {
      console.error("Processing Form Data is not Valid");
    }

  }

  const validateParameterForm = (): boolean => {

    let valid_flag: boolean = true;
    //verify required parameter values are populated
    const newParameters = parameters.map((param, i) => {
      // console.log("index ", index, ' Parameter Index ', param.parameter_index, 'Current Value', param.current_value);
      let newparam = { ...param };
      if (param.required_flag === 'Y') {
        if (!(param.current_value.toString().trim())) {
          newparam['param_message'] = 'This is a required parameter';
          valid_flag = false;
        }
      }
      return (newparam)
    });
    if (!valid_flag) {
      console.log("New Parameter Object is ", newParameters);
      setParameters(newParameters);
    }
    return valid_flag;
  };

  const handleReset = (e: React.FormEvent) => {
    console.log("Resetting Process State")
    setParameterValues({});
  };
  
  let column: any = columntypes.parameter_type;
  
  console.log("Parameter column is ", column);
  console.log("Set Parameters are ", parameters);

  const renderParameterValue = (paramobj: any): JSX.Element => {
    console.log("parameter is ", paramobj);
    if (!paramobj) {
      return (<></>);
    }
    if (paramobj.param_lov !== 'N/A' && (paramobj.param_lov_exclude??'N/A') !== 'N/A') {
      let paramoptions = paramobj.param_lov_exclude;
      console.log("Parameter options ", paramoptions, typeof paramoptions);
      return (
        <Form.Control
          as="select"
          key={r++}
          value={paramobj.current_value}
          required={paramobj.required_flag === 'Y'}
          name={paramobj.parameter_name}
          title={paramobj.param_message}
          className={paramobj.param_message ? 'is-invalid' : ''}
          onChange={(e) => handleParameterChange(paramobj.parameter_index, e.target.name, e.target.value)}
        >
          <option key='0' value=''>
            Select...
          </option>
          {
            paramoptions.map((lov: any, ind: any) => (
              <option key={lov.optionvalue} value={lov.optionvalue}>
                {lov.optionlabel}
              </option>

            ))
          }
        </Form.Control>
      )
    } else {
      let valuetype = 'text';
      switch (paramobj.parameter_type) {
        case 'd': valuetype = 'date'; break;
        case 'dnt': valuetype = 'datetime-local'; break;
        case 'tel': valuetype = 'tel'; break;
        case 'num': valuetype = 'text'; break;
        case 'amt': valuetype = 'amount'; break;
        default: valuetype = paramobj.parameter_type ?? 'text';
      }
      return (<Form.Control
        type={valuetype}
        key={r++}
        value={paramobj.current_value}
        name={paramobj.parameter_name}
        required={paramobj.required_flag === 'Y'}
        title={paramobj.param_message}
        className={paramobj.param_message ? 'is-invalid' : ''}
        onChange={(e) => handleParameterChange(paramobj.parameter_index, e.target.name, e.target.value)}
      />)
    }

  }

  return (
    <Form className="row g-3 me-3" id={processName + '_parameters'} onSubmit={handleSubmit} onReset={handleReset}>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Number</th>
            <th>Name</th>
            <th>Type</th>
            <th>Value</th>
            <th>Required/Optional</th>
          </tr>
        </thead>
        <tbody>
          {parameters.map((param, index) => (
            <tr key={index}>
              <td>
                <Form.Control
                  type="text"
                  value={param.parameter_index}
                  readOnly
                  title="Parameter Number"
                /* onChange={(e) => handleParameterChange(index, 'number', e.target.value)}
                required={param.required === 'Required'} */
                />
              </td>
              <td>
              { <Form.Control
                type="text"
                value={param.parameter_label}
                readOnly
                title=" Parameter Name"
              /> }
              </td>
              <td>
                
              <Form.Control
                as="select"
                value={param.parameter_type}
                readOnly >
                {column && column.columnListofValues && column.columnListofValues.map((optionobj: any) => (
                  <option key={optionobj.optionvalue} value={optionobj.optionvalue}>
                    {optionobj.optionlabel}
                  </option>
                ))}
              </Form.Control>
              </td>
              <td>
                {renderParameterValue(param)}
              </td>
              <td>
                
                <Form.Control
                  as="select"
                  value={param.required_flag==='Y'?'R':'O'}
                  readOnly
                >
                  <option value="R">Required</option>
                  <option value="O">Optional</option>
                </Form.Control>
               
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="d-flex p-3">
        <Button type="submit" variant="primary" className="ms-auto">Submit Parameters</Button>
      </div>

    </Form>
  );
}

export default ParameterForm;
