import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import React, { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { Column, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import * as XLSX from 'xlsx';
import { useTheme } from '../Template/ThemeContext';
import { getTableColumns, isEmptyObj } from '../context/MiscFunc';
import { TableRow } from '../types/menu-item';
interface SimpleTableData {
  dataobject: any,
  columnTypes: any
}

const SimpleTable: React.FC<SimpleTableData> = ({ dataobject, columnTypes }) => {
  const [tableData, setTableData] = useState<TableRow[] | undefined>();
  const [columns, setColumns] = useState<Column<TableRow>[]>([]);
  const [pageSize, setPageSize] = useState<number>(20);
  const [pageCount, setPageCount] = useState<number>(0);
  const Theme = useTheme();
  const [progress, setProgress] = useState(0);
  const sumCell: any = {};
  let r = 1;
  const tableStyles: React.CSSProperties & Record<string, string> = {
    'background-color': Theme.backgroundColor,
    '--text-color': Theme.primaryColor,
    '--container-width': Theme.containerWidth,
    '--border-style': Theme.containerBorderStyle,
    '--border-width': Theme.containerBorderWidth,
    '--border-color': Theme.containerBorderColor,
  };

  useEffect(() => {
    if (dataobject.hasOwnProperty("data")) {
      setTableData(dataobject.data as TableRow[]);
      setColumns(getTableColumns(columnTypes));
    } else {
      setTableData([]);
    }
  }, [dataobject])


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
    state: { pageIndex, globalFilter },
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable<TableRow>(
    {
      columns,
      data: (tableData === undefined ? [] : tableData),
      manualPagination: true, // Enable manual pagination
      pageCount,
      initialState: { pageIndex: 0, pageSize: 20 }, // Initial page index and size
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const handlePageSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSize = Number(e.target.value);
    if (newSize > 0) {
      setPageSize(newSize);
    }
  };

  // Function to convert table data to Excel
  const exportToExcel = (data: any[], fileName: string) => {
    // Extract column order from headerGroups
    const columnOrder = headerGroups[0].headers.map(header => header.id);

    // console.log("saud table data: ", Object.keys(data[0]));

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([], { header: Object.keys(data[0]) });

    // Simulate processing large data with setTimeout and update progress
    let chunkSize = 1000;
    let currentIndex = 0;

    const processChunk = () => {
      const chunk = data.slice(currentIndex, currentIndex + chunkSize);
      XLSX.utils.sheet_add_json(ws, chunk, { origin: -1, skipHeader: true });
      currentIndex += chunkSize;
      setProgress(Math.min((currentIndex / data.length) * 100, 100));

      if (currentIndex < data.length) {
        setTimeout(processChunk, 0);
      } else {
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, `${fileName}.xlsx`);
        setProgress(0); // Reset progress after completion
      }
    };

    processChunk();
  };


  const exportToPDF = (data: any[], fileName: string, displayColumnHeaders: any[]) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 20;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(10);

    // let columnHeaders = Object.keys(data[0]).filter(function (key) {
    //   return !(key.includes("_") && key.);
    // }
    // );

    let displayData = data.map(row => Object.fromEntries(displayColumnHeaders.map(column => [column, row[column]])));


    // console.log("saud pdf keys", columnHeaders);
    // console.log("saud pdf values", displayData);

    doc.text(fileName, marginLeft, 20);
    autoTable(doc, {
      head: [displayColumnHeaders],
      body: displayData.map(row => Object.values(row))

    }
    );

    doc.save(`${fileName}.pdf`);



  }

  const getTableDataAsJson = () => {
    return rows.map((row) => {
      prepareRow(row);
      return row.original;
    });
  };
  const handleExport = () => {
    const xlsData = getTableDataAsJson();
    // console.log("saud json:", getTableDataAsJson());
    let headerFullList: any[] = [];
    Object.keys(dataobject.pagemeta.columntypes).filter(keylabel => ((keylabel !== 'rownumber_meta') &&
    (!keylabel.endsWith("_hidden")) && (!keylabel.startsWith('row(')))).forEach(element => {
      headerFullList.push([element, dataobject.pagemeta.columntypes[element].columnIndex]);
    });



    function compareFn(a: any[], b: any[]) {
      if (a[1] < b[1]) {
        return -1;
      } else if (a[1] > b[1]) {
        return 1;
      }
      // a must be equal to b
      return 0;
    }

    headerFullList.sort(compareFn);
    //headerFullList = headerFullList.slice(0, 5)

    let displayHeaderList: any[] = [];
    headerFullList.forEach(header => displayHeaderList.push(header[0]))

    let filteredData: any[] = [];
    xlsData.forEach(row => {
      let filteredRow: any = {};
      displayHeaderList.forEach(key => {
        filteredRow[key] = row[key];
      })
      filteredData.push(filteredRow);
    });

    console.log("Saud EXCEL: ", filteredData);
    exportToExcel(filteredData, 'table_data');
  };

  const handleExportPDF = () => {
    const xlsData = getTableDataAsJson();
    // console.log("saud json pdf:", getTableDataAsJson());
    let PDFHeaderFullList: any[] = [];
    Object.keys(dataobject.pagemeta.columntypes).filter(keylabel => ((keylabel !== 'rownumber_meta') &&
      (!keylabel.endsWith("_hidden")) && (!keylabel.startsWith('row(')))).forEach(element => {
        PDFHeaderFullList.push([element, dataobject.pagemeta.columntypes[element].columnIndex]);
      });


    function compareFn(a: any[], b: any[]) {
      if (a[1] < b[1]) {
        return -1;
      } else if (a[1] > b[1]) {
        return 1;
      }
      // a must be equal to b
      return 0;
    }

    PDFHeaderFullList.sort(compareFn);

    let displayHeaders: any[] = PDFHeaderFullList.slice(0, dataobject.pagemeta.tableheaderscount);
    let displayNames: string[] = [];
    displayHeaders.forEach(header => displayNames.push(header[0]));

    // console.log("saud PDF headers:", displayNames);
    exportToPDF(xlsData, 'table_data', displayNames);
  };

  const hasFooter = columns.some(column => column.Footer);

  if (isEmptyObj(dataobject)) {
    return <div>No Data Found Yet</div>
  }
  else {
    //console.log("Column Types are ", columnTypes);
    return (
      <div className="card h-100 me-2">
        <div className="card-header">
          {/* <div className="d-flex justify-content-center">
            <h2>{dataobject.pagemeta.page_title}</h2>
          </div> */}
          <div className="row">
            <div key={r++} className="col-md-6">
              <div className="input-group">
                <span className="input-group-text">Search:</span>
                <input
                  value={globalFilter || ''}
                  name="table_search"
                  id="table_search"
                  onChange={e => setGlobalFilter(e.target.value)}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-end align-items-center">
              <h4 className="me-3">Total Rows: {rows.length}</h4>
              <button className="btn btn-primary me-2" onClick={handleExportPDF}>PDF</button>
              <button className="btn btn-warning m" onClick={handleExport}>Excel</button>
            </div>
            {progress > 0 && (
              <ProgressBar now={progress} label={`${progress}%`} animated striped />
            )}
          </div>
        </div>
        <div className="card-body d-flex flex-column">
          <div style={{
            overflow: 'auto', width: '100%',
            maxHeight: '550px',
            borderTop: '1px solid #ddd',
            borderBottom: '1px solid #ddd',
            borderLeft: '1px solid #ddd',
            borderRight: '1px solid #ddd',
          }}>
            <table id={"result_table"} {...getTableProps()} className="w-100">
              <thead style={{ position: 'sticky', top: '-1px', zIndex: 1, backgroundColor: Theme.topbarBackgroundColor }}>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => (
                      <th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                      >
                        {column.render('Header')}
                        <span>
                          {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, rowIndex) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()} >
                      {row.cells.map((cell, cellIndex) => {
                        const colkey = cell.column.id;
                        const columnkey = Object.keys(columnTypes).find((collabel: any) => columnTypes[collabel].columnIndex === colkey);
                        const cellcol = columnTypes[columnkey ?? 'none']
                        return (
                          <td
                            {...cell.getCellProps()} >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}

              </tbody>
              {hasFooter && <tfoot className="mt-auto">
                {footerGroups.map(group => (
                  <tr {...group.getFooterGroupProps()}>
                    {group.headers.map(column => (
                      <td {...column.getFooterProps()} style={{ textAlign: 'right', paddingRight: '10px', fontWeight: 'bold', borderTop: '2px solid black' }}>
                        {column.Footer ? column.render('Footer') : null}
                      </td>
                    ))}
                  </tr>
                ))}
              </tfoot>
              }
            </table>
          </div>
        </div >
        {
          (rows.length > 500) && <div>
            <span>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageCount}
              </strong>{' '}
            </span>
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              Previous
            </button>
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              Next
            </button>
            <span>
              | Go to page:{' '}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: '50px' }}
              />
            </span>
            <span>
              | Rows per page:{' '}
              <input
                type="number"
                value={pageSize}
                onChange={handlePageSizeChange}
                style={{ width: '50px' }}
              />
            </span>
          </div>
        }
      </div >
    );
  }
};


export default SimpleTable;

