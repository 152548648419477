import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { ThemeProps, useTheme } from '../Template/ThemeContext';
import AppContext from '../context/AppContext';
import { isEmptyObj, isEmptyOrNull } from '../context/MiscFunc';
import { hpost } from '../context/apiService';
import { BaseContentProps, PageProps } from '../types/menu-item';
import AlertModal from './AlertModal';
import BillerProfile from './BillerProfile';
import BulkLoader from './BulkLoader';
import Dashboard from './Dashboard/Dashboard';
import DataForm from './DataForm';
import DynamicTable from './DynamicTable';
import MasterDetailForm from './MasterDetailForm';
import MultiMenuEditor from './MultiMenuEditor2';
import PDFTemplateViewer from './PDFTemplateViewer';
import ProcessingForm from './ProcessingForm';
import ReportingForm from './ReportingForm';
import SegFormLayout from './SegFormLayout';
import SimpleLedgerTable from './SimpleLedgerTable';
import TabDataForm from './TabDataForm';

type MyThemeProperties = keyof ThemeProps;

const ContentPane: React.FC<PageProps> = ({ currApplication, pageName }) => {
  const [contentProps, setContentProps] = useState<BaseContentProps>();
  const [currentPage, setCurrentPage] = useState<string>(pageName);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [columnTypes, setColumnTypes] = useState<any>([]);
  const [jobrunMessage, setJobRunMessage] = useState<string>();
  const Theme = useTheme();
  const { sessionData, setCurrAppLogo, refreshData, setRefreshData, searchFilter, setSearchFilter } = useContext(AppContext);
  

  const contentPaneStyles: React.CSSProperties & Record<string, string> = {
    'backgroundColor': Theme.backgroundColor,
    '--text-color': Theme.primaryColor,
    '--container-width': Theme.containerWidth,
    '--border-style': Theme.containerBorderStyle,
    '--border-width': Theme.containerBorderWidth,
    '--border-color': Theme.containerBorderColor,
  };

  useEffect(() => {
    // Fetch data using the API service
    console.log("Content Pane Search Use Effect  ", searchFilter, " for Page ", currentPage);
    // Fetch data using the API service
    if (isEmptyOrNull(searchFilter)) {
       setCurrentPage(pageName);

    } else {
      fetchData(true);
      setSearchFilter('');
    }
  }, [currApplication, pageName, searchFilter]);

  useEffect(() => {
        if (refreshData) {
           console.log("Content Pane Refresh Data ", currApplication, ' Page is ', currentPage, ' Page Name is ', pageName);
           setRefreshData(false)
        }
        fetchData(false);
  }, [ currentPage, refreshData]);

  const displayMessage = (message : string, timeout : number) => {
     setJobRunMessage(message);
     setTimeout(() => {
      setJobRunMessage('');
    }, timeout)
  }

  const fetchData = async (withFilter: boolean) => {
    let params: any = {
      objecttype: "pageobject",
      pagename: currentPage,
      application_id: currApplication?.id,
      biller_id: sessionData.data["Biller Id"],
    }
    if (withFilter) {
      params['searchcriteria'] = searchFilter ? searchFilter : '';
    }

    console.log("Content Pane Before Data Fetch ", pageName, ' Current ', currentPage, ' for application ', currApplication);
    if (currentPage.startsWith("proc") ||
        (currentPage === 'gettemplatepdf') ||
        currentPage.endsWith("report")) {
       return;
    }

    let response = await hpost('/sec/data', params);
    if (response.data) {
      let pagetemplate = JSON.parse(JSON.stringify(response.data));
      console.log("Content Pane Props: Current ", currentPage, ' Page ', pageName)
      console.log("Response: ", pagetemplate);
      if (!withFilter && (currentPage !== 'billerledger') && (pagetemplate.hasOwnProperty("_notfound"))) {
        setContentProps({
          currentError: pagetemplate._notfound
        });
        return;
      }
      
      if (pagetemplate.hasOwnProperty("data")) {
        if (pagetemplate.data[0].hasOwnProperty("Logo")) {
          if (!isEmptyObj(pagetemplate.data[0].Logo)) {
            setCurrAppLogo(pagetemplate.data[0].Logo);
          }
        }
      }
      if (pagetemplate.hasOwnProperty("pagemeta")) {
        setColumnTypes(pagetemplate.pagemeta.columntypes);
        // console.log("Table Headers Count is set to ", pagetemplate.pagemeta.tableheaderscount)
      } else {
        setColumnTypes({});
      }
      setContentProps({
        dataObj: response.data,
        templateName: (isEmptyObj(pagetemplate.pagemeta) ? 'DataForm' : pagetemplate.pagemeta.templateName),
        pageTitle: pagetemplate.pagemeta.page_title + '',
        pageActions: pagetemplate.pagemeta.pageactions + '',
        tableheadercount: pagetemplate.pagemeta.tableheaderscount,
      });
      setDataLoaded(true);
    } else if (response.error) {
      console.error("Error: ", response.error);
      setContentProps({
        currentError: response.error
      });
    };
  }

  const processPendingJobs = async () => {
    let params: any = {
      application_id: currApplication?.id,
      biller_id: sessionData.data["Biller Id"],
    }
   

    console.log("Content Pane Before Processing pending jobs for ", params);

    let response = await hpost('/sec/runpendingjobs', params);
    if (response.data) {
      let responseinfo = JSON.parse(JSON.stringify(response.data));
      if (responseinfo.hasOwnProperty("_success")) {
        displayMessage(responseinfo._success, 5000);
        setRefreshData(true);
      }
    } else if (response.error) {
      displayMessage(response.error, 5000);
    };
  }


  const processPendingComms = async () => {
    let params: any = {
      application_id: currApplication?.id,
      biller_id: sessionData.data["Biller Id"],
    }
   

    console.log("Content Pane Before Processing communications for ", params);

    let response = await hpost('/sec/processcomm', params);
    if (response.data) {
      let responseinfo = JSON.parse(JSON.stringify(response.data));
      if (responseinfo.hasOwnProperty("_success")) {
        displayMessage(responseinfo._success, 5000);
        setRefreshData(true);
      }
    } else if (response.error) {
      displayMessage(response.error, 5000);
    };
  }

  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("Contents: ", contentProps);
    console.log("Changed Color value is ", event.target.value);
    Theme.setKeyProperty('primaryColor', event.target.value);
    Theme.setKeyProperty('backgroundColor', "#6655ff");
    Theme.setKeyProperty('containerWidth', '60%');
    Theme.setKeyProperty('containerBorderStyle', 'dashed');
    Theme.setKeyProperty('containerBorderWidth', '2px');
    Theme.setKeyProperty('containerBorderColor', event.target.value);

  };

  const handleSettingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const propertyName = event.target.name;
    const propertyValue = event.target.value;


    for (const key of Object.keys(Theme) as MyThemeProperties[]) {
      if (key === propertyName) {
        Theme.setKeyProperty(key, propertyValue);
        console.log("Property Found ", key);
        break;
      } else {
        console.log("Not Matched...", key);
      }
    }
    /*
        Object.keys(Theme).filter(refkey => (refkey == propertyName)).map( (tkey) => {
          console.log("Need to set this Property ", tkey, typeof tkey);
          Theme.setProperty(tkey, propertyValue);
        }
        );
        */
  }
  
  if (currentPage.startsWith("proc")) {
    return (<ProcessingForm pageName={currentPage.substring(4)}
      currApplication={currApplication} />)
  } else if (currentPage === 'bulkload') {
    if (!isEmptyObj(contentProps?.dataObj) && (currentPage === contentProps?.dataObj.dataobject)) {
        return (<BulkLoader  loadoptions={contentProps?.dataObj}/>)
    } else {
        return <div>No dataObj yet</div>
    }
  } else if (currentPage === 'gettemplatepdf') {
    return (<PDFTemplateViewer />)
  } else if (currentPage.endsWith("report")) {
    return (<ReportingForm pageName={currentPage} currApplication={currApplication} />);
  } else
    console.log("Content Pane Page ', currentPage, ' Data Loaded ", dataLoaded, ' Contents ', contentProps);
    if (!dataLoaded) {
      return (
        <div className="text-center">
          <div className="spinner-grow text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <div className="spinner-grow text-danger" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <div className="spinner-grow text-warning" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <div className="spinner-grow text-info" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )
    } else {
      if ((currentPage !== 'billerledger') && (contentProps?.currentError)) {
        console.log("Current Error is ", contentProps.currentError);
        return (<Container className="mt-3 custom-container"
          style={contentPaneStyles}>
          <AlertModal alerttitle="Error" msgobj={contentProps.currentError} display={true} />
        </Container>
        );
      } else {
        let r = 0;
        console.log("Content Pane Page Name", currentPage, " Data Object is ", contentProps?.dataObj);
        if (currentPage !== contentProps?.dataObj.dataobject) {
          return (
            <div className="text-center">
              <div className="spinner-grow text-success" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <div className="spinner-grow text-danger" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <div className="spinner-grow text-warning" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <div className="spinner-grow text-info" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )
        }

        let renderContent = <DataForm pagename={currentPage} currApplication={currApplication} dataobject={contentProps.dataObj} pageactions={contentProps.pageActions} />;


        switch (contentProps.templateName) {
          case 'DataForm':
            renderContent = (<DataForm pagename={currentPage} currApplication={currApplication} dataobject={contentProps.dataObj} pageactions={contentProps.pageActions} />);
            break;
          case 'LedgerScreen':
            renderContent = (<SimpleLedgerTable pagename={currentPage} currApplication={currApplication} />)
            break;
          case 'TabForm':
            renderContent = (<TabDataForm pageName={currentPage} currApplication={currApplication}  />);
            break;
          case 'ReportForm':
            renderContent = (<ReportingForm pageName={currentPage} currApplication={currApplication} />);
            break;
          case 'FixedContents':
              /* renderContent = (<FixedContentTester />); */
              renderContent = (<SegFormLayout pagename={currentPage} currApplication={currApplication} dataobject={contentProps.dataObj} pageactions={contentProps.pageActions} />);
              break;
          case 'JobMonitor': 
          console.log("Job Monitor");
          renderContent = (<div>
              <DynamicTable pagename={currentPage}
              currApplication={currApplication}
              dataobject={contentProps.dataObj}
              columnTypes={columnTypes}
              pageactions={contentProps.pageActions}
              tableheadercount={contentProps.tableheadercount ?? 5} />
              <div className="d-flex mt-3">
              <button type="button" name="pendingjobs"
                 style={{ backgroundColor: "#0397B1" }} 
                 className="btn btn-success ms-auto me-2" onClick={(processPendingJobs)}>Process Now</button>
                 </div>
                 {(jobrunMessage) && <AlertModal alerttitle="Job Status" msgobj={jobrunMessage} display={true} />}
              </div>)
          break;
          case 'CommProcess': 
          console.log("CommProcess");
          renderContent = (<div>
              <DynamicTable pagename={currentPage}
              currApplication={currApplication}
              dataobject={contentProps.dataObj}
              columnTypes={columnTypes}
              pageactions={contentProps.pageActions}
              tableheadercount={contentProps.tableheadercount ?? 5} />
              <div className="d-flex mt-3">
              <button type="button" name="pendingcomms"
                 style={{ backgroundColor: "#0397B1" }} 
                 className="btn btn-success ms-auto me-2" onClick={(processPendingComms)}>Process Communication</button>
                 </div>
                 {(jobrunMessage) && <AlertModal alerttitle="Processing Status" msgobj={jobrunMessage} display={true} />}
              </div>)
          break;
          case 'DataList':
            renderContent = (<DynamicTable pagename={currentPage}
              currApplication={currApplication}
              dataobject={contentProps.dataObj}
              columnTypes={columnTypes}
              pageactions={contentProps.pageActions}
              tableheadercount={contentProps.tableheadercount ?? 5} />)
            break;
          case 'ProcessForm':
            console.log("Rendering Processing Form");
            renderContent = (<ProcessingForm pageName={currentPage}
              currApplication={currApplication} />)
            break;
          case 'MenuEdit':
            renderContent = <MultiMenuEditor setDataLoaded={setDataLoaded} pagename={currentPage} currApplication={currApplication} dataobject={contentProps.dataObj} />
            break;
          case 'MasterForm':
            renderContent = (<MasterDetailForm pagename={currentPage} currApplication={currApplication} dataobject={contentProps.dataObj} columnTypes={columnTypes} />);
            break;
          case "ProfileForm":
            renderContent = (<BillerProfile pagename={currentPage} currApplication={currApplication} dataobject={contentProps.dataObj} />);
            break;
          case "Dashboard":
            renderContent = (<Dashboard pageName={currentPage} currApplication={currApplication} />)
            break;
          default:
            renderContent = (<DataForm pagename={currentPage} currApplication={currApplication} dataobject={contentProps.dataObj} pageactions={contentProps.pageActions} />);
        }
        return (
          <div className="ml-3">
            {

              (currentPage === 'Settings') &&
              <div className="custom-container container">
                <h2 className="primary-color">Style</h2>
                <div className="row ml-3">
                  {
                    Object.entries(Theme).filter((currkey) => { return currkey[0] !== 'setProperty' }).map((settingkey, index) => {
                      console.log(" Setting ", settingkey, " Index ", index);

                      return (
                        <div key={r++} className="col-lg-4">
                          <label htmlFor={settingkey[0]} className="form-label col-3">{settingkey[0]} </label>
                          <input type={settingkey[0].endsWith("Color") ? "color" : "text"} name={settingkey[0]} value={settingkey[1]} onChange={handleSettingChange} />
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            }

            {
              !isEmptyObj(contentProps.dataObj) &&
              (<Container className="mt-3 custom-container"
                style={contentPaneStyles}>
                {contentProps.pageTitle !== "Dashboard" &&
                  <>
                    <h2 className="primary-color mb-4">{contentProps.pageTitle}</h2>
                    <p className="mb-2"></p>
                  </>}
                {renderContent}
              </Container>
              )
            }
          </div>
        )
      }
    }
};

export default ContentPane;
