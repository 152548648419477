// src/components/MenuEditor.tsx
import React, { SetStateAction, useContext, useEffect, useState } from 'react';
import AppContext from '../context/AppContext';
import { hpost } from '../context/apiService';
import { MultiLevelMenuItem as MultiLevelMenuItemType } from '../types/menu-item';
import AlertModal from './AlertModal';
import MultiLevelMenuItem from './MultiLevelMenuItem2';

const initialMenuItems: MultiLevelMenuItemType[] = [
  {
    "name": "billermenu",
    "icon": "",
    "option_id": "14",
    "position": 1,
    "href": "",
    "prompt": "Settings",
    "value": "Profile",
    "class": "",
    "rownumber_meta": 1,
    "parent_option_id": "0",
    "status": "A",
    "sorted_position": 0
  },
];
interface DataProps {
  pagename: string;
  currApplication: Record<string, string> | null;
  dataobject: any;
  setDataLoaded: React.Dispatch<SetStateAction<boolean>>;
}
const MultiMenuEditor2: React.FC<DataProps> = ({ pagename, currApplication, dataobject, setDataLoaded }) => {
  const [menuData, setMenuData] = useState<MultiLevelMenuItemType[]>([]);
  const [menuItems, setMenuItems] = useState<MultiLevelMenuItemType[]>([]);
  const { currMenuId } = useContext(AppContext);

  const [alertCondition, setAlertCondition] = useState(false);

  const initializeMenuItems = (parent_option_id: string) => {
    let initMenuItems: MultiLevelMenuItemType[] = [];
    let new_position = 1;
    let resequenceInd = false;
    menuData.filter((fitem) => (fitem.parent_option_id === parent_option_id) && (fitem.status !== 'X')).map((item, index) => {
      item.children = initializeMenuItems(item.option_id);
      //item.position = new_position * 10;
      /*
      if ((item.sorted_position === 0) || (item.sorted_position%10 !== 0)) {
        resequenceInd = true;
      }
      if (resequenceInd) {
        console.log("Setting New Position for Item: ", item, new_position);
        item.sorted_position = new_position * 10;
      }
      */
      new_position++;
      console.log("Adding item: ", item);
      initMenuItems.push(item);
    })
    const sortedItems = [...initMenuItems].sort((a, b) => Number(a.position) - Number(b.position));
    return sortedItems;
  }

  useEffect(() => {
    setMenuData(dataobject.data);
  }, [currApplication, dataobject]);

  useEffect(() => {
    const updatedMenuItems = initializeMenuItems('0')
    console.log("Before setting Menu Items", menuData, updatedMenuItems);
    setMenuItems(updatedMenuItems);
  }, [menuData])

  const getParentIndex = (p_parent_option: string) => {
    return menuData.findIndex(item => item.option_id === p_parent_option)
  }
  const deleteMenuNode = (deleteNode: MultiLevelMenuItemType): MultiLevelMenuItemType[] => {
    return menuData.filter((item) => {
      if (item.option_id === deleteNode.option_id) {
        // Delete the item if its ID matches the specified ID
        item.status = 'X';
      } else if (item.parent_option_id === deleteNode.option_id) {
        // Recursively delete items from children arrays
        return deleteMenuNode(item);
      }
      return true;
    });
  };
  const updateMenuData = (changedItem: MultiLevelMenuItemType, actionType: string = 'U', itemIndex: number = -1, toItemIndex : number = -1) => {

    if (itemIndex === -1) {
      itemIndex = menuData.findIndex(item => item.option_id === changedItem.option_id);
    }
    console.log("Index Found: ", itemIndex);
    if (itemIndex !== -1) {
      let newposition : number = 1;
      // Create a new array with the updated item
      setMenuData((prevDataItems) => {
        const updatedDataItems = [...prevDataItems];
        switch (actionType) {
          case 'U':
            console.log("Changed Item Index is ", itemIndex, " Sorted Position is ", changedItem.position, changedItem.prompt);
            if (toItemIndex === -1) {
              updatedDataItems.splice(itemIndex, 1, changedItem);
            } else {
              updatedDataItems.splice(itemIndex, 1);
              updatedDataItems.splice(toItemIndex, 0, changedItem);
            }
            break;
          case 'N':
            /*delete changedItem.children; */
            console.log("Changed Item Position is ", changedItem.position, changedItem.prompt, changedItem.option_id);
            let newItem : MultiLevelMenuItemType;
            if (changedItem.parent_option_id !== '0') {
              newItem = { ...changedItem, prompt: 'New Sub Item', option_id: 'S' + (new Date()).getTime(), position: Number(changedItem.position) + 9 }
            } else {
              newItem = { ...changedItem, prompt: 'New Item', option_id: 'N' + (new Date()).getTime(), position: Number(changedItem.position) + 9 }
            }
            delete newItem.children;
            console.log("New Item Position is ", newItem.position, newItem.prompt, newItem.option_id);
            updatedDataItems.splice(itemIndex, 0, newItem);
            const newDataItems = [...updatedDataItems].sort((a,b) => Number(a.position) - Number(b.position));
            newposition = 1;
            newDataItems.filter((fitem) => (fitem.parent_option_id === changedItem.parent_option_id) &&
                    (fitem.status !== 'X')).map((item) => {
              const updatedSeqItem = {...item, position : newposition * 10}
              const updatedIndex = updatedDataItems.findIndex(citem => citem.option_id === item.option_id);
              if (updatedIndex !== -1) {
                console.log("Setting Position for updatedIndex", updatedIndex, updatedSeqItem.option_id, updatedSeqItem.position, newposition);
                updatedDataItems.splice(updatedIndex, 1, updatedSeqItem);
                newposition++;
              }
            });
            break;
          case 'S':
            /* delete changedItem.children; */
            const newSubItem = { ...changedItem, prompt: 'New Sub Item', option_id: 'S' + (new Date()).getTime(), parent_option_id: changedItem.option_id, position : 0 }
            delete newSubItem.children;
            updatedDataItems.splice(itemIndex, 0, newSubItem);
            const subDataItems = [...updatedDataItems].sort((a,b) => Number(a.position) - Number(b.position));
            subDataItems.filter((fitem) => (fitem.parent_option_id === changedItem.option_id) &&
            (fitem.status !== 'X')).map((item) => {
                const updatedSeqItem = {...item, position : newposition * 10}
                const updatedIndex = updatedDataItems.findIndex(citem => citem.option_id === item.option_id);
                if (updatedIndex !== -1) {
                     updatedDataItems.splice(updatedIndex, 1, updatedSeqItem);
                } else {
                  console.log("Index Not Found for Item: ", item.option_id);
                }
            });
            break;
          case 'X':
            return deleteMenuNode(changedItem);
          default:
            console.log('Action Not Supported');
        }
        console.log("Updated Menu Data is ", updatedDataItems)
        return updatedDataItems;
      });
      //console.log("Updated Menu Data is ", menuData);
    }

  }
/*
  const addNewData = (currentItem: MultiLevelMenuItemType, isChild: boolean = false) => {
    const newItem = { ...currentItem, option_id: 'N' + currentItem.option_id }
    setMenuData((prevDataItems) => {
      const updatedDataItems = [...prevDataItems];
      if (isChild) {
        newItem.parent_option_id = currentItem.option_id;
      }
      updatedDataItems.push(newItem);
      return updatedDataItems;
    });
  }
  */
  const moveItem = (fromItem: MultiLevelMenuItemType, toItem: MultiLevelMenuItemType) => {
    //const dropItem = menuItems[hoverIndex];
    console.log("In MoveItems From  Key: ", fromItem.option_id, " parent id: ", fromItem.parent_option_id)
    console.log(" Drop Item  Key: ", toItem.option_id + " new parent: " + toItem.parent_option_id);
    // Find the index of the item with the matching id
    let validMove = true;
    let p_option_id = toItem.parent_option_id;
    while ((validMove) && (p_option_id !== '0')) {
      if (fromItem.option_id === p_option_id) {
        validMove = false;
      } else {
        let parentIndex = getParentIndex(p_option_id);
        if (parentIndex !== -1) {
          p_option_id = menuData[parentIndex].parent_option_id;
        } else {
          p_option_id = '0';
        }
      }
    }
    if (!validMove) {
      console.log("Move Not Allowed");
      setAlertCondition(true);
      return;
    } else {
      setAlertCondition(false);
    }
    const fromItemIndex = menuData.findIndex((item, index) => { console.log(item.option_id, index, fromItem.option_id); 
                                                                     if (item.option_id === fromItem.option_id) 
                                                                     { console.log('Key Found ', fromItem.option_id, index); 
                                                                     return true; }});
    const toItemIndex = menuData.findIndex((item, index) => { if (item.option_id === toItem.option_id) return true; });

    console.log("From Index Found: ", fromItemIndex, fromItem.position);
    console.log("To Index Found: ", toItemIndex, toItem.position);
    if (fromItemIndex !== -1) {
      //update Values in from Item.
      //Change Name only if the fromItem is not a leaf node
      const updatedposition = Number(toItem.position) + 1 ;
      const updatedItem = { ...menuData[fromItemIndex], name: ((fromItem.children)? toItem.name: fromItem.name), parent_option_id: toItem.parent_option_id, position: updatedposition };
      console.log("Changed Item Position is ", toItem.position, updatedItem.position, updatedposition, updatedItem.prompt);
      // Set the state with the updated array
      updateMenuData(updatedItem, 'U', fromItemIndex, toItemIndex);
    }


    /*
    setMenuItems((prevMenuItems) => {
      const updatedMenuItems = [...prevMenuItems];
      updatedMenuItems.splice(dragIndex, 1);
      updatedMenuItems.splice(hoverIndex, 0, draggedItem);
      console.log("Updated Menu Items: " + updatedMenuItems);
      return updatedMenuItems;
    });
    */
  };

  const onDropItem = (targetItem: MultiLevelMenuItemType) => {
    // Implement the onDropItem function to handle item dropping
    console.log("Target Item: ", targetItem);
    // Update the data to reflect the new parent-child relationship
    // Find the item by itemId and update its name in the data array
    /*
    const updatedData = menuItems.map((item) =>
      item.option_id === itemId + "" ? { ...item, name: newName } : item
    );
    setMenuItems(updatedData);
    */
  };

  const handleMoveToNewLevel = (itemId: string, newParentId: string) => {
    // Move the item to a new level by updating the parent-child relationship
    const updatedData = menuItems.map((item) => {
      if (item.option_id === newParentId + "") {
        return {
          ...item,
          children: [...(item.children || []), menuItems.find((i) => i.option_id === itemId + "")!],
        };
      } else if (item.children) {
        return {
          ...item,
          children: item.children.filter((i) => i.option_id !== itemId + ""),
        };
      }
      return item;
    });
    setMenuItems(updatedData || []);
  };
  const saveMenu = async () => {
    console.log(menuData);
    const sorteddata = menuData.sort((a,b) =>   a.parent_option_id.localeCompare(b.parent_option_id) || Number(a.position) - Number(b.position));
    // Make API call to update data
    console.log("Current Application: ", currApplication, currApplication?.menu_id);
    const params = {
      "application_id": currApplication?.id,
      "menu_id": currMenuId,
      "objectdata": JSON.stringify(menuData)
    };

    console.log("Before Updating Menu ");
    let response = await hpost('/sec/updateappmenu', params);
    if (response.error) {
      console.error(response.error);
    } else {
      console.log("Data Updated Successfully");
      setDataLoaded(false);
    }
  }
  const resetMenu = () => {
    setMenuData(dataobject.data);
  }
  const addFirstItem = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const firstItem = {
      "name": "newmenu",
      "icon": "",
      "option_id": "N1",
      "position": 10,
      "href": "",
      "prompt": "New Item",
      "value": "New Item",
      "class": "",
      "rownumber_meta": 1,
      "parent_option_id": "0",
      "status": "N",
      "sorted_position" : 10
    }

    setMenuData((prevDataItems) => {
      const updatedDataItems = [...prevDataItems, firstItem];
      return updatedDataItems;
    });
  }

  if (menuItems.length === 0) {
    return (<div><button onClick={addFirstItem} >Add New Item</button></div>);
  } else {
  return (
    <div>
      {
        (alertCondition) &&
        <AlertModal alerttitle='Not Allowed ' msgobj='Move Not Allowed' display={true} />
      }
      {
        menuItems.map((item, index) => (
          <MultiLevelMenuItem
            key={item.option_id}
            originalItem={item}
            index={index}
            moveItem={moveItem}
            onDropItem={onDropItem}
            onMoveToNewLevel={handleMoveToNewLevel}
            onUpdateData={updateMenuData}
          />
        ))
      }
       <div className="col-6 d-flex justify-content-end">
            <button type="button" onClick={saveMenu} className="btn btn-success me-2">Save</button>
            <button type="button" onClick={resetMenu} className="btn btn-warning">Reset</button>
       </div>
    </div>
  )
    }
}

export default MultiMenuEditor2;
