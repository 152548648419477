
import React, { useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTheme } from '../Template/ThemeContext';
import { useAppContext } from '../context/AppContextProvider';
import { extractRowValue, getStartingNumber, isEmptyObj, isValidDate, isValidDateOrTimestamp } from '../context/MiscFunc';
import { hpost } from '../context/apiService';
import docimage from '../img/docimage.jpg';
import pdfimage from '../img/pdfimage.jpg';
import { AddressComponents, LayoutDescription, PageDataProps } from '../types/menu-item';
import ImageUpload64 from '../utilities/ImageUpload64';
import AddressInput from './AddressInput';
import AlertModal from './AlertModal';
import { BIcon } from './BIcon';
import DocumentList from './DocumentList';
import LargeFilterList from './LargeFilterList';


interface LayoutProps {
  layout: LayoutDescription;
}


const SegFormLayout: React.FC<PageDataProps> = ({ pagename, currApplication, dataobject, initrow = '1', pageactions = 'Q', pagemode = 'E' }) => {
  const [formData, setFormData] = useState<any>({});
  const [dataChanges, setDataChanges] = useState<Record<string, any>>({});
  const [currentrow, setCurrentRow] = useState<number>(1);
  const [activeTab, setActiveTab] = useState(0);
  const [chkBoxToggle, setChkbxToggle] = useState<any>();
  const [rowAction, setRowAction] = useState<string>('save');
  const [formMessage, setFormMessage] = useState<string>('');
  const [dataChanged, setDataChanged] = useState<boolean>(false);
  const [readOnlyMode, setReadOnlyMode] = useState(false); // Initial disable read-only mode
  const [showRelated, setShowRelated] = useState(false); // Initial disable read-only mode
  const [showDocuments, setShowDocuments] = useState(false);
  const [docRequestType, setDocRequestType] = useState<any>('');
  const docpagelist = ['billercustomers', 'billerservlocations', 'billerprogaccts', 'lienmanagement', 'billerledger'];
  const docpageIdList = ['Customer', 'Location', 'Program Code', 'Lien Code', 'Program Code'];

  const { setRefreshData, sessionData, setCurrAppLogo, listItemSelected, setListItemSelected, lastMessage, displayMessage } = useAppContext();

  const streetRef = useRef<HTMLInputElement>(null);
  const cityRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<HTMLInputElement>(null);
  const zipcRef = useRef<HTMLInputElement>(null);
  const countryRef = useRef<HTMLInputElement>(null);
  const custInputRef = useRef<HTMLInputElement>(null);
  const [custInputValue, setCustInputValue] = useState<string>('');
  const locaInputRef = useRef<HTMLInputElement>(null);
  const [locaInputValue, setLocaInputValue] = useState<string>('');
  const acctInputRef = useRef<HTMLInputElement>(null);
  const [acctInputValue, setAcctInputValue] = useState<string>('');
  let key: number = 0;

  const Theme = useTheme();


  useEffect(() => {
    if (formMessage) {
      const timer = setTimeout(() => {
        setFormMessage('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [formMessage]);

  const handleShowLastMessage = () => {
    if (lastMessage) {
      displayMessage({ message: lastMessage.content, type: lastMessage.type, duration: 3 });
    }
  };

  useEffect(() => {
    if (dataobject.hasOwnProperty("data")) {
      setFormData(dataobject);
      setCurrentRow((initrow) ? Number(initrow) : 1);
      if (pageactions === 'Q') {
        setReadOnlyMode(true);
      } else if ((pageactions === 'N') && (rowAction !== "new")) {
        setReadOnlyMode(true);
      } else {
        setReadOnlyMode(false);
      }
    } else {
      setFormData(dataobject);
      if (!dataobject.hasOwnProperty("pagemeta")) {
        setFormMessage("No Data Found");
      } else {
        console.log("In form Data...No Data Found");
        const newobject = JSON.parse('{}');
        const columntypes = dataobject.pagemeta.columntypes;

        Object.keys(columntypes).filter((colkey) => {
          return !(columntypes[colkey].columnDisplayType.endsWith("_style"))
        }).map((datakey, j) => {
          newobject[datakey] = '';
          if (datakey.startsWith('biller_id')) {
            newobject[datakey] = sessionData.data["Biller Id"] ?? '1001';
          }
        });
        dataobject['data'] = [newobject];
        setRowAction("new");
        setCurrentRow(1);
      }
    }
    setDataChanges({});
    setDataChanged(false);
    console.log("Data Object in useffect is ", dataobject + " Row value is ", initrow);
  }, [dataobject, initrow, pageactions])

  const handleChkbxToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChkbxToggle(!chkBoxToggle)
  }

  const renderTooltip = (tooltipText: string) => (
    <Tooltip id="button-tooltip">
      {tooltipText}
    </Tooltip>
  );

  const initDataChange = () => {
    const existingobj = formData.data[currentrow - 1];
    const newobject = JSON.parse('{}');
    const columntypes = formData.pagemeta.columntypes;
    Object.keys(columntypes).filter(datakey => (columntypes[datakey].columnDisplayType === 'key') ||
      (datakey.endsWith("_hidden"))).map((datakey, j) => {
        newobject[datakey] = existingobj[datakey];
      });
    return newobject;
  }

  const handleStreetChange = (newvalue: string) => {
    if (streetRef.current !== null) {
      const existingobj = formData.data[currentrow - 1];
      if (isEmptyObj(dataChanges)) {
        let newobject = initDataChange();
        console.log("New Object: " + JSON.stringify(newobject));
        newobject[streetRef.current.id] = newvalue;
        setDataChanges(newobject);
      } else {
        let newobject = { [streetRef.current.id]: newvalue }
        setDataChanges((prevData) => ({ ...prevData, ...newobject }));
      }
    }
  }

  const handleAddressChange = (components: AddressComponents) => {
    console.log("Handle Address Change is called with " + JSON.stringify(components));
    let newdataobj = JSON.parse("{}");
    if (isEmptyObj(dataChanges)) {
      newdataobj = initDataChange();
      //JSON.parse(JSON.stringify(formData.data[currentrow - 1]));
    } else {
      newdataobj = ({});
    }
    if ((streetRef.current) &&
      (streetRef.current?.value !== components.street)) {
      //cityRef.current.value = components.city;
      newdataobj[streetRef.current.id] = components.street;
      console.log("Set Street to " + components.street);
      //cityRef.current.dispatchEvent(new Event('change', { bubbles: false }));
    }

    if ((cityRef.current) &&
      (cityRef.current?.value !== components.city)) {
      //cityRef.current.value = components.city;
      newdataobj[cityRef.current.id] = components.city;
      console.log("Set City to " + components.city);
      //cityRef.current.dispatchEvent(new Event('change', { bubbles: false }));
    }

    if ((stateRef.current) &&
      (stateRef.current?.value !== components.state)) {
      //stateRef.current.value = components.state;
      newdataobj[stateRef.current.id] = components.state;
      console.log("Set State to " + components.state);
      //stateRef.current.dispatchEvent(new Event('change', { bubbles: false }));
    }

    if ((zipcRef.current) &&
      (zipcRef.current?.value !== components.postalCode)) {
      //zipcRef.current.value = components.postalCode;
      newdataobj[zipcRef.current.id] = components.postalCode;
      console.log("Set City to " + components.postalCode);
      // zipcRef.current.dispatchEvent(new Event('change', { bubbles: true }));
    }

    if ((countryRef.current) &&
      (countryRef.current?.value !== components.country)) {
      countryRef.current.value = components.country;
    }
    if (isEmptyObj(dataChanges)) {
      setDataChanges(newdataobj);
    } else {
      setDataChanges((prevData) => ({ ...prevData, ...newdataobj }));
    }

  }

  const fileupload64 = (id: string, imagevalue: string | null) => {
    if (imagevalue == null) {
      return;
    }
    console.log("File Upload ID " + id);
    if (isEmptyObj(dataChanges)) {
      let newobject = initDataChange();
      newobject[id] = imagevalue;
      setDataChanges(newobject);
    } else {
      setDataChanges((prevData) => ({ ...prevData, [id]: imagevalue }));
    }
    console.log(JSON.stringify(dataChanges));
  }

  const setRowCopy = (selectionname: string) => {
    const existingobj = formData.data[currentrow - 1];
    const newobject = JSON.parse('{}');
    const columntypes = formData.pagemeta.columntypes;
    Object.keys(columntypes).filter((colkey) => {
      return !(columntypes[colkey].columnDisplayType.endsWith("_style"))
    }).map((datakey, j) => {
      switch (columntypes[datakey].columnDisplayType) {
        case null:
        case "meta":
        case "key":
        case "hidden":
        case "display":
          newobject[datakey] = existingobj[datakey];
          break;
        case "groupstart":
        case "groupend":
        case "reffieldstart":
        case "reffieldend":
        case "style":
          break;
        case "unique":
          newobject[datakey] = selectionname.toUpperCase();
          break;
        case "auto":
          newobject[datakey] = 'AUTO-GEN';
          break;
        default:
          if (columntypes[datakey].columnDisplayType.startsWith('llist')) {
            newobject[datakey] = '';
          } else {
            if (dataChanges.hasOwnProperty(datakey)) {
              if (selectionname === 'copy') {
                newobject[datakey] = dataChanges[datakey];
              } else {
                newobject[datakey] = '';
              }
            } else {
              if (selectionname === 'copy') {
                newobject[datakey] = existingobj[datakey];
              } else {
                newobject[datakey] = '';
              }
            }
          }
      }
    });
    setDataChanges(newobject);
  }

  const handleInputBlur = (ref: React.RefObject<HTMLInputElement>) => {
    if (ref.current) {
      console.log("In Parent Blur Field Name ", ref.current.name, " Value ", ref.current.value);
      updateChangedValue(ref.current.name, ref.current.value, ref.current.name);
    }
  };

  const updateChangedValue = (name: string, value: any, id: any) => {
    const existingobj = formData.data[currentrow - 1];
    const columntypes = formData.pagemeta.columntypes;
    const columndisplaytype = formData.pagemeta.columntypes[id].columnDisplayType;
    let newvalue = value;
    if (columndisplaytype === 'checkbox' || columndisplaytype === 'chktoggle') {
      let currentvalue = existingobj[id];
      if (dataChanges.hasOwnProperty(id)) {
        currentvalue = dataChanges[id];
      }
      if (currentvalue.toLowerCase() === 'y') {
        newvalue = 'N';
      } else {
        newvalue = 'Y'
      }
    }
    if (columndisplaytype === 'unique' || columndisplaytype === 'uppercase') {
      newvalue = newvalue.toUpperCase();
    }

    if (columndisplaytype.startsWith("llist")) {
      newvalue = getStartingNumber(value);
    }


    //console.log("Existing Object: "+JSON.stringify(existingobj));

    if (isEmptyObj(dataChanges)) {
      let newobject = initDataChange();
      console.log("Auto New Object: " + JSON.stringify(newobject));
      newobject[id] = newvalue;
      setDataChanges(newobject);
    } else {
      setDataChanges((prevData) => ({ ...prevData, [id]: newvalue }));
    }
    console.log(JSON.stringify(dataChanges));
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value, id } = e.target;
    updateChangedValue(name, value, id);
  }

  useEffect(() => {
    console.log("Changed Data is " + JSON.stringify(dataChanges))
  }, [dataChanges])

  const handleCancel = (e: React.FormEvent) => {
    if (listItemSelected) {
      setRefreshData(dataChanged);
      setListItemSelected(false)
    }
  }
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isEmptyObj(dataChanges)) {
      // alert("No Changes done on this page");
      return;
    }
    setDataChanged(true);
    if (validateForm()) {
      //onSubmit(formData);
      //console.log('Submitted data:', formData);
      //console.log('Columns List '+JSON.stringify(formData.Status))
      //console.log('Data Values '+JSON.stringify(formData["Established Date"].columnType));
      const entries = Object.entries(dataChanges);
      for (let [key, value] of entries) {
        console.log(key, value);
      }

      // Make API call to update data
      const params = {
        objecttype: "pageobject", pagename: pagename,
        biller_id: "1001",
        action: (rowAction === '' ? 'save' : rowAction),
        application_id: currApplication?.id,
        changeload: dataChanges
      };

      console.log("Before Data Fetch " + pagename);
      let response = await hpost('/sec/updatedata', params);
      if (!response.error) {
        const responseobj = JSON.parse(JSON.stringify(response.data));
        if (responseobj.hasOwnProperty("result") && responseobj.hasOwnProperty("message")) {
          if (responseobj.result === 'Success') {
            setFormMessage(responseobj.message);
          }
        } else {
          setFormMessage("Data saved successfully");
        }
      } else if (response.error) {
        console.error(response.error);
        setFormMessage(response.error);
      } else {
        if (dataChanges.hasOwnProperty("Logo")) {
          setCurrAppLogo(dataChanges.Logo);
        }
      }
    } else {
      console.error("Form Data is not Valid");
      setFormMessage("Form Data is not Valid");
    }
  }


  const handleReset = (e: React.FormEvent) => {
    // alert("Handling Reset");
    const entries = Object.entries(dataChanges);
    for (let [key, value] of entries) {
      console.log(key, value);
    }
    setDataChanges({});
  };

  // Handle button click
  const handleButtonAction = (event: React.MouseEvent<HTMLButtonElement>) => {
    const buttonName = event.currentTarget.name;
    if ((buttonName === 'copy') || (buttonName === 'new')) {
      if (pageactions !== 'Q') {
        if (pageactions === 'N' || pageactions === 'A') {
          setRowCopy(buttonName);
          setRowAction('new'); // Update the action state to indicate it is a new row
          setReadOnlyMode(false);
        }
      }
      event.preventDefault();
    } else if (buttonName === 'showdocuments') {
      event.preventDefault();
      if (getDocRequestCode()) {
        setShowDocuments(true);
      }
      console.log("Action Button is ", buttonName, getDocRequestCode()); // Log the button name
    } else {
      console.log("Action Button is ", buttonName); // Save and Cancel buttons should progress further
    }
  };

  const validateForm = (): boolean => {
    // Perform validation logic for each field
    // Set error message for fields that fail validation
    // Example: Required field validation
    /*
    if (!formData.name) {
      newErrors.name = 'Name is required';
    }
*/
    // Add more validation rules as needed

    setFormMessage('');
    //return (!dataerror);
    return true;

    // Return true if the form is valid, false otherwise
    //return Object.keys(newErrors).length === 0;
  };


  const renderElement = (columnObj: any, rowdata: any) => {
    let datakey = columnObj.columnLabel;
    let currentJSX: JSX.Element | null = null;
    let cellClassName = "mb-3"
    switch (columnObj.columnDisplayType) {
      case null:
      case "meta":
      case "key":
      case "hidden":
        currentJSX =
          <input key={key++} type="hidden" id={columnObj.columnLabel} name={columnObj.columnName} defaultValue={rowdata[datakey]} />
        break;

      case "auto":
        currentJSX = (<div key={key++} className={cellClassName}>
          <label key={key++} htmlFor={datakey} className="form-label">{datakey}</label>
          <input key={key++} type="text" id={datakey} name={columnObj.columnName}
            className="form-control text-uppercase" readOnly
            value={rowdata[datakey]} title={`${rowAction !== "new" ? 'Display Only attribute' : 'Auto Generated Value'}`} />
        </div>)
        break;
      case "unique":
        currentJSX = (<div key={key++} className={cellClassName}>
          <label key={key++} htmlFor={datakey} className="form-label">{datakey}</label>
          <input key={key++} type="text" id={datakey} name={columnObj.columnName}
            className="form-control text-uppercase" onChange={handleChange}
            value={rowdata[datakey]} title={`${rowAction !== "new" ? 'Display Only attribute' : 'requires unique value'}`} readOnly={rowAction !== 'new'} />
        </div>)
        break;
      case "upper":
        currentJSX = (<div key={key++} className={cellClassName}>
          <label key={key++} htmlFor={datakey} className="form-label">{datakey}</label>
          <input key={key++} type="text" id={datakey} name={columnObj.columnName} className="form-control text-uppercase"
            onChange={handleChange}
            value={rowdata[datakey]}
            title={`${readOnlyMode ? 'Display Only attribute' : 'Upper case value'}`}
            readOnly={readOnlyMode} />
        </div>
        )
        break;
      case "docupload":
        currentJSX = (<div key={key++} className={cellClassName}>
          <label key={key++} htmlFor={datakey} className="form-label">{datakey}</label>
          <ImageUpload64 file_input_id={datakey} currentValue={rowdata[datakey]} onUpload={fileupload64} maxFileSize={65000} inputButtonLabel="Choose Image" />
        </div>)
        break;
      case "doclist":
        const listvalues = rowdata[datakey].split("~");
        currentJSX = (<div key={key++} className={cellClassName}>
          <ol className="list-group list-group-numbered">
            {
              listvalues.map((option: string, idx: any) => (
                <li key={key++} className="list-group-item d-flex justify-content-between align-items-start row">
                  <div className="ms-2 me-auto">
                    {(() => {
                      const optionvalues = option.split('&');
                      console.log('option ' + option + ' split values ' + optionvalues);
                      console.log('First ' + optionvalues[0]);
                      console.log('Second ' + optionvalues[1]);
                      console.log('Third ' + optionvalues[2]);
                      const optionComponents = [];
                      optionComponents.push(<div className="fw-bold">{optionvalues[1]}</div>)
                      if (optionvalues[1].toLowerCase().endsWith(".docx")) {
                        optionComponents.push(<img src={docimage} className="img-thumbnail" style={{ width: 100 }} alt="No Document" />)
                      } else if (optionvalues[1].toLowerCase().endsWith(".pdf")) {
                        optionComponents.push(<img src={pdfimage} className="img-thumbnail" style={{ width: 100 }} alt="No Document" />)
                      } else {
                        optionComponents.push(<img src={process.env.REACT_APP_API_URI + "/file/tnimage?documentid=" + optionvalues[0]} className="img-thumbnail" style={{ width: 100 }} alt="No Document" />)
                      }
                      return optionComponents;
                    })()}
                  </div>
                </li>
              ))
            }
          </ol>
        </div>
        )
        break;
      case "chktoggle":
        currentJSX = (<div key={key++} className={cellClassName}>
          <label className="form-label" htmlFor={datakey}>{datakey}</label>
          <div>
            <fieldset key={key++} id="chkToggleFieldset" className="radio btn-group">
              <input key={key++} id={datakey} name={columnObj.columName} className="btn-check" onChange={handleChange} type="checkbox"
                value={rowdata[datakey]} checked={rowdata[datakey].toLowerCase() === 'y'}
                title={`${readOnlyMode ? 'Display Only attribute' : ''}`}
                readOnly={readOnlyMode} />
              <label key={key++} className="btn btn-outline-primary" htmlFor={datakey} style={{ borderColor: "#0397B1", backgroundColor: rowdata[datakey] === "Y" ? "#0397B1" : "#ffffff", color: rowdata[datakey] === "Y" ? "#ffffff" : "#0397B1" }}> Yes </label>
              <input key={key++} id={datakey} name={columnObj.columName} className="btn-check" onChange={handleChange} type="checkbox" value={rowdata[datakey]} checked={!rowdata[datakey] || rowdata[datakey].toLowerCase() === 'n'}
                title={`${readOnlyMode ? 'Display Only attribute' : ''}`}
                readOnly={readOnlyMode} />
              <label key={key++} className="btn btn-outline-primary" htmlFor={datakey} style={{ borderColor: "#0397B1", backgroundColor: (!rowdata[datakey] || rowdata[datakey] === "N") ? "#0397B1" : "#ffffff", color: (!rowdata[datakey] || rowdata[datakey] === "N") ? "#ffffff" : "#0397B1" }}> No </label>
            </fieldset>
          </div>
        </div>
        )
        break;
      case "display":
        currentJSX = (<div key={key++} className={cellClassName}>
          <label key={key++} htmlFor={datakey} className="form-label">{datakey}</label>
          <input key={key++} readOnly type="text" id={datakey} name={columnObj.columnName} title="You cannot edit this value" className="form-control" value={rowdata[datakey]} />
        </div>
        )
        break;
      case "multiline":
        currentJSX = (<div key={key++} className={cellClassName}>
          <label key={key++} htmlFor={datakey} className="form-label">{datakey}</label>
          <textarea key={key++} rows={3} id={datakey} name={columnObj.columnName} className="form-control"
            onChange={handleChange} value={rowdata[datakey]}
            title={`${readOnlyMode ? 'Display Only attribute' : ''}`}
            readOnly={readOnlyMode} />
        </div>
        )
        break;
      case "checkbox":
        currentJSX = (<div key={key++} className={cellClassName}>
          <label key={key++} className="form-check-label" htmlFor={datakey}> {datakey} </label>
          <input key={key++} id={datakey} name={columnObj.columName} className="form-check mt-2" onChange={handleChange}
            type="checkbox" value={rowdata[datakey]} checked={rowdata[datakey].toLowerCase() === 'y'}
            title={`${readOnlyMode ? 'Display Only attribute' : ''}`}
            readOnly={readOnlyMode} />
        </div>
        )
        break;
      case "radiobutton":
        currentJSX = (<div key={key++} className={cellClassName}>
          <label key={key++} htmlFor={datakey} className="form-label">{datakey}</label>
          <input key={key++} type="text" id={datakey} name={columnObj.columnName} className="form-control"
            onChange={handleChange} value={rowdata[datakey]}
            title={`${readOnlyMode ? 'Display Only attribute' : ''}`}
            readOnly={readOnlyMode} />
        </div>
        )
        break;
      case "date":
        currentJSX = (<div key={key++} className={cellClassName}>
          <label key={key++} htmlFor={datakey} className="form-label">{datakey}</label>
          <input key={key++} type="date" id={datakey} name={columnObj.columnName} className="form-control"
            onChange={handleChange} value={rowdata[datakey]}
            title={`${readOnlyMode ? 'Display Only attribute' : 'Please enter or select date'}`}
            readOnly={readOnlyMode} />
        </div>
        )
        break;
      case "timestamp":
        currentJSX = (<div key={key++} className={cellClassName}>
          <label key={key++} htmlFor={datakey} className="form-label">{datakey}</label>
          <input key={key++} type="datetime-local" id={datakey} name={columnObj.columnName} className="form-control"
            onChange={handleChange} value={rowdata[datakey]}
            title={`${readOnlyMode ? 'Display Only attribute' : 'Date and Time value'}`}
            readOnly={readOnlyMode} />
        </div>
        )
        break;
      case "phone":
        currentJSX = (<div key={key++} className={cellClassName}>
          <label key={key++} htmlFor={datakey} className="form-label">{datakey}</label>
          <input key={key++} type="tel"
            id={datakey}
            name={columnObj.columnName}
            pattern="(\(\d{3}\) ?|\d{3}-)\d{3}-\d{4}"
            placeholder="123-456-7890"
            className="form-control" onChange={handleChange} value={rowdata[datakey]}
            title={`${readOnlyMode ? 'Display Only attribute' : 'Upper case Phone number should be in the format (123) 456-7890 or 123-456-7890'}`}
            readOnly={readOnlyMode} />
        </div>
        )
        break;
      case "bphone":
        currentJSX = (<div key={key++} className={cellClassName}>
          <label key={key++} htmlFor={datakey} className="form-label">{datakey}</label>
          <input key={key++} type="tel"
            id={datakey}
            name={columnObj.columnName}
            pattern="(\(\d{3}\) ?|\d{3}-)\d{3}-\d{4}( x[0-9]{1,5})?"
            placeholder="123-456-7890 x12345"
            className="form-control" onChange={handleChange} value={rowdata[datakey]}
            title={`${readOnlyMode ? 'Display Only attribute' : 'Business Phone number should be in the format (123) 456-7890 x12345 or 123-456-7890 x12345'}`}
            readOnly={readOnlyMode} />
        </div>
        )
        break;
      case "email":
        currentJSX = (<div key={key++} className={cellClassName}>
          <label key={key++} htmlFor={datakey} className="form-label">{datakey}</label>
          <input key={key++} type="email"
            id={datakey}
            name={columnObj.columnName}
            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
            className="form-control" onChange={handleChange} value={rowdata[datakey]}
            title={`${readOnlyMode ? 'Display Only attribute' : 'Please enter a valid email address'}`}
            readOnly={readOnlyMode} />
        </div>
        )
        break;
      case "selectlist":
        currentJSX = (<div key={key++} className={cellClassName}>
          <label key={key++} htmlFor={datakey} className="form-label">{datakey}</label>
          <select key={key++} id={datakey} name={columnObj.columnName}
            className="form-select" aria-label="Status" onChange={handleChange} value={rowdata[datakey]}
            title={`${readOnlyMode ? 'Display Only attribute' : ''}`}
            disabled={readOnlyMode}>
            {
              columnObj.columnListofValues.map((option: any, idx: any) => (
                <option key={key++} value={option.optionvalue}>{option.optionlabel}</option>
              ))
            }
          </select>
        </div>
        )
        break;
      case "Customer Status":
        currentJSX = (<div key={key++} className={cellClassName}>
          <label key={key++} htmlFor={datakey} className="form-label">{datakey}</label>
          <select key={key++} id={datakey} name={columnObj.columnName} className="form-select" aria-label="Status"
            onChange={handleChange} value={rowdata[datakey]}
            title={`${readOnlyMode ? 'Display Only attribute' : ''}`}
            disabled={readOnlyMode}>
            {
              columnObj.columnListofValues.map((option: any, idx: any) => (
                <option key={key++} value={option.optionvalue}>{option.optionlabel}</option>
              ))
            }
          </select>
        </div>
        )
        break;
      case "addauto":
        currentJSX = readOnlyMode ? (
          <div>
            <label key={key++} htmlFor={datakey} className="form-label">{datakey}</label>
            <input key={key++} ref={cityRef} type="text" id={datakey} name={columnObj.columnName} className="form-control" value={rowdata[datakey]} readOnly />
          </div>
        ) : (
          <AddressInput inputRef={streetRef}
            inputId={datakey}
            inputLabel={datakey}
            currentvalue={rowdata[datakey]}
            handleUpdate={handleAddressChange}
            handleStreetChange={handleStreetChange} />
        )
        break;
      case "addcity":
        currentJSX = (<div key={key++} className={cellClassName}>
          <label key={key++} htmlFor={datakey} className="form-label">{datakey}</label>
          <input key={key++} ref={cityRef} type="text" id={datakey} name={columnObj.columnName}
            className="form-control" onChange={handleChange} value={rowdata[datakey]}
            readOnly={readOnlyMode} />
        </div>
        )
        break;
      case "addstate":
        currentJSX = (<div key={key++} className={cellClassName}>
          <label key={key++} htmlFor={datakey} className="form-label">{datakey}</label>
          <input key={key++} ref={stateRef} type="text" id={datakey} name={columnObj.columnName} className="form-control"
            onChange={handleChange} value={rowdata[datakey]}
            readOnly={readOnlyMode} />
        </div>
        )
        break;
      case "addzipc":
        currentJSX = (<div key={key++} className={cellClassName}>
          <label key={key++} htmlFor={datakey} className="form-label">{datakey}</label>
          <input key={key++} ref={zipcRef} type="text" id={datakey} name={columnObj.columnName}
            className="form-control" onChange={handleChange} value={rowdata[datakey]}
            readOnly={readOnlyMode} />
        </div>
        )
        break;
      case "addcountry":
        currentJSX = (<div key={key++} className={cellClassName}>
          <label key={key++} htmlFor={datakey} className="form-label">{datakey}</label>
          <input key={key++} ref={countryRef} type="text" id={datakey} name={columnObj.columnName} className="form-control"
            onChange={handleChange} value={rowdata[datakey]}
            readOnly={readOnlyMode} />
        </div>
        )
        break;
      default:
        if (columnObj.columnDisplayType.startsWith("list")) {
          console.log("Column ", datakey, " Value is ", rowdata[datakey]);
          currentJSX = (<div key={key++} className={cellClassName}>
            <label key={key++} htmlFor={datakey} className="form-label">{datakey}</label>
            <select key={key++} id={datakey} name={columnObj.columnName} className="form-select"
              aria-label={datakey} onChange={handleChange}
              value={rowdata[datakey]}
              disabled={readOnlyMode} >
              <option key={key++} value=''>Please select...</option>
              {
                columnObj.columnListofValues.map((option: any, idx: any) => (
                  <option key={key++} value={option.optionvalue}>{option.optionlabel}</option>
                ))
              }
            </select>
          </div>
          )
        } else if (columnObj.columnDisplayType.startsWith("llist")) {
          if ((rowAction !== 'new') && rowdata[datakey]) {
            currentJSX = (<div key={key++} className={cellClassName}>
              <label key={key++} htmlFor={datakey} className="form-label">{datakey}</label>
              <input key={key++} readOnly type="text" id={datakey} name={columnObj.columnName} title="You cannot edit this value" className="form-control" value={rowdata[datakey]} />
            </div>
            )
            break;
          } else {
            if (columnObj.columnDisplayType.includes("customer")) {
              currentJSX = (<div key={key++} className={cellClassName}>
                <label key={key++} htmlFor={datakey} className="form-label">{datakey}</label>
                <LargeFilterList inputRef={custInputRef} currApplication={currApplication} fieldname={datakey} listname="customer" initialValue={custInputValue} depRefs={[]} parentBlur={handleInputBlur} />
                {/*<LListAutocomplete inputRef={custInputRef} currApplication={currApplication} fieldname={datakey} listname="customer" initialValue={custInputValue} depRefs={[]} parentBlur={handleInputBlur} /> */}
              </div>)
            } else if (columnObj.columnDisplayType.includes("location")) {
              currentJSX = (<div key={key++} className={cellClassName}>
                <label key={key++} htmlFor={datakey} className="form-label">{datakey}</label>
                <LargeFilterList inputRef={locaInputRef} currApplication={currApplication} fieldname={datakey} listname="location" initialValue={locaInputValue} depRefs={[custInputRef]} parentBlur={handleInputBlur} />
              </div>)
            } else if (columnObj.columnDisplayType.includes("custloca")) {
              currentJSX = (<div key={key++} className={cellClassName}>
                <label key={key++} htmlFor={datakey} className="form-label">{datakey}</label>
                <LargeFilterList inputRef={locaInputRef} currApplication={currApplication} fieldname={datakey} listname="custloca" initialValue={locaInputValue} depRefs={[custInputRef]} parentBlur={handleInputBlur} />
              </div>)
            } else if (columnObj.columnDisplayType.includes("account")) {
              currentJSX = (<div key={key++} className={cellClassName}>
                <label key={key++} htmlFor={datakey} className="form-label">{datakey}</label>
                <LargeFilterList inputRef={locaInputRef} currApplication={currApplication} fieldname={datakey} listname="account" initialValue={acctInputValue} depRefs={[custInputRef]} parentBlur={handleInputBlur} />
              </div>)
            } else {
              currentJSX = (<div key={key++} className={cellClassName}>
                <label key={key++} htmlFor={datakey} className="form-label">{datakey}</label>
                <input key={key++} type="text" id={datakey} name={columnObj.columnName} className="form-control" onChange={handleChange} value={rowdata[datakey]} />
              </div>
              )
            }
          }
        } else if (isValidDateOrTimestamp(rowdata[datakey])) {
          console.log("Value ", rowdata[datakey], " is valid date or timestamp", isValidDateOrTimestamp(rowdata[datakey]));
          currentJSX = (<div key={key++} className={cellClassName}>
            <label key={key++} htmlFor={datakey} className="form-label">{datakey}</label>
            <input key={key++} type="datetime-local" id={datakey} name={columnObj.columnName}
              className="form-control" onChange={handleChange} value={rowdata[datakey]}
              readOnly={readOnlyMode} />
          </div>
          )
        } else if (isValidDate(rowdata[datakey])) {
          currentJSX = (<div key={key++} className={cellClassName}>
            <label key={key++} htmlFor={datakey} className="form-label">{datakey}</label>
            <input key={key++} type="date" id={datakey} name={columnObj.columnName}
              className="form-control" onChange={handleChange} value={rowdata[datakey]}
              readOnly={readOnlyMode} />
          </div>
          )
        } else {
          currentJSX = (<div key={key++} className={cellClassName}>
            <label key={key++} htmlFor={datakey} className="form-label">{datakey}</label>
            <input key={key++} type="text" id={datakey} name={columnObj.columnName} className="form-control"
              onChange={handleChange} value={rowdata[datakey]}
              readOnly={readOnlyMode} />
          </div>
          )
        }
    }
    return currentJSX;
  }


  const renderSegments = (columns: Record<string, any>) => {

    const sortedColumns = Object.entries(columns).sort((a, b) => a[1].columnIndex - b[1].columnIndex);
    const rowdata = isEmptyObj(dataChanges) ? formData.data[currentrow - 1] : { ...formData.data[currentrow - 1], ...dataChanges };

    const tabs: { title: string, content: JSX.Element[] }[] = [];
    let currentTabContent: JSX.Element[] = [];
    let currentTabTitle = '';
    let inTab = false;
    let inSection = false;
    let sectionName = '';

    const divRows: JSX.Element[] = [];
    let currentDivRow: JSX.Element[] = [];
    let currentDivRowColumns = 0;


    sortedColumns.filter(([keylabel]) => ((keylabel !== 'rownumber_meta') &&
      (!keylabel.endsWith("_hidden")))).forEach(([key, column], index) => {
        if (column.columnLabel.endsWith("_divider")) {
          divRows.push(<div key={`row-${index}`} className="p-1 bg-secondary w-100"></div>);
        } else if (column.columnDisplayType === 'tabstart_style') {
          if (inTab) {
            tabs.push({ title: currentTabTitle, content: [...divRows, ...currentTabContent] });
            currentTabContent = [];
            divRows.length = 0; // Clear rows for the next tab
          }
          inTab = true;
          currentTabTitle = column.columnLabel;
        } else if (column.columnDisplayType === 'tabend_style') {
          if (currentDivRow.length > 0) {
            currentTabContent.push(<div key={`row-${index}`} className="row">{currentDivRow}</div>);
            currentDivRow = [];
          }
          tabs.push({ title: currentTabTitle, content: [...divRows, ...currentTabContent] });
          inTab = false;
          currentTabContent = [];
          currentTabTitle = '';
          divRows.length = 0; // Clear rows after tab ends
        } else if (column.columnDisplayType === 'groupstart_style') {
          if (currentDivRow.length > 0) {
            divRows.push(<div key={`row-${index}`} className="row">{currentDivRow}</div>);
            currentDivRow = [];
          }
          inSection = true;
          sectionName = column.columnLabel;
          divRows.push(<div key={`section-start-${index}`} className="mb-3"><h5>{sectionName}</h5></div>);
        } else if (column.columnDisplayType === 'groupend_style') {
          if (currentDivRow.length > 0) {
            divRows.push(<div key={`row-${index}`} className="row">{currentDivRow}</div>);
            currentDivRow = [];
          }
          inSection = false;
          sectionName = '';
        } else if (column.columnLabel.startsWith('row(')) {
          if (currentDivRow.length > 0) {
            divRows.push(<div key={`row-${index}`} className="row">{currentDivRow}</div>);
            currentDivRow = [];
          }
          const rowColumns = extractRowValue(column.columnLabel) ?? 1;
          if (!extractRowValue(column.columnLabel)) {
            console.log("Expression returned zeror column for the rowlabel ", column.columnLabel);
          }
          currentDivRowColumns = rowColumns;
        } else {
          let element: JSX.Element = <></>;
          if ((column.columnDisplayType === "meta") ||
            (column.columnDisplayType === "key") ||
            (column.columnDisplayType === "hidden")) {
            element = renderElement(column, rowdata);
          } else {
            element = (
              <div key={index} className={`col-${12 / (currentDivRowColumns || 2)}`}>
                {renderElement(column, rowdata)}
              </div>
            );
          }
          currentDivRow.push(element);
        }

        if (currentDivRow.length === currentDivRowColumns) {
          divRows.push(<div key={`row-${index}`} className="row">{currentDivRow}</div>);
          currentDivRow = [];
          currentDivRowColumns = 0;
        }
      });

    if (currentDivRow.length > 0) {
      divRows.push(<div key={`row-end-${sortedColumns.length}`} className="row">{currentDivRow}</div>);
    }

    if (inTab) {
      tabs.push({ title: currentTabTitle, content: [...divRows, ...currentTabContent] });
    } else {
      divRows.push(...currentTabContent);
    }

    if (tabs.length > 0) {
      return (
        <nav>
          <div className="nav nav-tabs mb-2" id="nav-tab" role="tablist">
            {tabs.map((tab, index) => (
              <button
                type="button"
                className={`nav-link ${index === activeTab ? 'active' : ''} custom-tab`}
                onClick={(event) => { event.preventDefault(); setActiveTab(index) }}
              >
                {tab.title}
              </button>
            ))}
          </div>
          <div className="tab-content mt-3">
            {tabs.map((tab, tabIndex) => (
              <div
                key={tabIndex}
                className={`tab-pane fade ${tabIndex === activeTab ? 'show active' : ''}`}
              >
                {tab.content}
              </div>
            ))}
          </div>
        </nav>
      );
    }

    return divRows;
  };

  const getDocRequestCode = () => {
    if (docpagelist.indexOf(pagename) !== -1) {
      switch (pagename) {
        case 'billercustomers': return 'customer';
        case 'billerservlocations': return 'location';
        case 'billerprograms': return 'program';
        case 'lienmanagement': return 'lien';
        case 'billerledger': return 'program';
        default: return '';
      }
    } else {
      return '';
    }
  }

  if (formData.hasOwnProperty("pagemeta") &&
    (Object.keys(formData.pagemeta.columntypes).length > 0)) {
    return (
      <div className="d-flex row">
        <form className="row" id={pagename + '_form'} onSubmit={handleSubmit} onReset={handleReset}>
          {renderSegments(formData.pagemeta.columntypes)}
          <div className="row m-2">
            <div className="d-flex justify-content-end">
              {(pageactions === 'Q') ? 
                <button key={key++} type="reset" className="btn btn-outline-dark" onClick={handleCancel} >
                  Close
                </button>
                : <>
                  {(pageactions !== 'M') &&
                    <OverlayTrigger
                      key={key++}
                      placement="top"
                      overlay={renderTooltip('Add new record')}>
                      <button key={key++} type="button" name="new" style={{ backgroundColor: Theme.buttonColor }} className="btn btn-success me-2" onClick={(handleButtonAction)}>
                        <BIcon iconName="PlusSquare" size={24} />
                      </button>
                    </OverlayTrigger>}
                  {(pageactions !== 'M') &&
                    <OverlayTrigger
                      key={key++}
                      placement="top"
                      overlay={renderTooltip('Copy existing values')}>
                      <button key={key++} type="button" name="copy" style={{ backgroundColor: Theme.buttonColor }} className="btn btn-success me-2" onClick={(handleButtonAction)}>
                        <BIcon iconName="CSquare" size={24} />
                      </button>
                    </OverlayTrigger>}
                  <OverlayTrigger
                    key={key++}
                    placement="top"
                    overlay={renderTooltip('save data changes')}>
                    <button key={key++} type="submit" name="save" style={{ backgroundColor: Theme.buttonColor }} 
                    className="btn btn-success me-2" onClick={(handleButtonAction)}>
                      <BIcon iconName="Safe" size={24} />
                    </button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={key++}
                    placement="top"
                    overlay={renderTooltip('close this window')}>
                    <button key={key++} name="reset" type="reset" className="btn btn-outline-dark me-2" onClick={handleCancel} >
                      <BIcon iconName="XSquare" size={24} />
                    </button>
                  </OverlayTrigger>
                  {(getDocRequestCode()) &&
                    <OverlayTrigger
                      key={key++}
                      placement="top"
                      overlay={renderTooltip('Show related documents')}>
                      <button key={key++} type="button" className="btn btn-outline-dark me-2" 
                       name="showdocuments" style={{ backgroundColor: Theme.buttonColor }} 
                       onClick={(handleButtonAction)}>
                        <BIcon iconName="FiletypeDoc" size={24} />
                      </button>
                    </OverlayTrigger>}
                </>
              }
            </div>
          </div>
        </form>
        {(formMessage) && <AlertModal alerttitle="Success" msgobj={formMessage} display={true} />}
        {(showDocuments) && <DocumentList requesttype={getDocRequestCode()} 
          requestcode={formData.data[currentrow - 1][docpageIdList[docpagelist.indexOf(pagename)]]} show={showDocuments} onClose={() => setShowDocuments(false)} /> }
      </div>

    )
  } else {
    return (
      <div className="text-center">
        <div className="spinner-grow text-success" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow text-danger" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow text-warning" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow text-info" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  }
}


export default SegFormLayout;