import React, { useContext, useEffect, useRef, useState } from 'react';
import { OverlayTrigger, ProgressBar, Tooltip } from 'react-bootstrap';
import { Column, useSortBy, useTable } from 'react-table';
import * as XLSX from 'xlsx';
import { useTheme } from '../Template/ThemeContext';
import AppContext from '../context/AppContext';
import { formatDate, getStartingNumber, isEmptyObj, sortObjectKeys } from '../context/MiscFunc';
import { hpost } from '../context/apiService';
import AlertModal from './AlertModal';
import { BIcon } from './BIcon';
import DocumentList from './DocumentList';
import LargeFilterList from './LargeFilterList';


interface TableRow {
  [key: string]: string | number;
}

interface DataProps {
  pagename: string;
  currApplication: Record<string, string> | null;
}

interface BaseContentProps {
  templateName?: string;
  dataObj?: any;
  currentError?: string;
  pageTitle?: string;
  pageActions?: string;
  // tableheadercount?: number;
}

const SimpleLedgerTable: React.FC<DataProps> = ({ pagename, currApplication }) => {
  const [contentProps, setContentProps] = useState<BaseContentProps>({});
  const [tableData, setTableData] = useState<TableRow[] | undefined>([]);
  const [error, setError] = useState<string>('');
  const [selectedDaysRange, setSelectedDaysRange] = useState<string>('365');
  const [columnTypes, setColumnTypes] = useState<any>([]);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [showDocuments, setShowDocuments] = useState(false);
  const Theme = useTheme();
  const { sessionData } = useContext(AppContext);
  const [hoveredRowIndex, setHoveredRowIndex] = React.useState<number | null>(null);
  const [progress, setProgress] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [startBalance, setStartBalance] = useState<string>('');
  const buttonRef = useRef<HTMLButtonElement>(null);
  const custInputRef = useRef<HTMLInputElement>(null);
  const [custInputValue, setCustInputValue] = useState<string>('');
  const locaInputRef = useRef<HTMLInputElement>(null);
  const [locaInputValue, setLocaInputValue] = useState<string>('');
  let r = 1;

  // const [isModalOpen, setIsModalOpen] = useState(false);

  const tableStyles: React.CSSProperties & Record<string, string> = {
    'background-color': Theme.backgroundColor,
    '--text-color': Theme.primaryColor,
    '--container-width': Theme.containerWidth,
    '--border-style': Theme.containerBorderStyle,
    '--border-width': Theme.containerBorderWidth,
    '--border-color': Theme.containerBorderColor,
  };
  useEffect(() => {
    console.log("Page Name is ", pagename);
  }, [pagename])

  const renderTooltip = (tooltipText: string) => (
    <Tooltip id="button-tooltip">
      {tooltipText}
    </Tooltip>
  );

  const displayMessage = (message: string, timeout: number) => {
    setError(message);
    setTimeout(() => {
      setError('');
    }, timeout)
  }
  const fetchData = async (params: any) => {
    let response = await hpost('/sec/data', params);
    if (response.data) {
      let pagetemplate = JSON.parse(JSON.stringify(response.data));

      setContentProps({
        dataObj: response.data,
        templateName: (isEmptyObj(pagetemplate.pagemeta) ? 'LedgerScreen' : pagetemplate.pagemeta.templateName),
        pageTitle: pagetemplate.pagemeta.page_title,
        pageActions: pagetemplate.pagemeta.pageactions,
      });
      console.log("Response: ", response.data);
      if (pagetemplate.hasOwnProperty("data")) {
        setTableData(pagetemplate.data as TableRow[]);
        console.log("Ledger Screen: ", pagetemplate.data[0]);
        let earliestAvailableDate = pagetemplate.data[0]['Transaction Date']
        let earliestBalance = pagetemplate.data[0].starting_balance;
        console.log("Earliest Date ", earliestAvailableDate);
        setStartBalance(earliestBalance);
        setStartDate(earliestAvailableDate);
      } else {
        setTableData([]);
        displayMessage('No Data Found', 3000);
        setStartBalance('');
        setStartDate('');
      }
      if (pagetemplate.hasOwnProperty("pagemeta")) {
        setColumnTypes(pagetemplate.pagemeta.columntypes);
      }

      setDataLoaded(true);
    } else if (response.error) {
      console.error("Error: ", response.error);
      // setError(response.error);
      setTableData([]);
      setStartBalance('');
    };
  }

  const calculateStartDate = (numberdays: number) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - numberdays);
    const formattedDate = formatDate(currentDate);
    console.log("Formatted Start Date is ", formattedDate)
    setStartDate(formattedDate);
  };



  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    let newvalue = e.target.value;
    switch (newvalue) {
      case '365': calculateStartDate(365); break;
      case '730': calculateStartDate(730); break;
      case '1000': calculateStartDate(1000); break;
      default: setStartDate('');

    }
    setSelectedDaysRange(newvalue);
  };

  useEffect(() => {
    if ((buttonRef.current) && (custInputValue)) {
      buttonRef.current.click();
    }
  }, [selectedDaysRange])


  const performSearch = (event: any) => {
    event.preventDefault()
    setError('');
    // Create a FormData object from the form element
    const formData = new FormData(event.target);
    // Append form data from state
    formData.append('biller_id', sessionData.data["Biller Id"]);
    const currentAppId = currApplication?.id ?? '0';
    formData.append('application_id', currentAppId);
    formData.append('pagename', pagename);
    formData.append('objecttype', "pageobject");
    let customer_code_value = '';
    if (custInputRef.current) {
      setCustInputValue(custInputRef.current.value);
      customer_code_value = getStartingNumber(custInputRef.current.value);
    }
    if (customer_code_value === '') {
      console.log("Customer Code is blank");
      displayMessage("Customer Code is required", 3000);
      return;
    } else {
      formData.set("customer_code", customer_code_value)
    }
    let location_code_value = '';
    if (locaInputRef.current) {
      setLocaInputValue(locaInputRef.current.value);
      location_code_value = getStartingNumber(locaInputRef.current.value);
    }
    if (location_code_value === '') {
      console.log("Location Code is blank");
      displayMessage("Location Code is not valid for the given customer", 2000);
      return;
    } else {
      formData.set("location_code", location_code_value)
    }


    // Convert the FormData object to a plain object
    const formObject = Object.fromEntries(formData.entries());

    // Convert the plain object to a JSON string
    const jsonString = JSON.stringify(formObject);
    fetchData(formObject);

    // Log the JSON string to the console (or do something else with it)
    console.log(jsonString);
  }
  const resetSearchClick = (event: any) => {
    console.log("EVENT: ", event.target.id);
    event.preventDefault();
    setCustInputValue('');
    setLocaInputValue('');
    setTableData([]);
    setStartBalance('');
    setStartDate('');
  }


  const columns: Column<TableRow>[] = React.useMemo(
    () => {
      return sortObjectKeys(columnTypes).filter((colkey) => {
        return ((columnTypes[colkey].columnDisplayType === 'text')
          || (columnTypes[colkey].columnDisplayType === 'unique')
          || (columnTypes[colkey].columnDisplayType === 'amount')
          || (columnTypes[colkey].columnDisplayType.startsWith('list'))
          || (columnTypes[colkey].columnDisplayType.startsWith('num')));
      }).map(key => {
        let columnType = columnTypes[key];
        let column: Column<TableRow> =
        {
          Header: key,
          accessor: key,
          Cell: ({ value }: any) => {
            let numericValue = typeof value === 'number' ? value : parseFloat(value);
            if (columnType.columnDisplayType === 'amount') {
              return numericValue.toFixed(2);
            } else if (columnType.columnDisplayType.startsWith('num')) {
              const decimalPlaces = key.length > 3 ? parseInt(key.slice(3), 10) : 0;
              return numericValue.toFixed(decimalPlaces);
            }
            return value;
          },
        };
        console.log("Mapped Column is  ", key);
        if (columnType.columnDisplayType === 'amount' || columnType.columnDisplayType.startsWith('num')) {
          column.Cell = ({ value }: any) => {
            let numericValue = typeof value === 'number' ? value : parseFloat(value);
            const decimalPlaces = columnType.columnDisplayType.startsWith('num') && columnType.columnDisplayType.length > 3 ? parseInt(columnType.columnDisplayType.slice(3), 10) : 2;
            return (
              <div style={{ textAlign: 'right' }}>
                {numericValue.toFixed(decimalPlaces)}
              </div>
            );
          };
          column.Header = () => (
            <div style={{ textAlign: 'right' }}>
              {columnType.columnLabel}
            </div>
          );
        } else {
          column.Header = () => (
            <div style={{ textAlign: 'left' }}>
              {columnType.columnLabel}
            </div>
          );
        }

        return column;
      }
      )
    },
    [columnTypes]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,

  } = useTable<TableRow>(
    {
      columns,
      data: (tableData === undefined ? [] : tableData),
      initialState: {
        sortBy: [
          {
            id: 'Transaction Date',
            desc: true,
          },
        ],
      },
    },
    useSortBy
  );

  const handleRowClick = (row: TableRow) => {
    console.log("handle Row Click");
    console.log(" Selected Row " + JSON.stringify(row));
  };

  const handleMouseEnter = (rowIndex: number) => {
    setHoveredRowIndex(rowIndex);
  };
  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };

  // Function to convert table data to Excel
  const exportToExcel = (data: any[], fileName: string) => {
    // Extract column order from headerGroups
    const columnOrder = headerGroups[0].headers.map(header => header.id);

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([], { header: columnOrder });

    // Simulate processing large data with setTimeout and update progress
    let chunkSize = 1000;
    let currentIndex = 0;

    const processChunk = () => {
      const chunk = data.slice(currentIndex, currentIndex + chunkSize);
      XLSX.utils.sheet_add_json(ws, chunk, { origin: -1, skipHeader: true });
      currentIndex += chunkSize;
      setProgress(Math.min((currentIndex / data.length) * 100, 100));

      if (currentIndex < data.length) {
        setTimeout(processChunk, 0);
      } else {
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, `${fileName}.xlsx`);
        setProgress(0); // Reset progress after completion
      }
    };

    processChunk();
  };

  const getTableDataAsJson = () => {
    return rows.map((row) => {
      prepareRow(row);
      return row.original;
    });
  };
  const handleExport = () => {
    const xlsData = getTableDataAsJson()
    exportToExcel(xlsData, 'table_data');
  };

  const handleDocumentsShow = () => {
    if (!custInputRef.current) {
      displayMessage("No data selected", 1000);
      setShowDocuments(false);
    } else {
      setShowDocuments(true);
    }
  }

  const handleInputBlur = (ref: React.RefObject<HTMLInputElement>) => {
    if (ref.current) {
      console.log("New Ref Value for ", ref.current.name, " is set to ", ref.current.value)
    }
  };

  if (!dataLoaded || Object.keys(columnTypes).length <= 0) {
    return (
      <div className='d-flex' >
        {/*<div><h2>{error}</h2></div> */}
        <form id="ledgersearchform" name="ledgersearchform" className="row g-3 w-100" onSubmit={performSearch} onReset={resetSearchClick}>
          <div className="d-flex col-lg-6">
            <label key={r++} htmlFor="customer_code" className="form-label">Customer: </label>
            <LargeFilterList inputRef={custInputRef} currApplication={currApplication} fieldname={"Customer"} listname="customer" initialValue={custInputValue} depRefs={[]} parentBlur={handleInputBlur} />
          </div>
          <div className="d-flex col-lg-6">
            <label key={r++} htmlFor="location_code" className="form-label">Location: </label>
            <LargeFilterList inputRef={locaInputRef} currApplication={currApplication} fieldname={"Location"} listname="custloca" initialValue={locaInputValue} depRefs={[custInputRef]} parentBlur={handleInputBlur} />
          </div>
          <div className="d-flex w-100">
            <div className="w-30" >
              <div className="d-flex">
                <label key={r++} htmlFor="num_days" className="w-75 ms-auto">Starting from: </label>
                <select key={r++} id="num_days" name="num_days" className="form-select" aria-label="Number of Days" value={selectedDaysRange} onChange={handleChange} >
                  <option value="A">All</option>
                  <option value="365">last 365 Days</option>
                  <option value="730">last 730 Days</option>
                  <option value="1000">last 1000 Days</option>
                </select>
              </div>
            </div>
            {/*<button type="reset" className="ms-auto p-2 mb-2" style={{ backgroundColor: '#0397B1', marginLeft: "10px" }}>Reset</button>
            <button type="submit" ref={buttonRef} className="p-2 mb-2" style={{ backgroundColor: '#0397B1', marginLeft: "10px" }}>Get Ledger</button> */}
            <OverlayTrigger
              key={r++}
              placement="top"
              overlay={renderTooltip('Clear Data')}>
              <button key={r++} name="reset" type="reset" className="btn btn-outline-dark ms-auto"  >
                <BIcon iconName="XSquare" size={24} />
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              key={r++}
              placement="top"
              overlay={renderTooltip('Query Data')}>
              <button key={r++} type="submit" ref={buttonRef} name="submit"
                style={{ backgroundColor: Theme.buttonColor }}
                className="btn btn-success ms-2">
                <BIcon iconName="FileEarmarkRuled" size={24} />
              </button>
            </OverlayTrigger>
          </div>
        </form>
        {(error) && <AlertModal alerttitle="Error" msgobj={error} display={true} />}
      </div >)
  } else {
    return (
      <div>
        <div className='d-flex' >
          {/*<div><h2>{error}</h2></div> */}
          <form id="ledgersearchform" name="ledgersearchform" className="row g-3 w-100" onSubmit={performSearch} onReset={resetSearchClick}>
            <div className="d-flex col-lg-6">
              <label key={r++} htmlFor="customer_code" className="form-label">Customer: </label>
              <LargeFilterList inputRef={custInputRef} currApplication={currApplication} fieldname={"Customer"} listname="customer" initialValue={custInputValue} depRefs={[]} parentBlur={handleInputBlur} />
            </div>
            <div className="d-flex col-lg-6">
              <label key={r++} htmlFor="location_code" className="form-label">Location: </label>
              <LargeFilterList inputRef={locaInputRef} currApplication={currApplication} fieldname={"Location"} listname="custloca" initialValue={locaInputValue} depRefs={[custInputRef]} parentBlur={handleInputBlur} />
            </div>
            <div className="d-flex w-100">
              <div className="w-30" >
                <div className="d-flex">
                  <label key={r++} htmlFor="num_days" className="w-75 ms-auto">Starting from: </label>
                  <select key={r++} id="num_days" name="num_days" className="form-select" aria-label="Number of Days" value={selectedDaysRange} onChange={handleChange} >
                    <option value="A">All</option>
                    <option value="365">last 365 Days</option>
                    <option value="730">last 730 Days</option>
                    <option value="1000">last 1000 Days</option>
                  </select>
                </div>
              </div>
              {/* <button type="reset" className="ms-auto p-2 mb-2" style={{ backgroundColor: '#0397B1', marginLeft: "10px" }}>Reset</button>
              <button type="submit" ref={buttonRef} className="p-2 mb-2" style={{ backgroundColor: '#0397B1', marginLeft: "10px" }}>Get Ledger</button> */}
              <OverlayTrigger
                key={r++}
                placement="top"
                overlay={renderTooltip('Clear Data')}>
                <button key={r++} name="reset" type="reset" className="btn btn-outline-dark ms-auto"  >
                  <BIcon iconName="XSquare" size={24} />
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                key={r++}
                placement="top"
                overlay={renderTooltip('Query Data')}>
                <button key={r++} type="submit" ref={buttonRef} name="submit"
                  style={{ backgroundColor: Theme.buttonColor }}
                  className="btn btn-success ms-2">
                  <BIcon iconName="FileEarmarkRuled" size={24} />
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                key={r++}
                placement="top"
                overlay={renderTooltip('Show related documents')}>
                <button key={r++} type="button" className="btn btn-outline-dark ms-2"
                  onClick={handleDocumentsShow}
                  name="showdocuments" style={{ backgroundColor: Theme.buttonColor }} >
                  <BIcon iconName="FiletypeDoc" size={24} />
                </button>
              </OverlayTrigger>


            </div>
          </form>
          {(error) && <AlertModal alerttitle="Error" msgobj={error} display={true} />}
          {(showDocuments) && (custInputRef.current) && <DocumentList requesttype={'customer'}
            requestcode={getStartingNumber(custInputRef.current.value)} show={showDocuments} onClose={() => setShowDocuments(false)} />}
        </div >


        <div className='d-flex'>
          <div className="ms-2 mt-2 flex-grow-1">
            <div className="d-flex">
              <div className="ms-auto"><h4>Total Rows: {rows.length}</h4></div>
              <OverlayTrigger
                key={r++}
                placement="top"
                overlay={renderTooltip('Export Data to Excel')}>
                <button onClick={handleExport}
                  className="btn btn-outline-dark ms-2"
                  name="exportxlsx" style={{ backgroundColor: Theme.buttonColor }} >
                  <BIcon iconName="FiletypeXlsx" size={24} />
                </button>
              </OverlayTrigger>
              {progress > 0 && (
                <ProgressBar now={progress} label={`${progress}%`} animated striped />
              )}
            </div>
          </div>
        </div>


        <div style={{
          overflowX: 'auto', maxHeight: '600px', minWidth: '100%',
          borderTop: '1px solid #ddd',
          borderBottom: '1px solid #ddd',
          borderLeft: '1px solid #ddd',
          borderRight: '1px solid #ddd',
          marginTop: '10px',
        }}>
          <table id={pagename + "_table"} {...getTableProps()} className="w-100">
            <thead style={{ position: 'sticky', top: '-1px', zIndex: 1, 
              backgroundColor: Theme.topbarBackgroundColor 
              }}>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.slice(0, 5).map((column, index) => (
                    <th 
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, rowIndex) => {
                prepareRow(row);
                const isRowHovered = rowIndex === hoveredRowIndex;
                const rowStyles: React.CSSProperties = {
                  backgroundColor: isRowHovered ? Theme.topbarBackgroundColor : Theme.backgroundColor, // Apply background color on hover
                  color: isRowHovered ? Theme.topbarColor : rowIndex % 2 === 0 ? Theme.navLinkColor : Theme.primaryColor,
                  cursor: 'pointer'
                };
                return (
                  <tr
                    {...row.getRowProps()}
                    onMouseEnter={() => handleMouseEnter(rowIndex)}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => handleRowClick(row.original)}
                    style={rowStyles}>
                    {row.cells.slice(0, 5).map((cell, cellIndex) => {
                      const accessor = cell.column.id;
                      const cellcol = columnTypes[accessor];
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            textAlign: cellcol.columnDisplayType === 'amount' || cellcol.columnDisplayType.startsWith('num') ? 'right' : 'left',
                          }}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="d-flex w-100">
          <label key={r++} htmlFor="starting_balance" className="form-label ms-auto">Beginning Balance (as of {startDate}): </label>
          <input type="text" id="starting_balance" className="p-2 ms-auto form-input" value={startBalance} readOnly />
        </div>
      </div >
    );
  }
}
export default SimpleLedgerTable;
