// src/components/MultiLevelMenuItem.tsx
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { MultiLevelMenuItem as MultiLevelMenuItemType } from '../types/menu-item';

interface MultiLevelMenuItemProps {
  originalItem: MultiLevelMenuItemType;
  index: number;
  isMenuToggled:boolean;
  toggleMenu:  Dispatch<SetStateAction<boolean>>
  selectedNavItem: String;
  handleNavigationItemClick: (e: any, navItem: string) => void;
  setExpandedItem? : (e : any) => void;
}

const RenderMenuItem: React.FC<MultiLevelMenuItemProps> = ({
  originalItem,
  index,
  isMenuToggled,
  selectedNavItem,
  handleNavigationItemClick, toggleMenu,
  setExpandedItem

}) => {
  const [item, setItem] = useState<MultiLevelMenuItemType | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  console.log(" Rendering Original Item ", originalItem.option_id, originalItem.position, originalItem.prompt);
  useEffect(() => {
    setItem(originalItem);
  }, [originalItem]);

  const toggleExpandedMode = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {   
    console.log("Selected Menu Item: ", item)
    setIsExpanded(!isExpanded);   
    toggleMenu(true)
  }

  const dropdownItemStyle = {
    margin: '1px 1rem',
    padding: '2px',
    color: "#9D9FA4",    
  };

  if (item === null) {
    return <div>Loading...</div>;
  } else {
    if (item.value === "_divider_") {
      return (<Dropdown.Divider />)
    } else if (item.node_type === "Leaf") {
      return (item.status === 'A'?
        <div style= {dropdownItemStyle} className={selectedNavItem === item.value ? 'nav-item active' : 'nav-item'} onClick={(e) => handleNavigationItemClick(e, item.value)}>
          <div className="nav-link d-flex align-items-center" style={{gap:"3px"}}>
            <i className={item.icon}></i>
            {isMenuToggled && <span className='fs-5'>{item.prompt}</span>}
          </div>            
        </div>
        :<></>
      )
    } else {
      return (item.status === 'A') ? 
        <div style={dropdownItemStyle}>          
        <div className='nav-item'  onClick= {(event) => {toggleExpandedMode(event)}}>
          <div className="nav-link d-flex align-items-center" style={{gap:"3px"}}>            
            <i className={item.icon}></i>          
            {isMenuToggled && <span className='fs-5'>{item.prompt}</span>}
          </div>
        </div>
    
        {isMenuToggled && <Dropdown.Menu show={isExpanded}>
          {
             item.children?.map((child: any, idx: number) => {
              return (
                <RenderMenuItem
                  key={child.option_id}
                  originalItem={child}
                  index={idx}
                  selectedNavItem={selectedNavItem}
                  handleNavigationItemClick={handleNavigationItemClick}
                  isMenuToggled={isMenuToggled}
                  toggleMenu = {toggleMenu}
                />)
            })
          }
          </Dropdown.Menu>}
          </div>: <></>
    }
  }
}

  export default RenderMenuItem;
