import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';

export interface ThemeProps {
  themeType: string;
  primaryColor: string;
  secondaryColor: string;
  backgroundColor: string;
  topbarColor: string;
  topbarBackgroundColor: string;
  topbarHeadingColor: string;
  tabFontColor: string;
  tabInactiveFontColor: string;
  tabFontSize: string;
  searchbarBackgroundColor: string;
  buttonColor: string;
  navLinkColor: string;
  containerWidth: string;
  containerBorderStyle: string;
  containerBorderWidth: string;
  containerBorderColor: string;
  footerColor: string;
  footerBackgroundColor : string;
  customFontFamily : string;
}
type ThemeContextType = ThemeProps & {
  setProperty: (newValues: Partial<ThemeProps>) => void;
  setKeyProperty: (refkey: keyof ThemeProps, newvalue :string) => void;
  setInitTheme: (selectedtheme: string) => void
};
const initialTheme: ThemeProps = {
  themeType: 'Light',
  primaryColor: '#1F2837',
  secondaryColor: '#F8FAFC',
  backgroundColor: '#ffffff',
  topbarColor: '#F8FAFC',
  topbarBackgroundColor: '#00bb00',
  topbarHeadingColor: '#0397B1',
  tabFontColor: '#0397B1',
  tabInactiveFontColor: '#000000',
  tabFontSize: '24px',    
  searchbarBackgroundColor: '#DDE1E7',
  buttonColor: '#0397B1',
  containerWidth: 'none',
  containerBorderStyle: 'none',
  containerBorderWidth: 'none',
  containerBorderColor: 'none',
  navLinkColor: '#ff0101',
  footerColor: '#1F2837',
  footerBackgroundColor: '#F8FAFC',
  customFontFamily :"Roboto",
};

const ThemeContext = createContext<ThemeContextType>({} as ThemeContextType);


type ThemeProviderProps = {
  children: ReactNode;
};


const ThemeProvider: React.FC<ThemeProviderProps> = ({children }) => {
  

  const initialThemeLight: ThemeProps = {
    themeType: 'Light',
    primaryColor: '#1F2837',
    secondaryColor: '#F8FAFC',
    backgroundColor: '#ffffff',
    topbarColor: '#F8FAFC',
    topbarBackgroundColor: '#00bb00',
    topbarHeadingColor: '#0397B1',
    searchbarBackgroundColor: '#DDE1E7',
    buttonColor: '#0397B1',
    tabFontColor: '#0397B1',
    tabInactiveFontColor: '#000000',
    tabFontSize: '24px',    
    containerWidth: 'none',
    containerBorderStyle: 'none',
    containerBorderWidth: 'none',
    containerBorderColor: 'none',
    navLinkColor: '#ff0101',
    footerColor: '#1F2837',
    footerBackgroundColor: '#F8FAFC',
    customFontFamily: "Roboto",
  }

  const initialThemeDark: ThemeProps = {
    themeType: 'Dark',
    primaryColor: '#1F2837',
    secondaryColor: '#F8FAFC',
    backgroundColor: '#ffffff',
    topbarColor: '#F8FAFC',
    topbarBackgroundColor: '#00bb00',
    topbarHeadingColor: '#BDEEF6',
    tabFontColor: '#0397B1',
    tabInactiveFontColor: '#000000',
    tabFontSize: '24px',
    searchbarBackgroundColor: '#DDE1E7',
    buttonColor: '#0397B1',
    containerWidth: 'none',
    containerBorderStyle: 'none',
    containerBorderWidth: 'none',
    containerBorderColor: 'none',
    navLinkColor: '#BDEEF6', /* '#0397B1', */
    footerColor: '#1F2837',
    footerBackgroundColor: '#F8FAFC',
    customFontFamily: "Roboto",
 }
 const [theme, setTheme] = useState<ThemeProps>(initialThemeLight);


  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty('--primary-color', theme.primaryColor);
    root.style.setProperty('--secondary-color', theme.secondaryColor);
    root.style.setProperty('--background-color', theme.backgroundColor);
    root.style.setProperty('--topbar-color', theme.topbarColor);
    root.style.setProperty('--topbar-background-color', theme.topbarBackgroundColor);
    root.style.setProperty('--topbar-heading-color', theme.topbarHeadingColor);
    root.style.setProperty('--tab-font-color', theme.tabFontColor); // '#0397B1',
    root.style.setProperty('--tab-inactive-font-color', theme.tabInactiveFontColor); // '#000000',
    root.style.setProperty('--tab-font-size', theme.tabFontSize); // '24px',
    root.style.setProperty('--searchbar-background-color',theme.searchbarBackgroundColor);// '#DDE1E7',
    root.style.setProperty('--app-button-color',theme.buttonColor);
    root.style.setProperty('--container-width', theme.containerWidth);
    root.style.setProperty('--container-border-style', theme.containerBorderStyle);
    root.style.setProperty('--container-border-width', theme.containerBorderWidth);
    root.style.setProperty('--container-border-color', theme.containerBorderColor);
    root.style.setProperty('--bs-navbar-color', theme.navLinkColor);
    root.style.setProperty('--bs-nav-link-color', theme.navLinkColor);
    root.style.setProperty('--footer-color', theme.footerColor);
    root.style.setProperty('--footer-background-color', theme.footerBackgroundColor);
    root.style.setProperty('--bs-body-font-family', theme.customFontFamily)
  }, [theme]);

  const setProperty = React.useCallback((updatedValues: Partial<ThemeProps>) => {
    console.log('Setting New Property ', updatedValues);
    setTheme(prevTheme => ({ ...prevTheme, ...updatedValues }));
  }, []);
  const setKeyProperty = React.useCallback((refkey: keyof ThemeProps, newvalue: string) => {
    setTheme((prevTheme) => ({ ...prevTheme, [refkey]: newvalue }));
  }, []); 
  const setInitTheme = React.useCallback((selectedtheme: string) => {
    console.log('Before Setting ', selectedtheme);
    let newTheme: Partial<ThemeProps> = initialThemeDark;
    if (selectedtheme === 'Light') {
      newTheme = initialThemeLight;
    }
    //alert('Changing Theme: '+selectedtheme);
    console.log('Setting New Theme to ', newTheme);
    setProperty(newTheme);
  }, [theme]);



  const contextValue = React.useMemo(() => {
    return { ...theme, setProperty, setKeyProperty, setInitTheme };
  }, [theme, setProperty, setKeyProperty, setInitTheme]);

  return (
    <ThemeContext.Provider value={{ ...theme, setProperty, setKeyProperty, setInitTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
export const useTheme = () => {
  return useContext(ThemeContext);
};
export { ThemeContext, ThemeProvider };


