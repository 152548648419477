import React, { useContext, useState } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import AppContext from '../context/AppContext';

interface Charge {
    line_index : string;
    charge_description : string;
    charge_amount : string;
}
const initCharges : Array<Charge> = [
  {
    line_index : '1',
    charge_description: 'SWU Fixed Fee',
    charge_amount: '5.00'
  },
  {
    line_index : '2',
    charge_description: 'Late Fee (10%)',
    charge_amount: '13.50'
  },
  {
    line_index : '3',
    charge_description: 'Lien Recording Fee',
      charge_amount: '20.00'
    },
    {
      line_index: '4',
      charge_description: 'Previous Balance',
      charge_amount: '135.00'
  },
  {
    line_index: '5',
    charge_description: 'Payment Received (3/21/2024)',
      charge_amount: '-10.00'
  },
  {
    line_index: '6',
    charge_description: 'Total Amount Due',
      charge_amount: '163.50'
  }]

const PDFTemplateViewer: React.FC = () => {

    const { sessionData, currApplication } = useContext(AppContext);
    const [charges, setCharges] = useState<Array<Charge>>(initCharges);
    const [values, setValues] = useState({
        customer_code: '1011942',
        customer_name: 'John Doe',
        customer_addr_line1: '1206 SW 124th St',
        customer_addr_line2: 'Miami, FL 33136',
        location_code:'1005532',
        location_addr_line1: '1222 North Lane',
        location_addr_line2: 'Miami, FL 33917-3550',
        company_addr_line1: '701 NW 1st Court, 5th Floor',
        company_addr_line2: 'Miami, FLorida 33136-3912',
        account_code: '1011942-1006632',
        bill_reference: '1002135',
        bill_date: '04/01/2024',
        due_date: '04/15/2024',
        period_start_date: '03/01/2024',
        period_end_date: '03/31/2024',
        billing_days: '31'
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };


    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const formData = new FormData(e.currentTarget);
/*        const data = {
            customer_name: formData.get('customer_name') as string,
            previous_balance: formData.get('previous_balance') as string,
            current_charges: formData.get('current_charges') as string,
            ending_balance: formData.get('ending_balance') as string,
          };
          */
      
        // Append form data from state
        formData.append('biller_id', sessionData.data["Biller Id"]);
        const currentAppId = currApplication?.id ?? '0';
        formData.append('application_id', currentAppId);
        formData.append('pagename', 'PDF');
        formData.append('objecttype', "pageobject");
        formData.append('charge_details', JSON.stringify(charges));
         // Convert the FormData object to a plain object
        const formObject = Object.fromEntries(formData.entries());
        console.log("Formatted Payload is ", JSON.stringify(formObject));
        try {
            const response = await fetch(process.env.REACT_APP_API_URI +'/sec/data/generate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-KEY': 'Baeldung'
                },
                body: JSON.stringify(formObject),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'merged.pdf');
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        } catch (error) {
            console.error('Error generating PDF', error);
        }
    };

    const handleChargeChange = (index: number, field : string,  value: string) => {
      const newCharges = charges.map((param, i) =>
        i === index ? { ...param, field: value } : param
      );
      setCharges(newCharges);
    };

    const addChargeRow = () => {
      // Convert the numeric strings to numbers
     const numbers: number[] = charges.map(charges => +charges.line_index);
    // Finding the maximum value
      const maxValue = (Math.max(...numbers)+1)+'';
       setCharges([...charges, { line_index: maxValue, charge_description: '', charge_amount: '0.00' }]);
     };
   
     const removeChargeRow = (index: number) => {
       const newCharges = charges.filter((_, i) => i !== index);
       setCharges(newCharges);
     };

    return (
        <div>
            <h1>PDF Merge Example</h1>
            <form onSubmit={handleSubmit}>
               <div>
                    <input type="hidden" name="account_code" value={values.account_code}/>
                </div>
                <div>
                    <label>Compnay Address:</label>
                    <input type="text" name="company_addr_line1" value={values.company_addr_line1} readOnly/>
                </div>
                <div>
                    <label>Address Line 2:</label>
                    <input type="text" name="company_addr_line2" value={values.company_addr_line2} readOnly/>
                </div>
                <div>
                    <label>Statement Number:</label>
                    <input type="text" name="bill_reference" value={values.bill_reference} onChange={handleChange} />
                </div>
                <div>
                    <label>Customer #:</label>
                    <input type="text" name="customer_code" value={values.customer_code} onChange={handleChange} />
                </div>
                <div>
                    <label>Customer Name:</label>
                    <input type="text" name="customer_name" value={values.customer_name} onChange={handleChange} />
                </div>
                <div>
                    <label>Customer Address:</label>
                    <input type="text" name="customer_addr_line1" value={values.customer_addr_line1} onChange={handleChange} />
                </div>
                <div>
                    <label>City, State and Zip:</label>
                    <input type="text" name="customer_addr_line2" value={values.customer_addr_line2} onChange={handleChange} />
                </div>
                <div>
                    <label>Location Code:</label>
                    <input type="text" name="location_code" value={values.location_code} onChange={handleChange} />
                </div>
                <div>
                    <label>Service Address:</label>
                    <input type="text" name="location_addr_line1" value={values.location_addr_line1} onChange={handleChange} />
                </div>
                <div>
                    <label>City, State and Zip:</label>
                    <input type="text" name="location_addr_line2" value={values.location_addr_line2} onChange={handleChange} />
                </div>
                <div>
                    <label>Bill Date:</label>
                    <input type="text" name="bill_date" value={values.bill_date} onChange={handleChange} />
                </div>
                <div>
                    <label>Due Date:</label>
                    <input type="text" name="due_date" value={values.due_date} onChange={handleChange} />
                </div>
                <div>
                    <label>Period Start:</label>
                    <input type="text" name="period_start_date" value={values.period_start_date} onChange={handleChange} />
                </div>
                <div>
                    <label>Period End:</label>
                    <input type="text" name="period_end_date" value={values.period_end_date} onChange={handleChange} />
                </div>
                <div>
                    <label>Billing Days:</label>
                    <input type="text" name="billing_days" value={values.billing_days} onChange={handleChange} />
                </div>
                <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Charge Amount</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {charges.map((param, index) => (
                    <tr key={index}>
                      <td>
                        <Form.Control
                          type="text"
                          name="line_index"
                          value={param.line_index}
                          /*onChange={(e) => handleChargeChange(index, e.target.name, e.target.value)} */
                          readOnly
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          name="charge_description"
                          value={param.charge_description}
                          onChange={(e) => handleChargeChange(index, e.target.name, e.target.value)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          name="charge_amount"
                          value={param.charge_amount}
                          onChange={(e) => handleChargeChange(index, e.target.name, e.target.value)}
                        />
                      </td>
                      <td>
                        <Button variant="danger" onClick={() => removeChargeRow(index)}>Remove</Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <Button variant="primary" onClick={addChargeRow}>Add Charge Line</Button>
              </Table>
                <button type="submit">Generate PDF</button>
            </form>
        </div>
    );
};

export default PDFTemplateViewer;
