import React, { ChangeEvent, useRef, useState } from 'react';
import nologo from '../img/nologo.png';

interface ImageUploaderProps {
  file_input_id: string; //Input field ID
  maxFileSize: number; // Maximum file size in bytes
  onUpload: (id: string, imagevalue: string ) => void; // Callback function
  currentValue?: string; // Base64 value of the existing image
  inputButtonLabel?: string; // Custom label for the input field button
}

  
  const ImageUpload64: React.FC<ImageUploaderProps> = ({ file_input_id, inputButtonLabel, onUpload, maxFileSize, currentValue }) => {
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleClick = () => {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
    };
  
    const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      const resizedImage = await resizeImage(file);
      handleFile(file_input_id, resizedImage);
    };

    const handleImageUpload = async (event: ChangeEvent<HTMLInputElement> ) => {
      const file = event.target.files?.[0];
      if (file) {
        const resizedImage = await resizeImage(file);
        handleFile(event.target.id, resizedImage);
      }
    };
    const handleFile = (id:string, file: File|null) => {
      if (file) {
        
      if (file.size <= maxFileSize) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result as string;
          setSelectedImage(base64String);
          onUpload(id, base64String);
          setError(null);
        };
        reader.readAsDataURL(file);
      } else {
        setError(`File size should be less than ${maxFileSize} bytes.`);
      }
    }
    };

    const resizeImage = (file: File): Promise<File | null> => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const MAX_WIDTH = 280;
            const MAX_HEIGHT = 280;
            let width = img.width;
            let height = img.height;
    
            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
            } else {
              if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }
            }
    
            canvas.width = width;
            canvas.height = height;
    
            const ctx = canvas.getContext('2d');
            ctx?.drawImage(img, 0, 0, width, height);
    
            canvas.toBlob((blob) => {
              if (blob) {
                const resizedFile = new File([blob], file.name, { type: file.type });
                resolve(resizedFile);
              } else {
                resolve(null);
              }
            }, file.type);
          };
          img.src = event.target?.result as string;
        };
        reader.readAsDataURL(file);
      });
    };
  
    // Handle displaying the existing image
  React.useEffect(() => {
    if (currentValue) {
      setSelectedImage(currentValue);
    } else {
      setSelectedImage(null);
    }
  }, [currentValue]);

  return (
    <div
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      style={{ border: '1px dashed #ccc', padding: '1rem', cursor: 'pointer' }}
    >
      <input 
        type="file"
        id={file_input_id}
        accept="image/*"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleImageUpload}
      />
      <div
        onClick={handleClick}
        style={{ border: '1px dashed #ccc', padding: '1rem', cursor: 'pointer' }}
      >
        {error && <p>{error}</p>}
        {selectedImage ? (
          <img src={selectedImage} alt="Uploaded" width="60px"/>
        ) : (
          <img src={nologo} alt="No Logo" width="60px" />
        )}
        <span>Click to change logo or drop Image file</span>
      </div>
    </div>
  );
  };
  
  export default ImageUpload64;
