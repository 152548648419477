const BAR_CHART_DATA = [
    {id: 1,
        month: "Jan",
        WHTR: "10000",
        AC: "7000",
        Furn:"5000",
        WFTR:"2000"
    },
    
    {id: 2,
    month: "Feb",
    WHTR: "6000",
    AC: "5000",
    Furn:"3000",
    WFTR:"1000"
},

    {id: 3,
        month: "Mar",
        WHTR: "4000",
        AC: "1000",
        Furn:"6000",
        WFTR:"7000"
    },

    {id: 4,
        month: "Apr",
        WHTR: "5000",
        AC: "2000",
        Furn:"8000",
        WFTR:"4000"
    },

    {id: 5,
        month: "May",
        WHTR: "7010",
        AC: "4000",
        Furn:"5000",
        WFTR:"2000"
    },
    
    {id: 6,
        month: "Jun",
        WHTR: "1500",
        AC: "1000",
        Furn:"1500",
        WFTR:"2500"
    },
    
    {id: 7,
        month: "Jul",
        WHTR: "1440",
        AC: "2500",
        Furn:"8000",
        WFTR:"3000"
    },

    {id: 8,
        month: "Aug",
        WHTR: "1500",
        AC: "2500",
        Furn:"9000",
        WFTR:"5000"
    }, 
    {id: 9,
        month: "Sep",
        WHTR: "2000",
        AC: "2500",
        Furn:"5000",
        WFTR:"2000"
    },  
    {id: 10,
            month: "Oct",
            WHTR: "5000",
            AC: "2000",
            Furn:"6000",
            WFTR:"4000"
        },
    {id: 11,
            month: "Nov",
            WHTR: "2000",
            AC: "2500",
            Furn:"4000",
            WFTR:"3000"
        }, 
    {id: 12,
        month: "Dec",
        WHTR: "3000",
        AC: "2500",
        Furn:"5000",
        WFTR:"2000"
    },  

]

export default BAR_CHART_DATA