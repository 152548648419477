import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../context/AppContext';
import { hpost } from '../context/apiService';
import arrowRight from "../img/arrowRight.png";
import toggleArrow from "../img/toggleArrow.png";
import { MultiLevelMenuItem as MultiLevelMenuItemType, toggleHandle } from '../types/menu-item';
import AlertModal from './AlertModal';
import RenderMenuItem from './RenderMenuItem';

interface NavState {
  error: string | null,
  menuobj: any | null
}

const SideMenu: React.FC<toggleHandle> = ({ toggleState, toggleHandle, selectedNavItem, setSelectedNavItem }) => {
  const [navdata, setNavData] = useState<NavState>({ error: null, menuobj: null });
  const [menuItems, setMenuItems] = useState<MultiLevelMenuItemType[]>([]);
  const [expandedMenuItem, setExpandedMenuItem] = useState<MultiLevelMenuItemType>();
  const [isMenuToggled, toggleMenu] = useState<boolean>(false);
  const { currAppLogo, currApplication } = useContext(AppContext);

  const initializeMenuItems = (parent_option_id: string) => {
    let initMenuItems: MultiLevelMenuItemType[] = [];
    let new_position = 1;
    let resequenceInd = false;
    if (navdata.menuobj !== null) {
      navdata.menuobj.data.filter((fitem: any) => (fitem.parent_option_id === parent_option_id) &&
        (fitem.status !== 'X')).map((item: any, index: number) => {
          item.children = initializeMenuItems(item.option_id);
          new_position++;
          console.log("Adding item: ", item);
          if (item.children.length > 0) {
            item.node_type = "Submenu";
          } else {
            item.node_type = "Leaf";
          }
          initMenuItems.push(item);
        })
    }
    const sortedItems = [...initMenuItems].sort((a, b) => Number(a.position) - Number(b.position));
    return sortedItems;
  }

  const fetchData = async () => {
    const params = {
      objecttype: "appmenu",
      application_id: "3",
      pagename: "X",
    };
    console.log("Before Data Fetch ");
    let response = await hpost('/sec/data', params);
    if (response.data) {
      setNavData({
        menuobj: response.data,
        error: null
      });
      console.log("Menu Data Response: ", response.data);
    } else if (response.error) {
      console.log("Menu Data Error-", response.error);
      setNavData({ menuobj: null, error: response.error });
    }
  }


  useEffect(() => {
    // Fetch data using the API service
    fetchData();
  }, [currApplication]);

  useEffect(() => {
    const updatedMenuItems = initializeMenuItems('0')
    console.log("Before setting Menu Items", updatedMenuItems);
    setMenuItems(updatedMenuItems);
  }, [navdata])

  function handleClick() {
    toggleMenu(!isMenuToggled);
  }

  const setExpandedItem = (e: any) => {
    console.log("Item Getting Expanded is ", e);
  }
  const handleNavigationItemClick = (e: any, navItem: string) => {
    e.preventDefault();
    if (selectedNavItem !== navItem) {
      // alert('Menu Item Clicked '+navItem);
      setSelectedNavItem(navItem);
      console.log("Selected Menu item" + navItem);
    }
  };
  if (navdata.error) {
    return (<AlertModal alerttitle='API Error ' msgobj={navdata.error} display={true} />)
  } else {
    if (navdata.menuobj === null) {
      return (<h1>Loading Menu</h1>)
    } else {
      console.log('Nav Menu ' + JSON.stringify(navdata.menuobj.data));
      console.log('Nav Menu Items ', menuItems);
      return (
        <>
          {/* Sidebar */}
          <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" style={{maxHeight: '90vh'}}  id="accordionSidebar">

            {/*<!-- Nav Item - Dashboard -->*/}
            {
              <div style={{overflowY: 'auto', maxHeight: '90vh'}}>
                {
                  menuItems.filter(item => item.status === 'A').map((item, index) => (
                    <RenderMenuItem
                      key={item.option_id}
                      originalItem={item}
                      index={index}
                      selectedNavItem={selectedNavItem}
                      handleNavigationItemClick={handleNavigationItemClick}
                      isMenuToggled={isMenuToggled}
                      toggleMenu={toggleMenu}
                      setExpandedItem={setExpandedItem}
                    />
                  ))
                }
              </div>
            }

            {/*<!-- Sidebar Logo -->*/}
            {/* {isMenuToggled && <div className="sidebar-card fixed-bottom mb-3 ms-3">
      <img src={currAppLogo} alt="appLogo" />
    </div> } */}
          </ul>

          {/*<!-- End of Sidebar -->*/}

          <button type="button" className="sidemenuocbtn" onClick={handleClick}>
            <img src={isMenuToggled ? toggleArrow : arrowRight} alt="collapseButton" />
          </button>
        </>
      );
    }
  }
}
export default SideMenu;
