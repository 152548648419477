import { Modal } from "react-bootstrap";

interface ModalProps{
    onClose: () => void;
    isIconModalOpen:boolean;
    iconName: any;
}

const NavbarIconModal: React.FC<ModalProps> = ({onClose, isIconModalOpen, iconName})=>{
           return(
                <div>   
                <Modal show={isIconModalOpen} onHide={onClose} dialogClassName="resizable-modal-dialog" centered radius="4px">
                <Modal.Header closeButton>
                  <Modal.Title className="ms-auto">{iconName == "modeIcon" ? "Change Mode" : iconName == "bellIcon" ? "Notifications" : "Messages"}</Modal.Title>                  
                </Modal.Header>
                <Modal.Body>  
                    {iconName === "modeIcon" ? 
                   <>
                      <p>Select the mode</p>          
                      <div className="form-check">
                         <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                         <label className="form-check-label">
                             Light
                         </label>
                         </div>
                         <div className="form-check">
                         <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked/>
                         <label className="form-check-label">
                             Dark
                         </label>
                       </div>
                   </>
                            : iconName === "bellIcon"
                            ? <p>You have 0 notifications.</p>
                            : <p> You have 0 messages.</p>
                     }
                </Modal.Body>
              </Modal>        
            </div>
            )
    }
    

export default NavbarIconModal