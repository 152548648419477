import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import { Column } from 'react-table';
import { useTheme } from '../Template/ThemeContext';
import AppContext from '../context/AppContext';
import { getTableColumns, isEmptyObj } from '../context/MiscFunc';
import { hfetchdoc, hpost } from '../context/apiService';
import { TableRow } from '../types/menu-item';



interface DocumentListProps {
  requesttype: string;
  requestcode: string;
  show: boolean;
  onClose : () => void;
}

const DocumentList: React.FC<DocumentListProps> = ({ requesttype, requestcode, show, onClose }) => {
  const [documents, setDocuments] = useState<TableRow[]>([]);
  const [columns, setColumns] = useState<Column<TableRow>[]>([]);

  const [selectedDocument, setSelectedDocument] = useState<Document | null>(null);
  const Theme = useTheme();
  const { sessionData, currApplication } = useContext(AppContext);
  useEffect(() => {
    const fetchDocuments = async () => {
      let params: any = {
        objecttype: "pageobject",
        pagename: "documents",
        application_id: currApplication?.id,
        biller_id: sessionData.data["Biller Id"],
        id_type: requesttype,
        id_value: requestcode,
      }
      console.log("DocumentList Parameters are: ", params);
      try {
        /*
        const { blob, filename } = await hfetchdoc('/sec/file/download', params);
        const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();

                // Automatically remove the link after 10 seconds
           
           setTimeout(() => {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }, 10000); // 10 seconds
        */
        const response: any = await hpost('/sec/data', params);
        if (response.data) {
          console.log("DocList Response Data is ", response.data);
          setDocuments(response.data.data as TableRow[]);
          setColumns(getTableColumns(response.data.pagemeta.columntypes));
        } else {
          setDocuments([]);
        }
      } catch (error) {
        console.error('Error fetching documents:', error);
      }
    };

    fetchDocuments();
  }, [requesttype, requestcode]);

  const handleDownload = async (id: number) => {
    let params: any = {
      application_id: currApplication?.id,
      biller_id: sessionData.data["Biller Id"],
      document: id,
      reposnseType: 'blob'
    }
    try {
      const { blob, filename } = await hfetchdoc('/sec/file/getfile', params);

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 1000);
    } catch (error) {
      console.error('Error downloading document:', error);
    }
  };

 

  return (
    <Modal show={show} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Documents List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(documents?.length > 0) &&
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {documents.map((document) => (
              <tr key={document.document_id}>
                <td>{document.filename}</td>
                <td>{document.description}</td>
                <td>{document.contenttype}</td>
                <td>
                  <Button variant="primary" onClick={() => handleDownload(document.document_id as number)}>
                    Download
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        }
        {isEmptyObj(documents) && <h3>No Documents Found</h3>}
      </Modal.Body>
    </Modal>

  );
};

export default DocumentList;
