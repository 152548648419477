import { Column } from "react-table";
import { TableRow } from "../types/menu-item";

export function isEmptyObj(obj: Record<string, any> | null): boolean {
    if ((obj === null) || (obj === undefined)) {
      return true;
    }
    return Object.keys(obj).length === 0;
  }

  interface MyData {
    [key: string]: any;
  }

  export const getStartingNumber = (input: string): string => {
    // Define a regular expression to match the initial number
   // const regex = /^(\d+)-/;
    const regex = /\((\d+)\)/;   /* New regex with digits enclosed in brackets */
    const match = input.match(regex);
    
    if (match) {
      // Convert the matched number to an integer and return it
      return parseInt(match[1], 10)+'';
    } else {
      // If no match is found, return null
      return '';
    }
  }

  export const formatDate = (date: Date): string => {
    const day = date.getDate().toString().padStart(2, '0');
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  export const isDate = (value : any) => {
    return value && (value.length >= 8) && value instanceof Date;
  }

  export const isValidDate = (value : any) => {
    const date = new Date(value);
    return isDate(value) && !isNaN(date.getTime());
  }

  export const isTimestamp = (value : any ) => {
    return value && (value.length > 11) && typeof value === 'number' && !isNaN(value) && isFinite(value) && new Date(value).getTime() === value;
  }

  export const  isValidDateOrTimestamp = (value : any ) => {
    // Check if the value is an instance of Date and is valid
    if (value && value.length <= 11) {
      return isValidDate(value);
    }
    
    // Check if the value is a valid date string
    if (value && value.length > 11) {
      return isTimestamp(value);
    }
    
    return false;
  }

export function extractRowValue(str :string) {
    const match = str.match(/row\((\d+)\)/);
    if (match) {
      return parseInt(match[1], 10);
    }
    return null; // Return null if no match is found
  }
  
  
  export const formatTimestampForMySQL = (datetime : string) => {
    const date = new Date(datetime);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  export const isEmptyOrNull = (str: string | null | undefined): boolean => {
    return str === undefined || str === null || str.trim() === '';
  };

  export const sortObjectKeys = (data: MyData): string[] => {
    const keys = Object.keys(data);
    keys.sort((key1, key2) => {
      const index1 = data[key1].columnIndex;
      const index2 = data[key2].columnIndex;
      return index1 - index2;
    });
    return keys;
  };

 export const getColorHex = (str : string) : string =>  {
    let ctx = document.createElement("canvas").getContext("2d");
    if (ctx !== null) {
    ctx.fillStyle = str;
    return ctx.fillStyle;
    }
    return str;
}

export const debounce = (func: Function, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const getTableColumns = (columnTypes: any): Column<TableRow>[] =>  {
    return sortObjectKeys(columnTypes).filter((colkey) => {
      return (((columnTypes[colkey].columnDisplayType === 'text')
        || (columnTypes[colkey].columnDisplayType === 'unique')
        || (columnTypes[colkey].columnDisplayType === 'auto')
        || (columnTypes[colkey].columnDisplayType === 'amount')
        || (columnTypes[colkey].columnDisplayType === 'amountsum')
        || (columnTypes[colkey].columnDisplayType === "display")
        || (columnTypes[colkey].columnDisplayType.startsWith('list'))
        || (columnTypes[colkey].columnDisplayType.startsWith('add'))
        || (columnTypes[colkey].columnDisplayType.startsWith('llist'))
        || (columnTypes[colkey].columnDisplayType.startsWith('num'))) &&
           !(columnTypes[colkey].columnDisplayType.endsWith('_style')));
    }).map(key => {
      let columnType = columnTypes[key];
      let column: Column<TableRow> =
      {
        Header: key, 
        accessor: key,
        id:columnType.columnIndex,
        Cell: ({ value }: any) => {
          let numericValue = typeof value === 'number' ? value : parseFloat(value);
          if ((columnType.columnDisplayType === 'amount') || (columnType.columnDisplayType === 'amountsum')) {
            return numericValue.toFixed(2);
          } else if (columnType.columnDisplayType.startsWith('num'))  {
            const decimalPlaces = key.length > 3 ? parseInt(key.slice(3), 10) : 0;
            return numericValue.toFixed(decimalPlaces);
          } else if (columnType.columnDisplayType.startsWith("list")) {
            let columnLOV = columnType.columnListofValues;
            let displayvalue = columnLOV.find((listitem :any) => (listitem.optionvalue === value))
            // console.log("List Display Value is ", (displayvalue?.optionlabel??''))
            return (displayvalue?.optionlabel??'');
          }
          return value;
        },
        Footer: (columnType.columnDisplayType === 'amountsum') ?({ rows }: { rows: any[] }) => {
          const total = rows.reduce((sum, row) => {  
            const value = row.values[columnType.columnIndex];
            console.log("Dynamic Data Footer ", key, columnType.columnIndex, row.values);
            return sum + Number(value);
          }, 0);
          return <strong>{total.toFixed(2)}</strong>;
        } : undefined,
      };
     // console.log("Mapped Column is  ", key);
      if (columnType.columnDisplayType === 'amount' || columnType.columnDisplayType.startsWith('num')
        || columnType.columnDisplayType === 'amountsum') {
        column.Cell = ({ value }: any) => {
          let numericValue = typeof value === 'number' ? value : parseFloat(value);
          const decimalPlaces = columnType.columnDisplayType.startsWith('num') && columnType.columnDisplayType.length > 3 ? parseInt(columnType.columnDisplayType.slice(3), 10) : 2;
          return (
            <div style={{ textAlign: 'right', padding: '10px' }}>
              {numericValue.toFixed(decimalPlaces)}
            </div>
          );
        };
        column.Header = () => (
          <div style={{ textAlign: 'right', paddingRight: '10px' }}>
            {columnType.columnLabel}
          </div>
        );
      } else {
        column.Header = () => (
          <div style={{ textAlign: 'left' }}>
            {columnType.columnLabel}
          </div>
        );
      }

      return column;
    }
    )}
   
 