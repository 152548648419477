import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../context/AppContext';
import { isEmptyObj } from '../context/MiscFunc';
import { hpost } from '../context/apiService';
import AlertModal from './AlertModal';

type Master = {
  id: number;
  name: string;
};

type Detail = {
  id: number;
  description: string;
};

interface DataProps {
  pagename: string;
  currApplication: Record<string, string> | null;
  dataobject : any;
  columnTypes: any;
}

const MasterDetailForm: React.FC<DataProps> = ({ pagename, currApplication, dataobject, columnTypes }) => {
  const [master, setMaster] = useState<Record<string, any>>(dataobject);
  const [details, setDetails] = useState<Record<string, any>[]>([]);
  const [activeMasterRow, setActiveMasterRow] = useState<any>({});
  const { sessionData } = useContext(AppContext);
  const [errors, setErrors] = useState<Record<string, string>>({});

  console.log("In Master Details Form - Master Page: ", dataobject.data[0] );

  useEffect(() => {
    console.log("Before Fetch Masters", master);
    //fetchMasters();
  }, [dataobject]);

  const fetchMasters = async () => {
    console.log("In Fetch Masters");
    if (sessionData == null) {
      console.log("In PageContents - User Not Logged In");
      //changeAuthMode("signin");
      return (<div className="container-fluid">
        User not found in Session Context.<a href="/">Please Login</a>
      </div>)
    } else {
      try {
        console.log("Before Setting Parameters");
        const params = {
          objecttype: "pageobject",
          pagename: pagename,
          application_id: currApplication?.id
        };
        console.log("Before Fetching Master " + JSON.stringify(params));
        const response =  await hpost('/sec/data', params);
        //console.log(" Total Response "+response);
        const newErrors: Record<string, string> = {}
        let myjsondata = JSON.parse('{}');
        if (response != null) {
          console.log(" Master Data Response " + JSON.stringify(response.data));
          myjsondata = JSON.parse(JSON.stringify(response.data));
         // setActiveBill(myjsondata.data[0]['Reference Number']);
        } else {
          console.log("Master Response is NULL");
        }

        if (isEmptyObj(myjsondata) || myjsondata.hasOwnProperty("_notfound")) {
          newErrors.notfound = 'Data Not Found';
          console.log("Setting error to No Data Found");
        } else if (myjsondata.hasOwnProperty("pagemeta")) {
          setMaster(myjsondata);
        } else {
          setMaster({});
        }
        setErrors(newErrors);

      } catch (error) {
        console.error('Error fetching data:', error);
        const newErrors: Record<string, string> = {};
        newErrors.dataerror = 'Error: ' + error;
        setErrors(newErrors);
      }
    };

  };

  function renderMaster() {

    const masterList: any[] = [];
    masterList.push(<button className="list-group-item active" key='0' >
                    <div className="row">
                    <div className="col">First</div>
                    <div className="col">Second</div>
                    <div className="col">Third</div>
                    <div className="col">Fourth</div>
                    </div>
                     </button>);
    for(let i = 0; i < master.data.length; i++) {
        console.log("Master Row "+JSON.stringify(master.data[i])+" Active Master "+activeMasterRow);
        masterList.push(<button className={(master.data[i]['key']==activeMasterRow.key)?
                      "list-group-item list-group-item-action list-group-item-info":
                      "list-group-item list-group-item-action"} key={master.data[i]['rownumber_meta']} onClick={() => setActiveMasterRow(master.data[i])}>
                    <div className="row text-center">
                      {
                    Object.keys(master.data[i]).map((mkey) => {
                         console.log("Data: Key: ", mkey, " value ", master.data[i][mkey]);
                         return (<div key={mkey+i} className="col">{master.data[i][mkey]}</div>);
                      })
                    }
                      </div>
                     </button>);
    }
    console.log("Before Returning Master List", masterList);
    return masterList;

}

  if (Object.keys(errors).length > 0) {

    return (
      <span>
        <AlertModal alerttitle={"Error"} msgobj={JSON.stringify(errors)} display={true}/>
        {/*
          Object.keys(errors).map((errorkey, i) => {
            return (<h1 key={i}>{errors[errorkey]}</h1>)
          }
          )
          */
        }
      </span>
    );
  } else if (master.hasOwnProperty("pagemeta") &&
    (Object.keys(master.pagemeta.columntypes).length > 0)) {
      if (isEmptyObj(activeMasterRow)) {
         setActiveMasterRow(master.data[0]);
      }
    return (
        <>
        <div className="container-lg col-xl-5">
        <h2>Bill History</h2>
        <div className="list-group">
          { renderMaster() }
        </div>
        </div>
        <div className="container-lg">
         {/*
           Object.keys(detail).map((detailname, idx) => (
           <DetailsList master_id={activeMaster} detail={detail[idx]} currApplication={currApplication} />
           
           )
          )
           */}
           <span>Key is {activeMasterRow.application}</span>
          </div>
          </>

    );
   } else {
    return (
      <div className="text-center">
        <div className="spinner-grow text-success" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow text-danger" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow text-warning" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow text-info" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  }
}

export default MasterDetailForm;
