
const LINE_CHART_DATA = [
    {id: 1,
        month: "Jan",
        income: "5",
        orders: "7"},
    
    {id: 2,
    month: "Feb",
    income: "10",
    orders: "5"},

    {id: 3,
        month: "Mar",
        income: "15",
        orders: "10"},

    {id: 4,
        month: "Apr",
        income: "5",
        orders: "20"},

    {id: 5,
        month: "May",
        income: "10",
        orders: "5"},
    
    {id: 6,
        month: "Jun",
        income: "15",
        orders: "10"},
    
    {id: 7,
        month: "Jul",
        income: "10",
        orders: "25"},

    {id: 8,
        month: "Aug",
        income: "15",
        orders: "25"}, 
    {id: 9,
        month: "Sep",
        income: "20",
        orders: "25"},  
    {id: 10,
            month: "Oct",
            income: "5",
            orders: "20"},
    {id: 11,
            month: "Nov",
            income: "20",
            orders: "25"}, 
    {id: 12,
        month: "Dec",
        income: "20",
        orders: "25"},  

]

export default LINE_CHART_DATA