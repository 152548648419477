import React from 'react';
import { Container } from 'react-bootstrap';
import appLogo from '../img/md-logo-color2.png';
import { toggleHandle } from '../types/menu-item';


const AppFooter: React.FC<toggleHandle> = ({toggleState, toggleHandle}) => {
  //const {currAppLogo} = useContext(AppContext)
  return (
    <footer className="footer sticky-bottom mt-auto d-flex" onClick={() => toggleHandle("footerdisplay")}>
         
      {/* <img src={currAppLogo} alt="App logo" /> */}
      {<img src={appLogo} alt="App logo" style={{maxHeight: '30px'}}/>}
         
      <Container>
        <span className="text-center">&copy;{new Date().getFullYear()} TrekSoftware LLC. All rights reserved.</span>
      </Container>
    </footer>
  );
};

export default AppFooter;
