import welcomeImage from "../img/WelcomePageBackgroundImage.png"
import trekLogo from "../img/TrekLogo.png"
import { useContext, useState } from "react";
import LoginModal from "./LoginModal";
import AppContext from "../context/AppContext";


interface LoginProps {
    currentstep: string;
    changeAuthMode: (newmode: string) => void;
  }
interface LoginCredentials {
   username: string;
   password: string;
   roletype: string;
 }

const Welcome:React.FC<LoginProps> = ({currentstep, changeAuthMode}) =>{
    const [isLoginModalOpen, setLoginModalOpen] = useState(false)
    const {setIsSignedUp} = useContext(AppContext);

    const handleSignUp = ()=>{
        setIsSignedUp(true)
        setLoginModalOpen(true)
    }

    const handleModalClose = ()=>{
        setLoginModalOpen(false)
    }

    const handleLogin = ()=>{
        setLoginModalOpen(true)
    }

 return(
    <div style={{backgroundImage: `url(${welcomeImage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition:"center", height:"100vh",
    display:"flex", flexDirection:'column', gap:'32px', alignItems:"center", justifyContent:"center"}}>
      
        <img src={trekLogo} style={{width:"240px" }} alt="Company Logo"/>
        
        <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
            <h1 style={{color:'#FFFFFF', fontSize:'52px'}}>WELCOME TO</h1>
            <h3 style={{color:'#FFFFFF', fontSize:'36px'}}>Flex Biller Portal</h3>
        </div>

        <div style={{display:"flex", flexDirection:'column', gap:'20px'}}>
        <button type="submit" style={{backgroundColor:"#0397B1", width:'300px', borderRadius:'4px'}} className="btn btn-success me-2" onClick={handleSignUp}>SIGN UP</button>
        <button type="reset" className="btn btn-outline-dark" style={{width:'300px', color:'#FFFFFF',borderColor:'#FFFFFFB2', borderRadius:'4px'}} onClick={handleLogin}>LOGIN</button>
        </div>    
        
        {isLoginModalOpen && <LoginModal
          isOpen={isLoginModalOpen}
          onClose={handleModalClose}
          changeAuthMode = {changeAuthMode}
          currentstep= {currentstep}
          />}
    </div>
 )
}

export default Welcome